import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { BackButton, CardRow, DetailCardItem, EmptyDetailCard } from '../../../components';
import { getGrade } from '../actions';
import { getUser } from '../apis';
import { stringReduceAddDots } from '../../../utilities';
import { useLocation } from 'react-router-dom';


const RefereeDetail = (props) => {
  const location = useLocation();
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  const [user, setUser] = useState([]);

  const userId = location.state;

  useEffect(() => {

    dispatch(getGrade(userId))

    getUser(userId).then(response => {
      setUser(response.data)
    })

  }, []);

  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span><i className="fa fa-list fa-lg mr-3"></i></span>REFEREE DETAILS</h6>
              <BackButton />

            </div>
          </div>

          {user.length !== 0 ?
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card shadow mb-25 bg-white rounded my-3" >
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img src={user.picture || '/assets/images/profile.png'} alt="Admin" className="rounded" width="150" height="150" />
                      <div className="mt-3">
                        <h6>{`${user.name} ${user.surname}`}</h6>

                        <p className="font-size-sm mb-0" style={{fontSize: 12}}>{user.matricule === null ? '' : user.matricule}</p>
                        <p className="font-size-sm" style={{fontSize: 12}}>{user._subRole === null ? '' : user._subRole.name}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow mb-25 bg-white rounded my-3" >
                  <div className="card-body">
                    <CardRow title='League' value={user.league} title2='Region' value2={user._region === null ? '' : user._region.name} />
                    <CardRow title="Departement" value={user._departement === null ? '' : user._departement.name} title2={stringReduceAddDots('District',15)} value2={user._arrondissement === null ? '' : user._arrondissement.name} showHR={false} />
                  </div>
                </div>

                {/* <SignaturePreview signature={user.signature} height={'100%'} /> */}

              </div>
              <div className="col-md-8">
                <div className="card mb-3 shadow bg-white rounded my-3" >
                  <div className="card-body">

                    <CardRow title='Name' value={user.name} title2='Surname' value2={user.surname} />
                    <CardRow title='Date of birth' value={user.dob} title2='Place of birth' value2={user.pob} />
                    <CardRow title='Marital status' value={user.familyStatus} title2='Gender' value2={user.gender} />
                    <CardRow title='Email' value={user.email} showHR={false} title2="Phone number" value2={user.phone} />

                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="card mb-3 shadow bg-white rounded my-3" >
                      <div className="card-body">
                        <CardRow title="Profession" value={user.profession} title2='Role' value2={user._role.name} showHR={false} />
                        <CardRow title="Matricule" value={user.matricule} title2="Education level" value2={user.schoolLevel} />
                        <CardRow title="Creation date" value={<Moment format='DD/MM/YYYY'>{user.creationDate}</Moment>} showHR={false} />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <div className="card">

                      <div className="card-body">

                        <div style={{ margin: 20 }}>
                            <h1 className='text-center'>Professional information</h1>
                        </div>

                        {adminState.grade.map((e) => {
                          return <DetailCardItem key={e.id} title={e.name}
                            date={e.date} place={e.place} instructor={e.instructor}
                            observation={e.observation} />
                        })}

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            : <EmptyDetailCard />}

        </div>
      </div>
    </div>
  );
}

export default RefereeDetail