import React from 'react';

const Checkbox = ({ title = 'Title', type = 'checkbox', error, ...other_props }) => {

  return (

    <div className="form-check form-check-inline">
      <input className={'form-check-input form-control'} type={type} {...other_props} />
      <label className="form-check-label" for={other_props.id}>{title}</label>
      {error && <small className="text-danger ml-1">{error}</small>}
    </div>

  );
}


export default Checkbox;