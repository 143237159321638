import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { EmptyTable } from "../../../components";

const ELiberoModal = ({
  handleClose,
  show,
  team,
  players,
  time,
  onLibero,
  data,
  captainSelection,
  playerSelection,
  teamSelectedLetter,
}) => {
  const [playerID, setPlayerID] = useState("");
  const [liberoID, setLiberoID] = useState("");

  const changeLibero = (history) => {
    onLibero(history);
  };

  const onSave = () => {
    const toSave = {
      time,
      team,
      playerBack: playerID,
      libero: liberoID,
      active: true,
    };
    onLibero(toSave);
  };

  return (
    <Modal show={show} centered size={"lg"} onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>LIBERO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Team:</p>
                <h1 style={{ fontSize: 16 }}>{team.name}</h1>
              </div>
              <div>
                <p className="mb-0">Time:</p>
                <h1 style={{ fontSize: 16 }}>{time}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0 mt-3">Libero:</p>
              <select
                onChange={({ target }) => setLiberoID(target.value)}
                className="form-control form-control-sm"
                value={liberoID}
              >
                <option></option>
                {Object.values(playerSelection)
                  ?.filter(
                    (val) =>
                      captainSelection[val.player.id]?.role?.charAt(0) ===
                        "L" &&
                      val.clubID === players[0]?.clubID &&
                      !data
                        .map((val) => (val.active ? val.libero : ""))
                        .includes(val.player.id)
                  )
                  .map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <p className="mb-0 mt-3">Player:</p>
              <select
                onChange={({ target }) => setPlayerID(target.value)}
                className="form-control form-control-sm"
                value={playerID}
              >
                <option></option>
                {players
                  .filter(
                    (val) =>
                      (teamSelectedLetter === "A"
                        ? [5, 6, 1]
                        : [1, 6, 5]
                      ).includes(val.position) &&
                      !data
                        .map((val) => (val.active ? val.playerBack : ""))
                        .includes(val.player.id)
                  )
                  ?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
                {/* {players?.map((val) => (
                  <option key={val.player.id} value={val.player.id}>
                    {`(${val.kit}) ${val.player.name} ${
                      captainSelection[val.player.id]?.role
                        ? `(${captainSelection[val.player.id]?.role?.charAt(
                            0
                          )})`
                        : ""
                    }`}
                  </option>
                ))} */}
              </select>
            </div>
          </div>
          <div className="col-md-6 border-left">
            <h4 className="text-center">
              <b>Libero history</b>
            </h4>
            {data?.length ? (
              data
                .filter((dat) => dat.team.id === team.id)
                .map((history) => (
                  <div
                    className="border px-2"
                    style={{ backgroundColor: "#c2c2c220" }}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <small className="mb-0">Status:</small>
                        <h6
                          style={{
                            fontWeight: 600,
                            fontSize: 13,
                            color: history.active ? "#4caa53" : "#c2c2c2",
                          }}
                        >
                          {history.active ? "ACTIVE" : "INACTIVE"}
                        </h6>
                      </div>
                      <div>
                        <small className="mb-0">Set:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {history.set}
                        </h6>
                      </div>
                      <div>
                        <small className="mb-0">Time:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {history.time}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <small className="mb-0">Libero:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {playerSelection[history.libero]?.player?.name}
                        </h6>
                      </div>
                      <div>
                        <small className="mb-0">Player:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {playerSelection[history.playerBack]?.player?.name}
                        </h6>
                      </div>
                    </div>

                    {history.active && (
                      <div className="d-flex justify-content-center mt-3">
                        <button
                          className="btn btn-sm w-50"
                          onClick={() =>
                            changeLibero({
                              time,
                              team,
                              libero: history.playerBack,
                              playerBack: history.libero,
                              active: false,
                            })
                          }
                          style={{ backgroundColor: "#02c0ce", color: "white" }}
                        >
                          Change Libero
                        </button>
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <EmptyTable
                titleStyle={{ fontSize: 14 }}
                imageStyle={{ height: 70, width: 70 }}
                rootStyle={{ marginTop: 60 }}
              />
            )}
          </div>
        </div>

        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            disabled={playerID === "" || liberoID === "" || data?.filter(val=>val.active)?.length}
            style={{ backgroundColor: "#02c0ce", color: "white" }}
          >
            Change Libero
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ELiberoModal;
