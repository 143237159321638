import React from 'react';

const Footer = () => {

    return (

        <footer style={{ margin: 20 }}>
            <div className="row align-items-center">
                <div className="col">
                    <div className=" text-center text-muted">
                        <p>** Made by **</p>
                        <a href="https://cybexai.com" className="font-weight-bold ml-1" target="_blank" rel="noopener noreferrer"><img src='/assets/images/cybex_logo.png' height='50' alt="cybexai" /></a>
                    </div>
                </div>
            </div>
        </footer>

    );
}


export default Footer;