import React from 'react';
import { DropdownLarge, FormButton, InputTextLarge } from '.';
import { DropdownConstants } from '../utilities';

const SignupStepOne = ({ values, handleChange, errors, nextPage, left, touched, handleBlur, roles, ...rest }) => {

    return (

        <>
            <div class="row">
                <InputTextLarge title="Name" name="name" id="name"
                    value={values.name} onBlur={handleBlur}
                    onChange={handleChange} error={errors.name} />
                <InputTextLarge title="Surname" name="surname" id="surname"
                    value={values.surname} onBlur={handleBlur}
                    onChange={handleChange} error={errors.surname} />
            </div>
            <div class="row">

                <InputTextLarge title="Date of birth" type="date" name="dob" id="dob"
                    value={values.dob} onBlur={handleBlur}
                    onChange={handleChange} error={errors.dob} />

                <InputTextLarge title="Place of birth" name="pob" id="pob"
                    value={values.pob} onBlur={handleBlur}
                    onChange={handleChange} error={errors.pob} />

            </div>
            <div class="row">

                <DropdownLarge title="Gender" options={DropdownConstants.GENDER} name="gender" id="gender"
                    value={values.gender} onBlur={handleBlur}
                    onChange={handleChange} error={errors.gender} />

                <DropdownLarge title="Marital status" options={DropdownConstants.FAMILY_STATUS} name="familyStatus" id="familyStatus"
                    value={values.familyStatus} onBlur={handleBlur}
                    onChange={handleChange} error={errors.familyStatus} />

            </div>
            <div class="row">

                <InputTextLarge title="Profession" name="profession" id="profession"
                    value={values.profession} onBlur={handleBlur}
                    onChange={handleChange} error={errors.profession} />

                <DropdownLarge title="Education level" options={DropdownConstants.SCHOOL_LEVEL} name="schoolLevel" id="schoolLevel"
                    value={values.schoolLevel} onBlur={handleBlur}
                    onChange={handleChange} error={errors.schoolLevel} />

            </div>
            <div class="row">

                <DropdownLarge title="League" options={DropdownConstants.LEAGUE} name="league" id="league"
                    value={values.league} onBlur={handleBlur}
                    onChange={handleChange} error={errors.league} />

                <InputTextLarge title="Phone" name="phone" id="phone"
                    value={values.phone} onBlur={handleBlur}
                    onChange={handleChange} error={errors.phone} />

            </div>
            <div class="row">

                <InputTextLarge title="Email" name="email" id="email"
                    value={values.email} onBlur={handleBlur}
                    onChange={handleChange} error={errors.email} />

                <DropdownLarge title="Je suis" options={roles}
                    dynamic={true} name="role" id="role" value={values._role} onBlur={handleBlur}
                    onChange={handleChange} error={errors._role} />

            </div>

            <div className="text-right">
                <FormButton title="Suivant" iconRight="chevron-right" onClick={nextPage} />
            </div>
            
        </>

    );

}


export default SignupStepOne