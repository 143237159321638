import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  DropdownLarge,
  InputPicture,
  InputTextLarge,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants, locals } from "../../../utilities";
import {
  getArrondissements,
  getDepartements,
  getRegions,
  getTournois,
  saveClub,
  updateClub,
  resetArrondissements,
  getLoggedInUser,
} from "../actions";

const ClubFormAdmin = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getLoggedInUser());
    if (location.state) initializeClub(location.state);
    loadLigue();
  }, []);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [league, setLeague] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [typeClub, setTypeClub] = useState("");
  const [_region, setRegion] = useState("");
  const [_departement, setDepartement] = useState("");
  const [_arrondissement, setArrondissement] = useState("");
  const [normal, setNormal] = useState(true);
  const [auxiliaire, setAuxiliaire] = useState(false);
  const [_tournois, setTournois] = useState(null);
  const [picture, setPicture] = useState([]);

  const initializeClub = (club) => {
    setId(club.id);
    setName(club.name);
    setLeague(club.league);
    setCode(club.code);
    setPicture(club.picture);
    setTypeClub(club.typeClub);
    if (club.auxiliaire) {
      setNormal(false);
      setAuxiliaire(true);
    } else {
      setNormal(true);
      setAuxiliaire(false);
    }
    setRegion(club._region.id);
    dispatch(getDepartements(club._region.id));
    setDepartement(club._departement.id);
    dispatch(getArrondissements(club._departement.id));
    setArrondissement(club._arrondissement.id);
  };

  const loadLigue = () => {
    const ligue = localStorage.getItem("fvApp");

    if (ligue !== locals.ADMIN_LOCALS) {
      setLeague(adminState.loggedInUser.league);
      setDisabled(true);
    }
  };

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "league":
        setLeague(e.target.value);
        break;
      case "code":
        setCode(e.target.value);
        break;
      case "typeClub":
        setTypeClub(e.target.value);
        break;
      case "_region":
        dispatch(getDepartements(e.target.value));
        setRegion(e.target.value);
        dispatch(resetArrondissements());
        break;
      case "_departement":
        dispatch(getArrondissements(e.target.value));
        setDepartement(e.target.value);
        break;
      case "_arrondissement":
        setArrondissement(e.target.value);
        break;
      case "normal":
        setNormal(true);
        setAuxiliaire(false);
        break;
      case "auxiliaire":
        setNormal(false);
        setAuxiliaire(true);
        setLeague("");
        dispatch(getTournois(true));
        break;
      case "tournois":
        setTournois(e.target.value);
        break;

      default:
        break;
    }
  };

  const callback = () => {
    navigate("/admin/club");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let club = {
      id,
      name,
      league,
      code,
      typeClub,
      _region,
      _departement,
      _arrondissement,
      _tournois,
    };

    if (picture.base64 === undefined) {
      club = {
        ...club,
        picture: picture,
      };
    } else {
      club = {
        ...club,
        picture: picture.base64,
      };
    }

    if (picture.length === 0) {
      alert("Please add a picture");
      return;
    }

    if (normal) {
      club = {
        ...club,
        auxiliaire: false,
      };
    } else {
      club = {
        ...club,
        auxiliaire: true,
      };
    }

    if (league === undefined) {
      alert("The field League is empty !");
    } else {
      id === ""
        ? dispatch(saveClub(club, callback))
        : dispatch(updateClub(club, callback));
    }
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!location.state ? "ADD" : "EDIT"} A CLUB
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={name}
                        required
                      />
                      {picture !== null ? (
                        <div className="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span className="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture.length !== 0 && (
                                <div className="col">
                                  {picture.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div
                                className="col-md-10"
                                style={{ marginTop: -19 }}
                              >
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <InputTextLarge
                        title="Code"
                        name="code"
                        id="code"
                        onChange={handleInputChange}
                        style={{ textTransform: "uppercase" }}
                        value={code}
                        required
                      />
                      <DropdownLarge
                        title="Type"
                        options={DropdownConstants.CLUB_TYPE}
                        name="typeClub"
                        id="typeClub"
                        onChange={handleInputChange}
                        value={typeClub}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="Region"
                        options={adminState.regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={_region}
                        required
                      />
                      <DropdownLarge
                        title="Departement"
                        options={adminState.departements}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={_departement}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="District"
                        options={adminState.arrondissements}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={_arrondissement}
                        required
                      />
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="test">
                            {"Normal or Auxiliary Club ?"}
                          </label>{" "}
                          <span className="text-danger ml-1">*</span>
                          <br />
                          <Checkbox
                            title="Normal"
                            id="normal"
                            onChange={handleInputChange}
                            checked={normal}
                          />
                          <Checkbox
                            title="Auxiliary"
                            id="auxiliaire"
                            onChange={handleInputChange}
                            checked={auxiliaire}
                          />
                        </div>
                      </div>

                      {auxiliaire ? (
                        <DropdownLarge
                          title="Auxiliary Tournament"
                          options={adminState.tournois}
                          dynamic={true}
                          name="tournois"
                          id="tournois"
                          onChange={handleInputChange}
                          value={_tournois}
                        />
                      ) : null}

                      {!auxiliaire ? (
                        <DropdownLarge
                          title="League"
                          options={DropdownConstants.LEAGUE}
                          name="league"
                          id="league"
                          onChange={handleInputChange}
                          disabled={disabled}
                          value={league}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!adminState.loading ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClubFormAdmin;
