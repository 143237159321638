import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeNotify } from '../actions';

const Notification = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(closeNotify())
  }, [dispatch]);


  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-bell fa-lg mr-3"></i></span>NOTIFICATIONS</h6>


            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              {/* <SearchBar /> */}
              <div className="card card-stats" >

                <div className="card-body">
                  <div style={{ textAlign: 'center' }}>
                    <i className="fa fa-bell fa-7x mb-5 mt-5"></i>
                    <h1>BIENTÔT DISPONIBLE</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification