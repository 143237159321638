import React from "react";
import { Modal } from "react-bootstrap";

const EStartMatchModal = ({ handleClose, show, onStartMatch, onAddLibero }) => {

  return (
    <Modal show={show} centered size="sm" onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>START MATCH</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-block justify-content-center text-center w-100">
          <img
            src="/assets/images/volleyball-net.png"
            alt="scored"
            width={120}
            height={120}
          />
          {/* <h3 className="mt-3">Start match now!!!!</h3> */}
          {/* <hr /> */}
        </div>

        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onStartMatch}
            style={{ backgroundColor: "#21517c", color: "white" }}
          >
            Start Match
          </button>
          <button className="btn btn-sm w-50" onClick={onAddLibero}>
            Add Liberos
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EStartMatchModal;
