import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from 'react-signature-canvas';

import { hideSignatureModal, setSignature } from '../screens/admin/actions';
import ModalFooter from './ModalFooter';


const SignatureModal = (props) => {
        
    let sigPad = {}

    const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

    const saveSignature =()=> {
        dispatch(setSignature(sigPad.getTrimmedCanvas().toDataURL()))
    }

    const close = () => {
        dispatch(hideSignatureModal())
    }


    return (
        <Modal centered size='lg'
            show={adminState.signatureModal}
            onHide={() => dispatch(hideSignatureModal())}>
            <Modal.Header tabIndex='-1' aria-hidden closeButton>Signature</Modal.Header>
            <Modal.Body>
                <div className="row">

                    <SignatureCanvas penColor='black' ref={(ref) => { sigPad = ref }}
                        canvasProps={{ width: 800, height: 300, className: 'sigCanvas' }} />

                </div>

                <div style={{ marginTop: 10, borderTopStyle: 'solid', marginBottom: 0, borderTopWidth: 2 }}>
                    <button class="btn btn-sm" onClick={() => close()} >Close</button>
                    <button class="btn btn-sm float-right btn-primary" onClick={()=>saveSignature()}>Save</button>
                </div>

            </Modal.Body>

            <ModalFooter />

        </Modal>
    );

}

export default SignatureModal