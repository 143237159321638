import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Spinner } from "react-bootstrap";
import { Provider } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./index.css";
import { reduxStore as configureStore } from "./utilities";
import Screens from "./screens";

const store = configureStore();

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner animation="border" role="status" />}>
      <ReactNotifications />
      <Screens />
    </Suspense>
  </Provider>
);
