import React, { useEffect } from "react";
import { Outlet, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  AdminHeader,
  AdminSidebar,
  Footer,
  SessionExpiredModal,
  PasswordModal,
  SignatureModal,
  RestrictedRoute,
} from "../../components";

import { closeNotify, getLoggedInUser } from "./actions";
import { Store } from "react-notifications-component";

const Admin = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminState.notifyDisplay !== "none") {
      Store.addNotification({
        message: adminState.notifyMessage,
        type: adminState.notifyType,
        insert: "bottom",
        container: "bottom-center",
        dismiss: {
          duration: 5000,
        },
      })
    }
    return () => {
      dispatch(closeNotify())
    }
  }, [adminState.notifyDisplay,adminState.notifyMessage,adminState.notifyType,dispatch]);

  return (
    <React.Fragment>
      {adminState.loggedInUser.length !== 0 ? (
        <AdminSidebar user={adminState.loggedInUser} />
      ) : null}

      <div className="main-content" id="panel" style={{ height: "100vh" }}>
        <AdminHeader />

        {/* <Notify /> */}

       <Outlet />
        <Footer />
      </div>
      <PasswordModal />
      <SessionExpiredModal />
      <SignatureModal />
    </React.Fragment>
  );
};

export default Admin;
