import React from 'react';

const InputTextLarge = ({ title, type = 'text', size = '6', error, ...other_props }) => {

    return (

        <div class={`col-lg-${size}`}>
            <div class="form-group" >
                <label for={title}>{title}</label>{other_props.required ? <span className="text-danger ml-1">*</span> : null}
                <input className={error ? 'form-control is-invalid' : 'form-control'} type={type} {...other_props} />
                {error && <small className="text-danger ml-1">{error}</small>}
            </div>

        </div>

    );
}


export default InputTextLarge;