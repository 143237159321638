import React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '.';

const DashboardCard = ({ title = 'Title', data = 0, route, color = 'blue', icon = 'volleyball-ball', loading }) => {

    return (

        <div class="col-xl-3 col-md-6">
            <div class="card card-stats">
                <Link to={`/admin/${route}`}>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase text-muted mb-0">{title}</h5>
                                <span class="h2 font-weight-bold mb-0">{loading ? <Loader size={30} align='left' /> : data}</span>
                            </div>
                            <div class="col-auto">
                                <div class={`icon icon-shape bg-gradient-${color} text-white rounded-circle shadow"`}>
                                    <i class={`fa fa-${icon}`}></i>
                                </div>
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                            <span class="text-success mr-2">{`${title} au total`}</span>
                        </p>
                    </div>
                </Link>
            </div>
        </div>

    );
}


export default DashboardCard;