import React, { useState, useEffect } from "react";

const MatchTableRow = ({
  position,
  onPlayerSelect,
  clubID,
  players,
  type,
  playerSelection,
  onDeselect,
  playerID,
  showDiff = true,
  showInput,
  setKitSelection,
  index,
  kitSelection,
  teamLabel,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState("");

  const onValueChange = (value) => {
    const player = players.filter((player) => player.id === value).shift();

    onPlayerSelect({
      player: { id: player.id, name: `${player.name} ${player.surname}` },
      position: player?.dossard || 1,
      clubID,
      type,
      index,
    });
    setSelectedPlayer(player.id);
  };

  const onKitChange = (value) => {
    // console.log(kitSelection,value)
    if(Object.values(kitSelection))
      setKitSelection({ index, kit: value, id: selectedPlayer, teamLabel });
  };

  const onDeselectPlayer = () => {
    onDeselect(selectedPlayer);
    setSelectedPlayer("");
  };

  useEffect(() => {
    if (playerID) {
      setSelectedPlayer(playerID);
    } else {
      setSelectedPlayer("");
    }
  }, [playerID]);

  return (
    <tr
      style={{
        backgroundColor: position > 8 && showDiff ? "#dd3d4550" : "white",
      }}
    >
      {position ? <td>{position}</td> : null}
      <td>
        <select
          onChange={(event) => onValueChange(event.target.value)}
          className="form-control form-control-sm"
          value={selectedPlayer}
        >
          <option></option>
          {players?.map((player, index) => (
            <option
              key={index}
              value={player.id}
              disabled={Object.keys(playerSelection || "{}").includes(
                player.id
              )}
            >
              {player.name} {player.surname}
            </option>
          ))}
        </select>
      </td>
      {showInput ? (
        <td>
          <input
            className="form-control form-control-sm"
            type="number"
            disabled={selectedPlayer === ""}
            onChange={({ target }) => onKitChange(target.value)}
          />
        </td>
      ) : null}
      <td>
        <button
          className="btn btn-primary btn-sm"
          style={{ padding: 5, margin: 0, border: "none" }}
          disabled={!selectedPlayer}
          onClick={onDeselectPlayer}
        >
          <i className="fa fa-redo"></i>
        </button>
      </td>
    </tr>
  );
};

export default MatchTableRow;
