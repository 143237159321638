import axios from 'axios';
import { SERVER } from '../../../utilities'

export const saveUser = (user) => {

    return axios.post(`${SERVER}users`, user,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }).then((response) => {
            return response
        }).catch((response) => {
            return response
        });
}

export const getUsers = (roleId, page, perPage) => {
    // return axios.get(`${SERVER}users?code=${roleId}&page=${page}&per_page=${perPage}`,
    return axios.get(`${SERVER}users`,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            },
            params: {
                roleId,
                perPage,
                page
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getUsersConseilAdministration = () => {
    
    return axios.get(`${SERVER}users/conseil-administration`,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getUsersCommision = () => {
    
    return axios.get(`${SERVER}users/commission`,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getUsersCount = (roleId) => {
    
    return axios.get(`${SERVER}users-count`,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            },
            params: {
                roleId
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getUser = (id) => {    
    return axios.get(`${SERVER}users/${id}`,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getUsersByLigue = (roleId, ligue) => {

    return axios.post(SERVER + 'users/findByLigue', {
        code: roleId,
        league: ligue
    },
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const checkDuplicateUser = (user) => {

    return axios.post(SERVER + 'users/check', user,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateUser = (user) => {

    return axios.put(SERVER + 'users/update', user,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const enableUser = (user) => {

    return axios.put(SERVER + 'users/enable', user,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteUser = (userId) => {

    return axios.delete(SERVER + 'users/' + userId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const printUsers = (roleId) => {

    return axios.get(SERVER + 'users-print', {
        code: roleId
    },
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getRole = (code) => {

    return axios.get(SERVER + 'roles/' + code,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getRoles = () => {

    return axios.get(SERVER + 'roles',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const saveRole = (role) => {

    return axios.post(SERVER + 'roles', role,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteRole = (roleId) => {

    return axios.delete(SERVER + 'roles/' + roleId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateRole = (role) => {

    return axios.put(SERVER + 'roles', role,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getGrade = (userId) => {

    return axios.get(SERVER + 'grades/' + userId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const saveClub = (club) => {

    return axios.post(SERVER + 'clubs', club,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateClub = (club) => {

    return axios.put(SERVER + 'clubs', club,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteClub = (clubId) => {

    return axios.delete(SERVER + 'clubs/' + clubId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getClub = () => {

    return axios.get(SERVER + 'clubs',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getClubCount = () => {

    return axios.get(SERVER + 'clubs-count',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getClubAuxiliaire = (typeClub) => {

    return axios.get(SERVER + 'clubs/auxiliaire/' + typeClub,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getClubByLigue = (league) => {

    return axios.get(SERVER + 'clubs/' + league,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getClubByName = (club) => {

    return axios.post(`${SERVER}clubs/check-name/`, club,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getClubByCode = (code) => {

    return axios.get(`${SERVER}clubs/check-code/${code}`,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getInscrit = () => {

    return axios.get(SERVER + 'inscrits',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getInscritCount = () => {

    return axios.get(SERVER + 'inscrits-count',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getInscritByLigue = (league) => {

    return axios.get(SERVER + 'inscrits/' + league,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getLoggedInUser = (accessToken) => {

    return axios.post(SERVER + 'users/find/', { accessToken },
        {
            headers: {
                "cybexai-fcvb-token": accessToken
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getRegions = () => {

    return axios.get(SERVER + 'regions',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getDepartements = (regionId) => {

    return axios.get(SERVER + 'departements/region/' + regionId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getArrondissements = (departementId) => {

    return axios.get(SERVER + 'arrondissements/departement/' + departementId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getLigueUsers = (ligueType) => {

    return axios.get(SERVER + 'users/ligue/' + ligueType,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getSubRoles = (role) => {

    return axios.get(SERVER + 'sub-roles/' + role,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const saveMatch = (match) => {

    return axios.post(SERVER + 'matchs', match,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getMatchs = () => {

    return axios.get(SERVER + 'matchs',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getMatchsByTournois = (tournoisId) => {

    return axios.get(SERVER + 'matchs-tournois/' + tournoisId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteMatch = (matchId) => {

    return axios.delete(SERVER + 'matchs/' + matchId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateMatch = (match) => {

    return axios.put(SERVER + 'matchs', match,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const saveTournois = (tournois) => {

    return axios.post(SERVER + 'tournois', tournois,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getTournois = () => {

    return axios.get(SERVER + 'tournois/',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getTournoisAuxiliaire = (typeTournois) => {

    return axios.get(SERVER + 'tournois/auxiliaire/' + typeTournois,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteTournois = (tournoisId) => {

    return axios.delete(SERVER + 'tournois/' + tournoisId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateTournois = (tournois) => {

    return axios.put(SERVER + 'tournois', tournois,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const saveMatriculeCode = (matriculeCode) => {

    return axios.post(SERVER + 'matricule-codes', matriculeCode,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getMatriculeCode = () => {

    return axios.get(SERVER + 'matricule-codes/',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteMatriculeCode = (matriculeCodeId) => {

    return axios.delete(SERVER + 'matricule-codes/' + matriculeCodeId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateMatriculeCode = (matriculeCode) => {

    return axios.put(SERVER + 'matricule-codes', matriculeCode,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const savePermission = (permission) => {

    return axios.post(SERVER + 'permissions', permission,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getPermission = () => {

    return axios.get(SERVER + 'permissions/',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }            
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deletePermission = (permissionId) => {

    return axios.delete(SERVER + 'permissions/' + permissionId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updatePermission = (permission) => {

    return axios.put(SERVER + 'permissions', permission,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const saveRolePermission = (rolePermission) => {

    return axios.post(SERVER + 'role-permissions', rolePermission,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getRolePermission = (roleCode) => {

    return axios.get(SERVER + 'role-permissions/',
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            },
            params: {
                roleCode
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deleteRolePermission = (rolePermissionId) => {

    return axios.delete(SERVER + 'role-permissions/' + rolePermissionId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updateRolePermission = (rolePermission) => {

    return axios.put(SERVER + 'role-permissions', rolePermission,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const savePlayerTransfer = (playerTransfer) => {

    return axios.post(SERVER + 'player-transfer', playerTransfer,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const getPlayerTransfer = (playerId) => {

    return axios.get(SERVER + 'player-transfer/'+playerId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const deletePlayerTransfer = (playerTransferId) => {

    return axios.delete(SERVER + 'player-transfer/' + playerTransferId,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}

export const updatePlayerTransfer = (playerTransfer) => {

    return axios.put(SERVER + 'player-transfer', playerTransfer,
        {
            headers: {
                "cybexai-fcvb-token": localStorage.getItem('fvAccessToken')
            }
        }
    ).then((response) => {
        return response
    }).catch((response) => {
        return response
    });

}