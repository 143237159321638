import React, { useCallback, useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateMatch } from "../../admin/actions";
import {
  EExitModal,
  EFieldCard,
  EInjuryModal,
  ELiberoModal,
  EOnCourt,
  ESetTimeoutModal,
  ETopBar,
} from "../components";
import EAssignPointModal from "../components/EAssignPointModal";
import EBottomAction from "../components/EBottomAction";
import ELiberoStartModal from "../components/ELiberoStartModal";
import EManualChangeModal from "../components/EManualChangeModal";
import EOnBench from "../components/EOnBench";
import ESanctionModal from "../components/ESanctionModal";
import ESetEntrantModal from "../components/ESetEntrantModal";
import EStartMatchModal from "../components/EStartMatchModal";
import EStopMatchModal from "../components/EStopMatchModal";
import EStopSetModal from "../components/EStopSetModal";
import ESubstitutionModal from "../components/ESubstitutionModal";
import ESupportModal from "../components/ESupportModal";
import ETimeoutModal from "../components/ETimeoutModal";
import EUndoModal from "../components/EUndoModal";
import "../styles.css";

export default function EScoreSheet(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedMatch, kiffoffTeam, teamA } = JSON.parse(
    localStorage.getItem("fcvb")
  );

  const roster = JSON.parse(selectedMatch.roster || "{}");
  const captainSelection = roster.captainSelection;

  const [playerSelection, setPlayerSelection] = useState(
    JSON.parse(localStorage.getItem("fcvb") || {})?.playerSelection
  );

  const teamColors = {
    [selectedMatch._participantOne?.id]: roster.teamAColor,
    [selectedMatch._participantTwo?.id]: roster.teamBColor,
  };

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [setTimeoutMin, setSetTimeoutMin] = useState(2);
  const [counterMinutes, setCounterMinutes] = useState(0);
  const [undoModal, setUndoModal] = useState(false);
  const [startMatch, setStartMatch] = useState(false);
  const [ballPosition, setBallPosition] = useState(true);
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [stopSetModal, setStopSetModal] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [sanctionModal, setSanctionModal] = useState(false);
  const [isNewEntrants, setIsNewEntrants] = useState(false);
  const [stopMatchModal, setStopMatchModal] = useState(false);
  const [timeoutSetModal, setTimeoutSetModal] = useState(false);
  const [newEntrantModal, setNewEntrantModal] = useState(false);
  const [assignPointModal, setAssignPointModal] = useState(false);
  const [substitutionModal, setSubstitutionModal] = useState(false);
  const [manualChangeModal, setManualChangeModal] = useState(false);
  const [liberoStartModal, setLiberoStartModal] = useState(false);
  const [liberoModal, setLiberoModal] = useState(false);
  const [injuryModal, setInjuryModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [teamSelectedLetter, setTeamSelectedLetter] = useState("");
  const [lastTeamScored, setLastTeamScored] = useState("");
  const [setTimeoutTitle, setSetTimeoutTitle] = useState(
    "Timeout before next set"
  );
  const [sets, setSets] = useState([
    {
      number: 1,
      time: "",
      active: true,
      minutes: 0,
    },
  ]);
  const [score, setScore] = useState({
    [selectedMatch._participantOne?.id]: {
      points: 0,
      teamName: selectedMatch._participantOne?.name,
      teamID: selectedMatch._participantOne?.id,
      scoreSet: [{ set: 1, score: 0 }],
    },
    [selectedMatch._participantTwo?.id]: {
      points: 0,
      teamName: selectedMatch._participantTwo?.name,
      teamID: selectedMatch._participantTwo?.id,
      scoreSet: [{ set: 1, score: 0 }],
    },
  });
  const [selectedTeam, setSelectedTeam] = useState({});
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState({});
  const [selectedSubTeamPlayers, setSelectedSubTeamPlayers] = useState({});
  const [timeouts, setTimeouts] = useState({});
  const [sanctions, setSanctions] = useState([]);
  const [injuries, setInjuries] = useState([]);
  const [substitutions, setSubstitutions] = useState([]);
  const [liberos, setLiberos] = useState([]);
  const [teamAonCourt, setTeamAonCourt] = useState(
    Object.values(playerSelection).filter(
      (player) => player.clubID === teamA && player.type === "onCourt"
    )
  );
  const [teamBonCourt, setTeamBonCourt] = useState(
    Object.values(playerSelection).filter(
      (player) => player.clubID !== teamA && player.type === "onCourt"
    )
  );
  const [teamAonBench, setTeamAonBench] = useState(
    Object.values(playerSelection).filter(
      (player) => player.clubID === teamA && player.type === "replacement"
    )
  );
  const [teamBonBench, setTeamBonBench] = useState(
    Object.values(playerSelection).filter(
      (player) => player.clubID !== teamA && player.type === "replacement"
    )
  );
  const [currentTeamA, setCurrentTeamA] = useState(
    Object.values(playerSelection).filter((player) => player.clubID === teamA)
  );
  const [currentTeamB, setCurrentTeamB] = useState(
    Object.values(playerSelection).filter((player) => player.clubID !== teamA)
  );
  const [undoHistory, setUndoHistory] = useState([]);
  const teams = {
    [selectedMatch._participantOne?.id]: selectedMatch._participantOne,
    [selectedMatch._participantTwo?.id]: selectedMatch._participantTwo,
  };

  const assignItem = (team, source, byPassLibero) => {
    setTeamSelectedLetter(team);
    if (team === "A") {
      setSelectedTeam(teams[currentTeamA[0].clubID]);
      setSelectedTeamPlayers(teamAonCourt);
    } else {
      setSelectedTeam(teams[currentTeamB[0].clubID]);
      setSelectedTeamPlayers(teamBonCourt);
    }
    setSelectedTime(`${hours}:${minutes}:${seconds}`);
    if (source === "point") {
      setAssignPointModal(true);
    } else if (source === "sanction") {
      setSanctionModal(true);
    } else if (source === "timeout") {
      setTimeoutModal(true);
    } else if (source === "substitution") {
      setSubstitutionModal(true);
      if (team === "A") {
        setSelectedSubTeamPlayers(teamAonBench);
      } else {
        setSelectedSubTeamPlayers(teamBonBench);
      }
    } else if (source === "libero") {
      if (byPassLibero) {
        setLiberoModal(true);
      } else {
        if (team === "A" && lastTeamScored === "A") {
          setLiberoModal(true);
        } else if (team === "B" && lastTeamScored === "B") {
          setLiberoModal(true);
        } else {
          Store.addNotification({
            message: "Score 1 point before libero gets in or out!",
            type: "danger",
            insert: "top",
            container: "top-center",
            dismiss: {
              duration: 3000,
            },
          });
        }
      }
    } else if (source === "injury") {
      setInjuryModal(true);
    }
  };

  const closeModals = () => {
    setAssignPointModal(false);
    setSanctionModal(false);
    setSubstitutionModal(false);
    setTimeoutModal(false);
    setStopSetModal(false);
    setStopMatchModal(false);
    setManualChangeModal(false);
    setSupportModal(false);
    setTimeoutSetModal(false);
    setNewEntrantModal(false);
    setIsNewEntrants(false);
    setUndoModal(false);
    setLiberoModal(false);
    setInjuryModal(false);
    setExitModal(false);
    setLiberoStartModal(false);
    setSelectedTeam({});
    setSelectedTeamPlayers({});
    setSelectedSubTeamPlayers({});
    setSelectedTime("");
  };

  const callback = () => {
    localStorage.removeItem("fcvb");
    navigate("/admin/match");
  };

  const onConfirmStopMatch = () => {

    const teamA = Object.values(score)[0];
    const teamB = Object.values(score)[1];
  
    const teamAScore = teamA.scoreSet.reduce((a,b)=>a+b.score,0)
    const teamBScore = teamB.scoreSet.reduce((a,b)=>a+b.score,0)

    const _winner = teamAScore > teamBScore ? teamA.teamID : teamAScore < teamBScore ? teamB.teamID : null

    const match = {
      timeouts,
      sanctions,
      injuries,
      substitutions,
      liberos,
      undoHistory,
      score,
      sets
    };

    const toSave = {
      ...selectedMatch,
      _winner,
      status: "PLAYED",
      scoresheet: JSON.stringify(match),
    };

    dispatch(updateMatch(toSave, callback));
  };

  const swapSet = () => {
    const tempA = currentTeamA;
    const tempB = currentTeamB;
    setTeamAonCourt(currentTeamB.filter((player) => player.type === "onCourt"));
    setTeamBonCourt(currentTeamA.filter((player) => player.type === "onCourt"));
    setTeamAonBench(
      currentTeamB.filter((player) => player.type === "replacement")
    );
    setTeamBonBench(
      currentTeamA.filter((player) => player.type === "replacement")
    );
    setCurrentTeamA(tempB);
    setCurrentTeamB(tempA);
  };

  const openEntrantModal = () => {
    setTimeoutSetModal(false);
    setNewEntrantModal(true);
  };

  const setEntrants = (data) => {
    const players = Object.values(playerSelection);

    const onbenchTemp = players
      .filter((item) => !Object.keys(data || {})?.includes(item.player.id))
      .map((val) => ({ ...val, type: "replacement" }));

    const newA = Object.values(data).filter(
      (val) => val.clubID === currentTeamA[0].clubID
    );
    const newB = Object.values(data).filter(
      (val) => val.clubID === currentTeamB[0].clubID
    );
    setTeamAonCourt(newB);
    setTeamBonCourt(newA);
    setTeamAonBench(
      onbenchTemp.filter((val) => val.clubID === currentTeamB[0].clubID)
    );
    setTeamBonBench(
      onbenchTemp.filter((val) => val.clubID === currentTeamA[0].clubID)
    );
    setCurrentTeamA(newB);
    setCurrentTeamB(newA);
    closeModals();
  };

  const confirmStopSet = useCallback(
    (time) => {
      const set = sets.filter((set) => set.active)[0]?.number;
      const teamA = Object.values(score)[0];
      const teamB = Object.values(score)[1];

      // if (set === 5) {
      //   const updateSet = sets.map((set) => {
      //     if (set.active) {
      //       return {
      //         ...set,
      //         time: time,
      //         active: false,
      //         minutes: counterMinutes,
      //       };
      //     }
      //     return set;
      //   });
      //   setSets([...updateSet]);
      //   setNewEntrantModal(false);
      //   setStopMatchModal(true);
      // } else {
      if (set === 1) {
        setSets([
          {
            number: 1,
            time: time,
            active: false,
            minutes: counterMinutes,
          },
          {
            number: 2,
            time: "",
            active: true,
            minutes: 0,
          },
        ]);

        const resultSet = {
          [teamA.teamID]: {
            ...teamA,
            points: 0,
            scoreSet: [{ set: 1, score: teamA.points }],
          },
          [teamB.teamID]: {
            ...teamB,
            points: 0,
            scoreSet: [{ set: 1, score: teamB.points }],
          },
        };
        setScore(resultSet);
      } else {
        const updateSet = sets.map((set) => {
          if (set.active) {
            return {
              ...set,
              time: time,
              active: false,
              minutes: counterMinutes,
            };
          }
          return set;
        });

        const resultSet = {
          [teamA.teamID]: {
            ...teamA,
            points: 0,
            scoreSet: [...teamA.scoreSet, { set: set, score: teamA.points }],
          },
          [teamB.teamID]: {
            ...teamB,
            points: 0,
            scoreSet: [...teamB.scoreSet, { set: set, score: teamB.points }],
          },
        };
        setScore(resultSet);

        setSets([
          ...updateSet,
          {
            number: updateSet.length + 1,
            time: "",
            active: true,
            minutes: 0,
          },
        ]);
      }
      // swapSet();
      setStopSetModal(false);
      if (kiffoffTeam !== currentTeamA[0].clubID) {
        setBallPosition(true);
      } else {
        setBallPosition(false);
      }
      setSetTimeoutTitle("Timeout before next set");
      // setSetTimeoutMin(2);
      // setCounterMinutes(0);
      setNewEntrantModal(true);
      // setIsNewEntrants(true);
    },
    [counterMinutes, currentTeamA, kiffoffTeam, score, sets]
  );

  const onStopMatch = () => {
    setSelectedTime(`${hours}:${minutes}:${seconds}`);
    setStopMatchModal(true);
  };

  const onStopSet = () => {
    setSelectedTime(`${hours}:${minutes}:${seconds}`);
    setStopSetModal(true);
  };

  const onAssignPoint = (ID) => {
    let localPlayerSelection = playerSelection;

    setUndoHistory([
      ...undoHistory,
      { team: "", time: "", set: "", score: "" },
    ]);
    setScore((prev) => ({
      ...prev,
      [ID]: { ...prev[ID], points: prev[ID].points + 1 },
    }));
    if (ID === currentTeamA[0].clubID) {
      setBallPosition(true);
      setLastTeamScored("A");
      if (lastTeamScored === "B") {
        let shouldUpdate = true;

        let tempTeamA = teamAonCourt.map((val) => {
          let temp;
          if (val.position === 1) {
            temp = { ...val, position: 6 };
          } else if (val.position === 6) {
            temp = { ...val, position: 5 };
          } else if (val.position === 5) {
            if (liberos[val.player.id]?.active) {
              shouldUpdate = false;
              Store.addNotification({
                message: "Libero must exit!",
                type: "danger",
                insert: "top",
                container: "top-center",
                dismiss: {
                  duration: 5000,
                },
              });
              assignItem("A", "libero", true);
            }
            temp = { ...val, position: 4 };
          } else if (val.position === 4) {
            temp = { ...val, position: 3 };
          } else if (val.position === 3) {
            temp = { ...val, position: 2 };
          } else if (val.position === 2) {
            temp = { ...val, position: 1 };
          }
          const result = {
            ...localPlayerSelection,
            [temp.player.id]: temp,
          };
          localPlayerSelection = result;
          return temp;
        });
        if (shouldUpdate) {
          setTeamAonCourt(tempTeamA);
          setPlayerSelection(localPlayerSelection);
        }
      }
    } else {
      setBallPosition(false);
      setLastTeamScored("B");
      if (lastTeamScored === "A") {
        let shouldUpdate = true;
        let tempTeamB = teamBonCourt.map((val) => {
          let temp;
          if (val.position === 4) {
            temp = { ...val, position: 3 };
          } else if (val.position === 3) {
            temp = { ...val, position: 2 };
          } else if (val.position === 2) {
            temp = { ...val, position: 1 };
          } else if (val.position === 1) {
            temp = { ...val, position: 6 };
          } else if (val.position === 6) {
            temp = { ...val, position: 5 };
          } else if (val.position === 5) {
            if (liberos[val.player.id]?.active) {
              shouldUpdate = false;
              Store.addNotification({
                message: "Libero must exit!",
                type: "danger",
                insert: "top",
                container: "top-center",
                dismiss: {
                  duration: 5000,
                },
              });
              assignItem("B", "libero", true);
            }
            temp = { ...val, position: 4 };
          }
          const result = {
            ...localPlayerSelection,
            [temp.player.id]: temp,
          };
          localPlayerSelection = result;
          return temp;
        });
        if (shouldUpdate) {
          setTeamBonCourt(tempTeamB);
          setPlayerSelection(localPlayerSelection);
        }
      }
    }

    // closeModals();
  };

  const onSetTimeout = (data) => {
    setTimeouts((prev) => ({
      ...prev,
      [data.startTime]: {
        ...prev,
        set: sets.filter((set) => set.active)[0]?.number,
        team: selectedTeam?.id,
        startTime: data.startTime,
        endTime: `${hours}:${minutes}:${seconds}`,
        type: data.type,
        score: `${score[teams[currentTeamA[0].clubID]?.id]?.points} : ${
          score[teams[currentTeamB[0].clubID]?.id]?.points
        }`,
      },
    }));

    closeModals();
  };

  const onSubstitute = (data) => {
    if (!data) {
      return;
    }
    let localPlayerSelection = playerSelection;

    //Perform Substitution
    (teamSelectedLetter === "A" ? teamAonCourt : teamBonCourt).forEach(
      ({ player, position }) => {
        const playerSelections = Object.values(data.playerSelection);
        playerSelections.forEach((pSelection) => {
          if (pSelection.playerOut === player.id) {
            const playerIn = localPlayerSelection[pSelection.playerIn];
            const playerOut = localPlayerSelection[pSelection.playerOut];
            const result = {
              ...localPlayerSelection,
              [pSelection.playerIn]: { ...playerIn, type: "onCourt", position },
              [pSelection.playerOut]: { ...playerOut, type: "replacement" },
            };
            // setPlayerSelection(result);
            localPlayerSelection = result;
          }
        });
      }
    );

    // Update Team oncourt & onbench with substitutes
    setPlayerSelection(localPlayerSelection);

    if (teamSelectedLetter === "A") {
      setTeamAonCourt(
        Object.values(localPlayerSelection).filter(
          (player) =>
            player.clubID === currentTeamA[0].clubID &&
            player.type === "onCourt"
        )
      );
      setTeamAonBench(
        Object.values(localPlayerSelection).filter(
          (player) =>
            player.clubID === currentTeamA[0].clubID &&
            player.type === "replacement"
        )
      );
    } else {
      setTeamBonCourt(
        Object.values(localPlayerSelection).filter(
          (player) =>
            player.clubID === currentTeamB[0].clubID &&
            player.type === "onCourt"
        )
      );

      setTeamBonBench(
        Object.values(localPlayerSelection).filter(
          (player) =>
            player.clubID === currentTeamB[0].clubID &&
            player.type === "replacement"
        )
      );
    }

    setSubstitutions([
      ...substitutions,
      {
        team: data.team.id,
        time: data.time,
        set: sets.filter((set) => set.active)[0]?.number,
        players: data.playerSelection,
      },
    ]);

    closeModals();
  };

  const onSanction = (data) => {
    setSanctions([
      ...sanctions,
      {
        set: sets.filter((set) => set.active)[0]?.number,
        team: selectedTeam?.id,
        time: data.time,
        sanction: data.sanction,
        player: data.player,
        playerType: data.playerType,
      },
    ]);
    closeModals();
  };

  const onInjury = (data) => {
    setInjuries([
      ...injuries,
      {
        set: sets.filter((set) => set.active)[0]?.number,
        team: selectedTeam?.id,
        time: data.time,
        player: data.player,
        playerSubID: data.playerSubID,
      },
    ]);
    if (data.playerSubID) {
      onSubstitute(data);
    }
    closeModals();
  };

  const onLibero = (data) => {
    const temp = liberos.map((val) => ({ ...val, active: false }));
    setLiberos([
      ...temp,
      {
        set: sets.filter((set) => set.active)[0]?.number,
        ...data,
      },
    ]);
    const updatedTeam = (
      teamSelectedLetter === "A" ? teamAonCourt : teamBonCourt
    ).map((val) => {
      let temp = val;
      if (val.player.id === data.playerBack) {
        temp = { ...temp, player: playerSelection[data.libero].player };
      }
      return temp;
    });
    const updatedBenchTeam = (
      teamSelectedLetter === "A" ? teamAonBench : teamBonBench
    ).map((val) => {
      let temp = val;
      if (val.player.id === data.libero) {
        temp = { ...temp, player: playerSelection[data.playerBack].player };
      }
      return temp;
    });
    if (teamSelectedLetter === "A") {
      setTeamAonCourt(updatedTeam);
      setTeamAonBench(updatedBenchTeam);
    } else {
      setTeamBonCourt(updatedTeam);
      setTeamBonBench(updatedBenchTeam);
    }

    closeModals();
  };

  const canSetTimeout = () => {
    if (
      score[teams[currentTeamA[0].clubID]?.id]?.points === 0 &&
      score[teams[currentTeamB[0].clubID]?.id]?.points === 0
    ) {
      return false;
    }
    return true;
  };

  const onSetTechTimeout = () => {
    setSetTimeoutTitle("Technical timeout");
    setSetTimeoutMin(0);
    setTimeoutSetModal(true);
  };

  const onExit = () => {
    closeModals();
    navigate("/admin/match");
  };

  useEffect(
    function () {
      setTimeout(function () {
        if (startMatch) {
          if (seconds === 59) {
            if (minutes === 59) {
              setSeconds(0);
              setMinutes(0);
              setHours(hours + 1);
            } else {
              setSeconds(0);
              setMinutes(minutes + 1);
            }
            setCounterMinutes(counterMinutes + 1);
          } else {
            setSeconds(seconds + 1);
          }
        }
      }, 1000);
    },
    [startMatch, seconds, minutes, hours, counterMinutes]
  );

  useEffect(() => {
    const team1Score = score[teams[currentTeamA[0].clubID]?.id]?.points;
    const team2Score = score[teams[currentTeamB[0].clubID]?.id]?.points;

    if (
      (team1Score >= 25 || team2Score >= 25) &&
      Math.abs(team2Score - team1Score) > 1
    ) {
      confirmStopSet(`${hours}:${minutes}:${seconds}`);
    }
  }, [
    score,
    teams,
    currentTeamA,
    currentTeamB,
    confirmStopSet,
    minutes,
    seconds,
    hours,
  ]);

  return (
    <main>
      <div className="container-fluid">
        <div className="row align-items-center d-flex justify-content-center">
          <div className="d-flex align-items-center">
            <div className="floating-buttons">
              <div className="inner">
                <>
                  <button
                    type="button"
                    className="history"
                    onClick={onSetTechTimeout}
                  >
                    <i className="fa fa-clock"></i>
                  </button>
                  <small className="text-center">TECHNICAL TIMEOUT</small>
                </>
                <>
                  <button
                    type="button"
                    className="TT stop"
                    onClick={onStopMatch}
                  >
                    <i className="fa fa-stop"></i>
                  </button>
                  <small className="text-center">MATCH</small>
                </>
                <>
                  <button
                    type="button"
                    className="TT exit"
                    onClick={() => setExitModal(true)}
                  >
                    <i className="fa fa-power-off"></i>
                  </button>
                  <small className="text-center">EXIT</small>
                </>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div>
              <ETopBar
                team1Score={score[teams[currentTeamA[0].clubID]?.id]?.points}
                team2Score={score[teams[currentTeamB[0].clubID]?.id]?.points}
                timer={`${hours}:${minutes}:${seconds}`}
                team1={teams[currentTeamA[0].clubID]}
                team2={teams[currentTeamB[0].clubID]}
                teamColors={teamColors}
              />
              <div className="deep-details">
                <EOnCourt
                  data={teamAonCourt}
                  captainSelection={captainSelection}
                />
                <div>
                  <div className="block-title text-center d-flex justify-content-center">
                    <div className="set">
                      SET {sets.filter((set) => set.active)[0]?.number}
                    </div>
                  </div>
                  <div className="field-card text-white">
                    <div className="field-row position-relative">
                      {ballPosition ? (
                        <div className="ball-posession ball-left">
                          <i className="fa fa-volleyball-ball"></i>
                        </div>
                      ) : null}
                      {teamAonCourt
                        .filter((val) =>
                          [5, 6, 1].includes(Number(val.position))
                        )
                        .sort(
                          (a, b) =>
                            [5, 6, 1].indexOf(a.position) -
                            [5, 6, 1].indexOf(b.position)
                        )
                        .map((val, index) => (
                          <EFieldCard
                            teamColors={teamColors}
                            clubID={val.clubID}
                            name={val.player.name}
                            playerID={val.player.id}
                            position={`${val.kit}`}
                            key={index}
                            captain={captainSelection[val.player.id]?.role}
                          />
                        ))}
                    </div>

                    <div className="field-row">
                      {teamAonCourt
                        .filter((val) =>
                          [4, 3, 2].includes(Number(val.position))
                        )
                        .sort(
                          (a, b) =>
                            [4, 3, 2].indexOf(a.position) -
                            [4, 3, 2].indexOf(b.position)
                        )
                        .map((val, index) => (
                          <EFieldCard
                            teamColors={teamColors}
                            clubID={val.clubID}
                            name={val.player.name}
                            playerID={val.player.id}
                            position={`${val.kit}`}
                            key={index}
                            captain={captainSelection[val.player.id]?.role}
                          />
                        ))}
                    </div>

                    <div className="field-row">
                      {!ballPosition ? (
                        <div className="ball-posession ball-right">
                          <i className="fa fa-volleyball-ball"></i>
                        </div>
                      ) : null}
                      {teamBonCourt
                        .filter((val) =>
                          [2, 3, 4].includes(Number(val.position))
                        )
                        .sort(
                          (a, b) =>
                            [2, 3, 4].indexOf(a.position) -
                            [2, 3, 4].indexOf(b.position)
                        )
                        .map((val, index) => (
                          <EFieldCard
                            teamColors={teamColors}
                            clubID={val.clubID}
                            name={val.player.name}
                            playerID={val.player.id}
                            position={`${val.kit}`}
                            key={index}
                            captain={captainSelection[val.player.id]?.role}
                          />
                        ))}
                    </div>

                    <div className="field-row">
                      {teamBonCourt
                        .filter((val) =>
                          [1, 6, 5].includes(Number(val.position))
                        )
                        .sort(
                          (a, b) =>
                            [1, 6, 5].indexOf(a.position) -
                            [1, 6, 5].indexOf(b.position)
                        )
                        .map((val, index) => (
                          <EFieldCard
                            teamColors={teamColors}
                            clubID={val.clubID}
                            name={val.player.name}
                            playerID={val.player.id}
                            position={`${val.kit}`}
                            key={index}
                            captain={captainSelection[val.player.id]?.role}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <EOnCourt
                  side="right"
                  data={teamBonCourt}
                  captainSelection={captainSelection}
                />

                <EOnBench
                  data={teamAonBench}
                  captainSelection={captainSelection}
                />

                <div>
                  <div className="point-assignment">
                    <button
                      className="point-assignment-btn"
                      onClick={() =>
                        onAssignPoint(teams[currentTeamA[0].clubID]?.id)
                      }
                    >
                      Assign Point (A)
                    </button>
                    <button
                      className="point-assignment-btn"
                      onClick={() =>
                        onAssignPoint(teams[currentTeamB[0].clubID]?.id)
                      }
                    >
                      Assign Point (B)
                    </button>
                  </div>
                  <div className="input-table">
                    <div className="table-head">
                      <div className="table-title">S</div>
                      <div className="table-title">TO</div>
                      <div className="table-title">Pt</div>
                      <div className="table-title">Set</div>
                      <div className="table-title">Time</div>
                      <div className="table-title">Pt</div>
                      <div className="table-title">TO</div>
                      <div className="table-title">S</div>
                    </div>
                    {[1, 2, 3, 4, 5].map((val) => (
                      <div key={val} className="table-data">
                        <div className="data">
                          {
                            substitutions
                              .filter(
                                (substitution) =>
                                  substitution.team ===
                                    currentTeamA[0].clubID &&
                                  substitution.set === val
                              )
                              ?.reduce(
                                (a, b) => a + Object.values(b.players).length,
                                0
                              )
                            // substitutions.filter(
                            //   (substitution) =>
                            //     substitution.team === currentTeamA[0].clubID &&
                            //     substitution.set === val
                            // )?.length
                          }
                        </div>
                        <div className="data">
                          {
                            Object.values(timeouts).filter(
                              (time) =>
                                time.set === val &&
                                time.team === currentTeamA[0].clubID
                            )?.length
                          }
                        </div>
                        <div className="data">
                          {score[currentTeamA[0].clubID]?.scoreSet?.filter(
                            ({ set }) => set === val
                          )[0]?.score || 0}
                        </div>
                        <div className="data">{val}</div>
                        <div className="data">
                          {sets.filter(({ number }) => number === val)[0]
                            ?.minutes || "0"}{" "}
                          min
                        </div>
                        <div className="data">
                          {score[currentTeamB[0].clubID]?.scoreSet?.filter(
                            ({ set }) => set === val
                          )[0]?.score || 0}
                        </div>
                        <div className="data">
                          {
                            Object.values(timeouts).filter(
                              (time) =>
                                time.set === val &&
                                time.team === currentTeamB[0].clubID
                            )?.length
                          }
                        </div>
                        <div className="data">
                          {substitutions
                            .filter(
                              (substitution) =>
                                substitution.team === currentTeamB[0].clubID &&
                                substitution.set === val
                            )
                            ?.reduce(
                              (a, b) => a + Object.values(b.players).length,
                              0
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <EOnBench
                  side="right"
                  data={teamBonBench}
                  captainSelection={captainSelection}
                />

                <EBottomAction
                  timeout={
                    Object.values(timeouts).filter(
                      (time) =>
                        time.set ===
                          sets.filter((set) => set.active)[0]?.number &&
                        time.team === currentTeamA[0].clubID
                    )?.length
                  }
                  substitution={substitutions
                    .filter(
                      (substitution) =>
                        substitution.team === currentTeamA[0].clubID
                    )
                    ?.reduce((a, b) => a + Object.values(b.players).length, 0)}
                  timeoutClick={() =>
                    canSetTimeout() && assignItem("A", "timeout")
                  }
                  substitutionClick={() => assignItem("A", "substitution")}
                  liberoClick={() => assignItem("A", "libero")}
                  injuryClick={() => assignItem("A", "injury")}
                />

                <div className="bottom-actions middle-actions">
                  <div className="LHS">
                    <div
                      className="action saction"
                      onClick={() => assignItem("A", "sanction")}
                    >
                      Sanction (A)
                    </div>
                    <div className="action challenge">challenge (A)</div>
                  </div>
                  <div className="RHS">
                    <div
                      className="action saction"
                      onClick={() => assignItem("B", "sanction")}
                    >
                      Sanction (B)
                    </div>
                    <div className="action challenge">challenge (B)</div>
                  </div>
                </div>
                <EBottomAction
                  side="right"
                  timeout={
                    Object.values(timeouts).filter(
                      (time) =>
                        time.set ===
                          sets.filter((set) => set.active)[0]?.number &&
                        time.team === currentTeamB[0].clubID
                    )?.length
                  }
                  substitution={substitutions
                    .filter(
                      (substitution) =>
                        substitution.team === currentTeamB[0].clubID
                    )
                    ?.reduce((a, b) => a + Object.values(b.players).length, 0)}
                  timeoutClick={() =>
                    canSetTimeout() && assignItem("B", "timeout")
                  }
                  substitutionClick={() => assignItem("B", "substitution")}
                  liberoClick={() => assignItem("B", "libero")}
                  injuryClick={() => assignItem("B", "injury")}
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="floating-buttons">
              <div className="inner">
                <div className="pop-notification d-none">
                  <span>Updated Captain court for Lorem Ipsum Team</span>
                  <div className="undo">Undo</div>
                </div>
                <>
                  <button
                    className="history"
                    onClick={() => setUndoModal(true)}
                  >
                    <i className="fa fa-undo"></i>
                  </button>
                  <small className="text-center">UNDO</small>
                </>
                <>
                  <button
                    className="TT"
                    onClick={() => setManualChangeModal(true)}
                  >
                    <i className="fa fa-cog"></i>
                  </button>
                  <small className="text-center">MANUAL CHANGES</small>
                </>
                <>
                  <button className="TT stop" onClick={onStopSet}>
                    <i className="fa fa-stop"></i>
                  </button>
                  <small className="text-center">
                    SET {sets.filter((set) => set.active)[0]?.number}
                  </small>
                </>
              </div>
            </div>
          </div>
        </div>

        {timeoutModal && (
          <ETimeoutModal
            show={timeoutModal}
            team={selectedTeam}
            time={selectedTime}
            timeoutCount={
              Object.values(timeouts).filter(
                (time) =>
                  time.set === sets.filter((set) => set.active)[0]?.number &&
                  time.team === selectedTeam?.id
              )?.length
            }
            data={Object.values(timeouts)}
            players={selectedTeamPlayers}
            onSetTimeout={(time) => onSetTimeout(time)}
            handleClose={closeModals}
          />
        )}

        {assignPointModal && (
          <EAssignPointModal
            show={assignPointModal}
            team={selectedTeam}
            time={selectedTime}
            players={selectedTeamPlayers}
            onAssignPoint={onAssignPoint}
            handleClose={closeModals}
          />
        )}

        {sanctionModal && (
          <ESanctionModal
            show={sanctionModal}
            team={selectedTeam}
            time={selectedTime}
            data={sanctions}
            onBench={teamSelectedLetter === "A" ? teamAonBench : teamBonBench}
            onSanction={onSanction}
            players={selectedTeamPlayers}
            handleClose={closeModals}
          />
        )}

        {substitutionModal && (
          <ESubstitutionModal
            show={substitutionModal}
            team={selectedTeam}
            time={selectedTime}
            onSubstitute={onSubstitute}
            players={selectedTeamPlayers}
            onBenchPlayers={selectedSubTeamPlayers}
            handleClose={closeModals}
            captainSelection={captainSelection}
            subCount={substitutions
              .filter(
                (substitution) =>
                  substitution.team ===
                  (teamSelectedLetter === "A"
                    ? currentTeamA[0].clubID
                    : currentTeamB[0].clubID)
              )
              ?.reduce((a, b) => a + Object.values(b.players).length, 0)}
          />
        )}

        {stopSetModal && (
          <EStopSetModal
            show={stopSetModal}
            team={selectedTeam}
            time={selectedTime}
            score={score}
            confirmStopSet={confirmStopSet}
            set={sets.filter((set) => set.active)[0]?.number}
            players={selectedTeamPlayers}
            onBenchPlayers={selectedSubTeamPlayers}
            handleClose={closeModals}
          />
        )}

        {stopMatchModal && (
          <EStopMatchModal
            score={score}
            show={stopMatchModal}
            team={selectedTeam}
            time={selectedTime}
            onConfirmStopMatch={onConfirmStopMatch}
            handleClose={closeModals}
          />
        )}

        {manualChangeModal && (
          <EManualChangeModal
            show={manualChangeModal}
            handleClose={closeModals}
          />
        )}

        {supportModal && (
          <ESupportModal show={supportModal} handleClose={closeModals} />
        )}

        {exitModal && (
          <EExitModal
            onExit={onExit}
            show={exitModal}
            handleClose={closeModals}
          />
        )}

        {timeoutSetModal && (
          <ESetTimeoutModal
            show={timeoutSetModal}
            handleClose={isNewEntrants ? openEntrantModal : closeModals}
            title={setTimeoutTitle}
            mins={setTimeoutMin}
          />
        )}

        {newEntrantModal && (
          <ESetEntrantModal
            show={newEntrantModal}
            onSave={setEntrants}
            players={Object.values(playerSelection)}
            teamA={teams[currentTeamA[0].clubID]}
            teamB={teams[currentTeamB[0].clubID]}
            captainSelection={captainSelection}
            handleClose={closeModals}
          />
        )}
        {!startMatch && (
          <EStartMatchModal
            show={!startMatch}
            team={selectedTeam}
            onAddLibero={() => setLiberoStartModal(true)}
            onStartMatch={() => setStartMatch(true)}
          />
        )}
        {undoModal && <EUndoModal show={undoModal} handleClose={closeModals} />}
        {liberoModal && (
          <ELiberoModal
            show={liberoModal}
            team={selectedTeam}
            time={selectedTime}
            onLibero={onLibero}
            players={selectedTeamPlayers}
            onBenchPlayers={selectedSubTeamPlayers}
            handleClose={closeModals}
            captainSelection={captainSelection}
            playerSelection={playerSelection}
            data={liberos}
            teamSelectedLetter={teamSelectedLetter}
          />
        )}
        {liberoStartModal && (
          <ELiberoStartModal
            show={liberoStartModal}
            teamA={currentTeamA}
            teamB={currentTeamB}
            teams={teams}
            onLibero={onLibero}
            handleClose={closeModals}
            captainSelection={captainSelection}
          />
        )}
        {injuryModal && (
          <EInjuryModal
            show={injuryModal}
            team={selectedTeam}
            time={selectedTime}
            onInjury={onInjury}
            players={selectedTeamPlayers}
            onBenchPlayers={
              teamSelectedLetter === "A" ? teamAonBench : teamBonBench
            }
            handleClose={closeModals}
            captainSelection={captainSelection}
            playerSelection={playerSelection}
            data={injuries}
          />
        )}
      </div>
    </main>
  );
}
