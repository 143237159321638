export const SAVE_USER = 'SAVE_USER';
export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const PRINT_USERS = 'PRINT_USERS';
export const PRINT_PREVIEW_USERS = 'PRINT_PREVIEW_USERS';

export const GET_REFEREES_SUCCESS = 'GET_REFEREES_SUCCESS';
export const GET_COACH_SUCCESS = 'GET_COACH_SUCCESS';
export const GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS';
export const GET_FEDERATION_SUCCESS = 'GET_FEDERATION_SUCCESS';
export const GET_LEAGUE_SUCCESS = 'GET_LEAGUE_SUCCESS';

export const GET_CLUB = 'GET_CLUB';
export const GET_CLUB_BY_LIGUE = 'GET_CLUB_BY_LIGUE';
export const GET_CLUB_AUXILIAIRE = 'GET_CLUB_AUXILIAIRE';
export const GET_CLUB_SUCCESS = 'GET_CLUB_SUCCESS';

export const SAVE_ROLE = 'SAVE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';

export const GET_GRADE = 'GET_GRADE';
export const GET_GRADE_SUCCESS = 'GET_GRADE_SUCCESS';

export const SAVE_CLUB = 'SAVE_CLUB';
export const UPDATE_CLUB = 'UPDATE_CLUB';
export const DELETE_CLUB = 'DELETE_CLUB';

export const GET_DASHBOARD_ELEMENTS = 'GET_DASHBOARD_ELEMENTS';
export const GET_DASHBOARD_ELEMENTS_SUCCESS = 'GET_DASHBOARD_ELEMENTS_SUCCESS';

export const GET_INSCRITS = 'GET_INSCRITS';
export const GET_INSCRITS_SUCCESS = 'GET_INSCRITS_SUCCESS';

export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';
export const CLOSE_NOTIFY = 'CLOSE_NOTIFY';

export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';

export const OPEN_SESSION_EXPIRED_MODAL = 'OPEN_SESSION_EXPIRED_MODAL';
export const CLOSE_SESSION_EXPIRED_MODAL = 'CLOSE_SESSION_EXPIRED_MODAL';

export const SHOW_PASSWORD_MODAL = 'SHOW_PASSWORD_MODAL';
export const HIDE_PASSWORD_MODAL = 'HIDE_PASSWORD_MODAL';

export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';

export const GET_DEPARTEMENTS = 'GET_DEPARTEMENTS';
export const GET_DEPARTEMENTS_SUCCESS = 'GET_DEPARTEMENTS_SUCCESS';

export const RESET_ARRONDISSEMENTS = 'RESET_ARRONDISSEMENTS';
export const GET_ARRONDISSEMENTS = 'GET_ARRONDISSEMENTS';
export const GET_ARRONDISSEMENTS_SUCCESS = 'GET_ARRONDISSEMENTS_SUCCESS';

export const GET_LIGUE_USERS = 'GET_LIGUE_USERS';
export const GET_LIGUE_USERS_SUCCESS = 'GET_LIGUE_USERS_SUCCESS';

export const GET_CLUB_ADMIN = 'GET_CLUB_ADMIN';
export const GET_CLUB_ADMIN_SUCCESS = 'GET_CLUB_ADMIN_SUCCESS';

export const GET_SUB_ROLES = 'GET_SUB_ROLES';
export const GET_SUB_ROLES_SUCCESS = 'GET_SUB_ROLES_SUCCESS';

export const SAVE_TOURNOIS = 'SAVE_TOURNOIS';
export const GET_TOURNOIS = 'GET_TOURNOIS';
export const GET_TOURNOIS_AUXILIAIRE = 'GET_TOURNOIS_AUXILIAIRE';
export const GET_TOURNOIS_SUCCESS = 'GET_TOURNOIS_SUCCESS';
export const DELETE_TOURNOIS = 'DELETE_TOURNOIS';
export const UPDATE_TOURNOIS = 'UPDATE_TOURNOIS';

export const SAVE_MATCH = 'SAVE_MATCH';
export const GET_MATCHS = 'GET_MATCHS';
export const GET_MATCH_FORM_DATA = 'GET_MATCH_FORM_DATA';
export const GET_MATCHS_BY_TOURNOIS = 'GET_MATCHS_BY_TOURNOIS';
export const GET_MATCH_SUCCESS = 'GET_MATCH_SUCCESS';
export const DELETE_MATCH = 'DELETE_MATCH';
export const UPDATE_MATCH = 'UPDATE_MATCH';

export const SAVE_MATRICULE_CODE = 'SAVE_MATRICULE_CODE';
export const GET_MATRICULE_CODES = 'GET_MATRICULE_CODES';
export const GET_MATRICULE_CODE_SUCCESS = 'GET_MATRICULE_CODE_SUCCESS';
export const DELETE_MATRICULE_CODE = 'DELETE_MATRICULE_CODE';
export const UPDATE_MATRICULE_CODE = 'UPDATE_MATRICULE_CODE';

export const SAVE_PERMISSION = 'SAVE_PERMISSION';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const SAVE_ROLE_PERMISSION = 'SAVE_ROLE_PERMISSION';
export const GET_ROLE_PERMISSIONS = 'GET_ROLE_PERMISSIONS';
export const GET_ROLE_PERMISSION_SUCCESS = 'GET_ROLE_PERMISSION_SUCCESS';
export const DELETE_ROLE_PERMISSION = 'DELETE_ROLE_PERMISSION';
export const UPDATE_ROLE_PERMISSION = 'UPDATE_ROLE_PERMISSION';

export const SHOW_SIGNATURE_MODAL = 'SHOW_SIGNATURE_MODAL';
export const HIDE_SIGNATURE_MODAL = 'HIDE_SIGNATURE_MODAL';

export const SET_SIGNATURE = 'SET_SIGNATURE';

export const SAVE_PLAYER_TRANSFER = 'SAVE_PLAYER_TRANSFER';
export const GET_PLAYER_TRANSFERS = 'GET_PLAYER_TRANSFERS';
export const GET_PLAYER_TRANSFER_SUCCESS = 'GET_PLAYER_TRANSFER_SUCCESS';
export const DELETE_PLAYER_TRANSFER = 'DELETE_PLAYER_TRANSFER';
export const UPDATE_PLAYER_TRANSFER = 'UPDATE_PLAYER_TRANSFER';