import React from 'react';
import { NavLink , useLocation} from 'react-router-dom';
import { SidebarItem } from '.';
import { hasRole } from '../utilities/auth';

const AdminSidebar = ({ user }) => {
    const location = useLocation();

    return (
        <nav className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white border-0" id="sidenav-main">
            <div className="scrollbar-inner">

                <div className="sidenav-header text-left d-flex align-items-center text-white" style={{ background: '#232323' }}>
                    <NavLink to='/admin/dashboard' style={{ color: '#fff' }}>
                        <span className="mr-2 ml-2">
                            <img src='/assets/images/fecavolley.png' height='30' alt="logo" />
                        </span>
                        <span className="font-weight-bold" style={{ fontSize: '20px', letterSpacing: 1 }}>FECAVOLLEY</span></NavLink>
                </div>
                {/* <div className="collapse navbar-collapse" id="sidenav-collapse-main"></div> */}

                <div className="navbar-inner">

                    <ul className="navbar-nav mt-3">
                        <SidebarItem title='DASHBOARD' route='dashboard' icon='desktop' active={location.pathname.includes('dashboard')} />
                        {/* <SidebarItem title='MON PROFIL' route='profile' icon='user' active={location.pathname.includes('profile')} /> */}
                        {hasRole(user, ['ADMIN']) && <SidebarItem title='CONFIGURATIONS' route='securite' icon='lock' active={location.pathname.includes('securite')} />}
                        {/* {hasRole(user, ['ADMIN']) && <SidebarItem title='E-SCORE SHEET' route='e-score-sheet' icon='poll' iconColor='text-green' />} */}
                        <SidebarItem title='MATCHS' route='match' icon='volleyball-ball' active={location.pathname.includes('match')} />
                    </ul>

                    <hr className="my-3" style={{ backgroundColor: '#fff' }} />

                    {/* <h6 className="navbar-heading p-0 text-muted">
                        <span className="docs-normal" style={{ letterSpacing: 1 }}>Membres</span>
                    </h6> */}

                    <ul className="navbar-nav mb-md-3">
                        <SidebarItem title='PLAYERS' route='player' icon='running' active={location.pathname.includes('player')} />
                        <SidebarItem title='REFEREES' route='referee' active={location.pathname.includes('referee')} />
                        <SidebarItem title='COACHS' route='coach' active={location.pathname.includes('coach')} />
                        <SidebarItem title='CLUBS' route='club' active={location.pathname.includes('club')} />
                        <SidebarItem title='ENROLLED' route='inscrit' icon='user-plus' active={location.pathname.includes('inscrit')} />
                        <SidebarItem title='TOURNAMENTS' route='tournois' icon='volleyball-ball' active={location.pathname.includes('tournois')} />
                        {hasRole(user, ['ADMIN']) && <SidebarItem title='FEDERATION' route='federation' icon='users' active={location.pathname.includes('federation')} />}
                        {hasRole(user, ['ADMIN', 'LEAGU']) && <SidebarItem title='LEAGUE' route='ligue' icon='users' active={location.pathname.includes('ligue')} />}
                      
                    </ul>

                </div>
            </div>
        </nav>

    )
}


export default AdminSidebar