import React from 'react';
import { NavLink } from 'react-router-dom';

const SidebarItem = ({ route, icon = 'volleyball-ball', title, active }) => {

    return (

        <li className="nav-item">
            <NavLink to={`/admin/${route}`}>
                <p className="nav-link" style={{backgroundColor: active ? '#25634c20' : 'transparent', borderTopRightRadius: 50, borderBottomRightRadius: 50, marginRight: 10}}>
                    <i className={`fa fa-${icon} text-green`}></i>
                    <span className="nav-link-text" style={{ letterSpacing: 1, fontSize: 12 }}>{title} </span>
                </p>
            </NavLink>

        </li>

    )
}

export default SidebarItem