import React, { useState, useEffect } from "react";

const MatchStaffRow = ({
  position,
  onStaffSelect,
  clubID,
  staffs,
  staffSelection,
  onDeselect,
  staffID,
}) => {

  const [selectedPlayer, setSelectedPlayer] = useState("");

  const onValueChange = (value) => {
    const staff = staffs.filter(staff=>staff.id===value).shift()

    onStaffSelect({
      ...staff,
      clubID
    });
    setSelectedPlayer(staff.id)
  };

  const onDeselectPlayer=()=>{
    onDeselect(selectedPlayer);
    setSelectedPlayer("");
  }

  useEffect(() => {
    if (staffID) {
      setSelectedPlayer(staffID)
    } else {
      setSelectedPlayer("")
    }
 
  }, [staffID])
  

  return (
    <tr>
      {position ? <td>
        {position}
      </td> : null}
      <td>
        <select
          onChange={(event) => onValueChange(event.target.value)}
          className="form-control form-control-sm"
          value={selectedPlayer}
        >
          <option></option>
          {staffs?.map((staff,index) => (
            <option key={index} value={staff.id} disabled={Object.keys(staffSelection || "{}").includes(staff.id)}>
              {staff.name} {staff.surname}
            </option>
          ))}
        </select>
        {/* <p
          className={`badge-${
            value > 0 ? "primary" : "light"
          } text-center rounded`}
        >
          {value > 0 ? "Sur le terrain" : "Remplaçant"}
        </p> */}
      </td>
      <td>
        <button className="btn btn-primary btn-sm" style={{padding:5,margin:0,border:"none"}} disabled={!selectedPlayer} onClick={onDeselectPlayer}><i className="fa fa-redo"></i></button>
      </td>
    </tr>
  );
};

export default MatchStaffRow;
