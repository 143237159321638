import axios from 'axios';
import { SERVER } from '../../../utilities';

export const signin = (user) => {

    return axios.post(`${SERVER}auth/signin`, user).then((response) => {
        return response
    }).catch((response) => {
        return response
    });
}

export const signup = (user) => {

    return axios.post(`${SERVER}auth/signup`, user).then((response) => {
        return response
    }).catch((response) => {
        return response
    });
}

export const checkPassword = (user) => {

    return axios.post(`${SERVER}auth/check-password`, user).then((response) => {
        return response
    }).catch((response) => {
        return response
    });
}