import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardCard } from '../../../components';
import { hasPermission } from '../../../utilities/auth';
import { closeNotify, getDashboardElements } from '../actions';

const Dashboard = (props) => {

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardElements());
    dispatch(closeNotify())

  }, [dispatch]);

  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-desktop fa-lg mr-3"></i></span>DASHBOARD</h6>
            </div>
          </div>

          <div className="row" >
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_REFEREE"]) && <DashboardCard title='Referees' data={adminState.refereesLength} icon='user' color='purple' route='referee' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_COACH"]) && <DashboardCard title='Coaches' data={adminState.coachsLength} icon='user' color='info' route='coach' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_PLAYER"]) && <DashboardCard title='Players' data={adminState.playersLength} icon='user' color='orange' route='player' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_FEDERATION_MEMBER"]) && <DashboardCard title='Federation' data={adminState.federationsLength} icon='user' color='blue' route='federation' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_LEAGUE_MEMBER"]) && <DashboardCard title='Leagues' data={adminState.leaguesLength} icon='user' color='blue' route='ligue' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_TEAM"]) && <DashboardCard title='Clubs' data={adminState.clubsLength} color='green' route='club' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_REGISTRATION"]) && <DashboardCard title='Enrolled' data={adminState.inscritsLength} color='green' route='inscrit' icon='user-plus' /> : null}
            {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["VIEW_NOTIFICATIONS"]) && <DashboardCard title='Notifications' data={adminState.inscritsLength} color='green' icon='bell' route='notification' /> : null}

          </div>
        </div>
      </div>
    </div>

  );
}


export default Dashboard