import { takeLatest } from 'redux-saga/effects';
import * as workers from './worker';
import * as constants from '../constants';

export function* watchSignin() {
    yield takeLatest(constants.SIGNIN, workers.signinWorker);
}

export function* watchSignup() {
    yield takeLatest(constants.SIGNUP, workers.signupWorker);
}
