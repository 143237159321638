import React from 'react'

const CardRow = ({ title, value, title2, value2, showHR = true }) => {

    return (

        <div>
            <div class="row">
                <div class='col-md-6' style={{ marginBottom: 10 }}>
                    <div class="col">
                        <h6 class="mb-10" style={{fontSize: 12}}>{title}</h6>
                    </div>
                    <div class="col">
                        <b style={{fontSize: 16}}>{value}</b>
                    </div>
                </div>
                <div class='col-md-6'>
                    <div class="col">
                        <h6 class="mb-10" style={{fontSize: 12}}>{title2}</h6>
                    </div>
                    <div class="col">
                        <b style={{fontSize: 16}}>{value2}</b>
                    </div>
                </div>
            </div>
            {showHR ? <hr /> : null}
        </div>

    );
}


export default CardRow;