import React from 'react';
import { FormButton, InputTextSmall } from '.';

const SignupStepThree = ({ values, handleChange, errors, left, touched, handleBlur, ...rest }) => {

    return (

        <>
            <h4 class="font-weight-bold mb-4">Professional information</h4>

            <h6 class="h6"><b>{rest.titleName1}</b></h6>
            <div class="row">
                <InputTextSmall title="Date obtained" type="date" name="adDateObtention" id="adDateObtention"
                    value={values.adDateObtention} onBlur={handleBlur}
                    onChange={handleChange} error={errors.adDateObtention} />

                <InputTextSmall title="Location" name="adLieu" id="adLieu"
                    value={values.adLieu} onBlur={handleBlur}
                    onChange={handleChange} error={errors.adLieu} />

                <InputTextSmall title="Instructor" name="adFormateur" id="adFormateur"
                    value={values.adFormateur} onBlur={handleBlur}
                    onChange={handleChange} error={errors.adFormateur} />

                <InputTextSmall title="Observation" name="adObservation" id="adObservation"
                    value={values.adObservation} onBlur={handleBlur}
                    onChange={handleChange} error={errors.adObservation} />

            </div>
            <h6 class="h6"><b>{rest.titleName2}</b></h6>
            <div class="row">
                <InputTextSmall title="Date obtained" type="date" name="arDateObtention" id="arDateObtention"
                    value={values.arDateObtention} onBlur={handleBlur}
                    onChange={handleChange} error={errors.arDateObtention} />

                <InputTextSmall title="Location" name="arLieu" id="arLieu"
                    value={values.arLieu} onBlur={handleBlur}
                    onChange={handleChange} error={errors.arLieu} />

                <InputTextSmall title="Instructor" name="arFormateur" id="arFormateur"
                    value={values.arFormateur} onBlur={handleBlur}
                    onChange={handleChange} error={errors.arFormateur} />

                <InputTextSmall title="Observation" name="arObservation" id="arObservation"
                    value={values.arObservation} onBlur={handleBlur}
                    onChange={handleChange} error={errors.arObservation} />
            </div>
            <h6 class="h6"><b>{rest.titleName3}</b></h6>
            <div class="row">
                <InputTextSmall title="Date obtained" type="date" name="anDateObtention" id="anDateObtention"
                    value={values.anDateObtention} onBlur={handleBlur}
                    onChange={handleChange} error={errors.anDateObtention} />

                <InputTextSmall title="Location" name="anLieu" id="anLieu"
                    value={values.anLieu} onBlur={handleBlur}
                    onChange={handleChange} error={errors.anLieu} />

                <InputTextSmall title="Instructor" name="anFormateur" id="anFormateur"
                    value={values.anFormateur} onBlur={handleBlur}
                    onChange={handleChange} error={errors.anFormateur} />

                <InputTextSmall title="Observation" name="anObservation" id="anObservation"
                    value={values.anObservation} onBlur={handleBlur}
                    onChange={handleChange} error={errors.anObservation} />
            </div>
            <h6 class="h6"><b>{rest.titleName4}</b></h6>
            <div class="row">
                <InputTextSmall title="Date obtained" type="date" name="aiDateObtention" id="aiDateObtention"
                    value={values.aiDateObtention} onBlur={handleBlur}
                    onChange={handleChange} error={errors.aiDateObtention} />

                <InputTextSmall title="Location" name="aiLieu" id="aiLieu"
                    value={values.aiLieu} onBlur={handleBlur}
                    onChange={handleChange} error={errors.aiLieu} />

                <InputTextSmall title="Instructor" name="aiFormateur" id="aiFormateur"
                    value={values.aiFormateur} onBlur={handleBlur}
                    onChange={handleChange} error={errors.aiFormateur} />

                <InputTextSmall title="Observation" name="aiObservation" id="aiObservation"
                    value={values.aiObservation} onBlur={handleBlur}
                    onChange={handleChange} error={errors.aiObservation} />
            </div>

            <div className="d-flex justify-content-between mt-5">
                <FormButton title="Precedent" iconLeft="chevron-left" onClick={left} />
                <button style={{ backgroundColor: '#00387B', color: '#fff' }} type="submit" class="btn rounded ">
                    Je m'inscris </button>
            </div>
        </>

    );

}


export default SignupStepThree