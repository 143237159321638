import React from "react";
import { stringReduceAddDots } from "../../../utilities";

const EOnBenchItem = ({ side, title, value, captain }) => {
  return (
    <li className={`list-group-item d-flex justify-content-between align-items-center ${side==="right"?"flex-row-reverse":""}`}>
      <span>{stringReduceAddDots(title, 25)}</span>
      <span className="badge badge-primary badge-pill">{value} {captain ? <b style={{backgroundColor:String(captain).charAt(0) === "C" ? "#25634c" : "#fa9931", borderRadius: 100, fontSize: 8, padding: 3}}>{String(captain).charAt(0)}</b> : null}</span>
    </li>
  );
};

export default EOnBenchItem;
