import React from "react";
import { Modal } from "react-bootstrap";

const ESupportModal = ({ handleClose, show }) => {
  return (
    <Modal show={show} centered size="sm" onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>SUPPORT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center mb-3">
          <i className="far fa-envelope mr-2"></i>
          <p className="mb-0">support.fecavolley@cybexai.com</p>
        </div>
        <div className="d-flex align-items-center mb-3">
          <i className="fa fa-mobile-alt mr-2"></i>
          <p className="mb-0">+237 696 484 976</p>
        </div>
        <div className="d-flex align-items-center mb-3">
          <i className="fa fa-mobile-alt mr-2"></i>
          <p className="mb-0">+237 698 983 447</p>
        </div>
        <div>
          <button className="btn btn-sm w-100" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ESupportModal;
