import React, { useState } from "react";

const MatchCaptainRow = ({
  position,
  onCaptainSelect,
  players,
  captainSelection,
  onDeselect,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState("");

  const onValueChange = (value) => {
    onCaptainSelect({
      id: value,
      role: position,
    });
    setSelectedPlayer(value);
  };

  const onDeselectPlayer = () => {
    onDeselect({ id: selectedPlayer });
    setSelectedPlayer("");
  };

  return (
    <tr>
      <td>{position}</td>
      <td>
        <select
          onChange={(event) => onValueChange(event.target.value)}
          className="form-control form-control-sm"
          value={selectedPlayer}
        >
          <option></option>
          {players?.map((value, index) => (
            <option
              key={index}
              value={value.player.id}
              disabled={Object.keys(captainSelection || "{}").includes(
                value.player.id
              )}
            >
              {value.player.name}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          style={{ padding: 5, margin: 0, border: "none" }}
          disabled={!selectedPlayer}
          onClick={onDeselectPlayer}
        >
          <i className="fa fa-redo"></i>
        </button>
      </td>
    </tr>
  );
};

export default MatchCaptainRow;
