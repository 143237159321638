import React from 'react';
import { Link } from 'react-router-dom';

const SecuriteButton = ({icon, title, link}) => {

    return (

        <div className="row">
        <div className="col-md-12">

          <div className="card card-stats" >

            <div className="card-body" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className={`fa fa-${icon} mb-2 mr-1`}></i>
                <h1>{title}</h1>
              </div>
              <div>
                <Link to={link} className="btn"><i className="fa fa-list mr-1"></i> List of {title.toLowerCase()}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
}


export default SecuriteButton;