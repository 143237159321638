import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ESetTimeoutModal = ({
  handleClose,
  show,
  title = "Timeout before next set",
  mins = 2,
}) => {
  const [minutes, setMinutes] = useState(mins);
  const [seconds, setSeconds] = useState(59);

  const closeTimer=()=>{
    if(window.confirm("Do you really want to close the timer?")){
      handleClose();
    }
  }

  useEffect(
    function () {
      setTimeout(function () {
        if (seconds === 0) {
          if (minutes === 0) {
            handleClose();
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    },
    [seconds, minutes, handleClose]
  );

  return (
    <Modal
      show={show}
      centered
      // backdrop={!isPaused && "static"}
      size="sm"
      onHide={minutes === 0 && handleClose}
    >
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="d-block justify-content-center text-center w-100">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={`/assets/images/time-${
                    minutes === 0 ? "bomb2" : "bomb"
                  }.png`}
                  alt="scored"
                  width={100}
                  height={100}
                />
                <h1
                  style={{ fontSize: 100, color: minutes === 0 && "#E56969" }}
                  className="mx-2"
                >
                  :
                </h1>
                <h1
                  style={{ fontSize: 100, color: minutes === 0 && "#E56969" }}
                >
                  {`${minutes}:${seconds}`}
                </h1>
              </div>
              <button className="btn btn-danger btn-sm" onClick={closeTimer}>Close Timer</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ESetTimeoutModal;
