import React from 'react';
import { DropdownLarge, FormButton, InputPicture } from '.';

const SignupStepTwo = ({ values, handleChange, errors, nextPage, left, touched, handleBlur, ...rest }) => {

    return (

        <>
            <div class="row">

                <div class="col-lg-6">
                    <InputPicture onDone={rest.setPicture.bind(this)} />
                </div>

                <DropdownLarge title="Region" options={rest.regions} dynamic={true} name="_region" id="_region"
                    value={values._region} onBlur={handleBlur}
                    onChange={handleChange} error={errors._region} />
            </div>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <div class="row">
                <DropdownLarge title="Departement" options={rest.departements} dynamic={true} name="_departement" id="_departement"
                    value={values._departement} onBlur={handleBlur}
                    onChange={handleChange} error={errors._departement} />
                <DropdownLarge title="District" options={rest.arrondissements} dynamic={true} name="_arrondissement" id="_arrondissement"
                    value={values._arrondissement} onBlur={handleBlur}
                    onChange={handleChange} error={errors._arrondissement} />
                {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}
            </div>

            <div className="d-flex justify-content-between mt-5">
                <FormButton title="Precedent" iconLeft="chevron-left" onClick={left} />
                <FormButton title="Suivant" iconRight="chevron-right" onClick={nextPage} />
            </div>

        </>

    );

}


export default SignupStepTwo