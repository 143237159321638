import React from "react";
import { Modal } from "react-bootstrap";

const EUndoModal = ({ handleClose, show, team, onConfirmUndo, time }) => {
  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>UNDO HISTORY</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <div className="row border border-10 rounded p-1 m-2">
            <div className="col-md-10">
              <b>Assign point</b>

              <div className="row mt-2">
                <div className="col-md-6">
                  <small>Team: <b>Ethio</b></small>
                </div>
                <div className="col-md-6">
                  <small>Time: <b>0:23:34</b></small>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <small>Set: <b>2</b></small>
                </div>
                <div className="col-md-6">
                  <small>Score: <b>22:4</b></small>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <button
                className="text-danger"
                style={{ backgroundColor: "transparent" }}
              >
                undo
              </button>
            </div>
          </div>

          <div className="d-flex">
            <button
              className="btn btn-sm w-50"
              onClick={onConfirmUndo}
              style={{ backgroundColor: "#21517c", color: "white" }}
            >
              Confirm Undo
            </button>
            <button className="btn btn-sm w-50" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EUndoModal;
