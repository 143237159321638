import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import {
  BackButton,
  CardRow,
  DatatableCard,
  EmptyDetailCard,
} from "../../../components";
import { getUser, getPlayerTransfer } from "../apis";
import { stringReduceAddDots } from "../../../utilities";
import { useLocation } from "react-router-dom";

const PlayerDetail = (props) => {
  const location = useLocation();
  const [user, setUser] = useState([]);
  const [playerTransfer, setPlayerTransfer] = useState([]);

  const userId = location.state;

  useEffect(() => {
    getUser(userId).then((response) => {
      setUser(response.data);

      getPlayerTransfer(userId).then((response) => {
        setPlayerTransfer(response.data);
      });
    });
  }, []);

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>
    },
    {
      name: "From",
      selector: "from.name",
      sortable: true,
    },
    {
      name: "To",
      selector: "to.name",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <button className="btn btn-sm btn-danger">
          <i className="fa fa-trash"></i>
        </button>
      ),
    },
  ];

  const tableData = {
    columns,
    data: playerTransfer,
  };

  const categoryFinder = (date, gender) => {
    const today = new Date();
    const age = today.getFullYear() - date;

    if (age >= 21) {
      return "SÉNIORS";
    } else if (age < 21 && age > 18) {
      return "JUNIORS";
    } else if (age === 18) {
      return "CADETS";
    } else if (age < 18 && age >= 15) {
      return "MINIMES";
    } else if (age < 15 && age >= 13) {
      return gender === "HOMME" ? "BENJAMINS" : "BENJAMINES";
    } else {
      return "ÉCOLES";
    }

    // if (gender === 'HOMME') {
    //   if (date <= 2000)
    //     return 'SÉNIORS'
    //   else if (date > 2000 && date < 2003)
    //     return 'JUNIORS'
    //   else if (date === 2003)
    //     return 'CADETS'
    //   else if (date >= 2004 && date < 2006)
    //     return 'MINIMES'
    //   else if (date >= 2006 && date < 2008)
    //     return 'BENJAMINS'
    //   else if (date >= 2008)
    //     return 'ÉCOLES'
    // } else if (gender === 'FEMME') {
    //   if (date <= 2001)
    //     return 'SÉNIORS'
    //   else if (date > 2001 && date < 2004)
    //     return 'JUNIORS'
    //   else if (date === 2004)
    //     return 'CADETTES'
    //   else if (date >= 2005 && date < 2007)
    //     return 'MINIMES'
    //   else if (date >= 2007 && date <= 2008)
    //     return 'BENJAMINES'
    //   else if (date >= 2009)
    //     return 'ÉCOLES'
    // }
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span>
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                PLAYER DETAIL
              </h6>
              <BackButton />
            </div>
          </div>

          {user.length !== 0 ? (
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card shadow mb-25 bg-white rounded my-3">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img
                        src={user.picture || "/assets/images/profile.png"}
                        alt="Admin"
                        className="rounded"
                        width="150"
                        height="150"
                      />
                      <div className="mt-3">
                        <h6>{`${user.name} ${user.surname}`}</h6>

                        {user.dossard ? (
                          <p className="font-size-sm mb-0">
                            Dossard: <b>{user.dossard}</b>
                          </p>
                        ) : null}
                        {user.matricule ? (
                          <p
                            className="font-size-sm mb-0"
                            style={{ fontSize: 12 }}
                          >
                            {user.matricule}|
                            <b>
                              {categoryFinder(
                                parseInt(moment(user.dob).format("YYYY")),
                                user.gender
                              )}
                            </b>
                          </p>
                        ) : null}
                        {user._subRole ? (
                          <p
                            className="font-size-sm mb-0"
                            style={{ fontSize: 12 }}
                          >
                            {user._subRole?.name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow mb-25 bg-white rounded my-3">
                  <div className="card-body">
                    <CardRow
                      title="League"
                      value={user.league}
                      title2="Region"
                      value2={user._region === null ? "" : user._region.name}
                    />
                    <CardRow
                      title="Departement"
                      value={
                        user._departement === null ? "" : user._departement.name
                      }
                      title2={stringReduceAddDots("District", 15)}
                      value2={
                        user._arrondissement === null
                          ? ""
                          : user._arrondissement.name
                      }
                      showHR={false}
                    />
                  </div>
                </div>

                {/* {user.signature ? <SignaturePreview signature={user.signature} height={'100%'} /> : null} */}
              </div>
              <div class="col-md-8">
                <div class="card mb-3 shadow bg-white rounded my-3">
                  <div class="card-body">
                    <CardRow
                      title="Name"
                      value={user.name}
                      title2="Surname"
                      value2={user.surname}
                    />
                    <CardRow
                      title="Date of birth"
                      value={user.dob}
                      title2="Place of birth"
                      value2={user.pob}
                    />
                    <CardRow
                      title="Marital status"
                      value={user.familyStatus}
                      title2="Gender"
                      value2={user.gender}
                    />
                    <CardRow
                      title="Email"
                      value={user.email}
                      showHR={false}
                      title2="Phone number"
                      value2={user.phone}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="card mb-3 shadow bg-white rounded my-3">
                      <div class="card-body">
                        <CardRow
                          title="Profession"
                          value={user.profession}
                          title2="Role"
                          value2={user._role.name}
                        />
                        <CardRow
                          title="Matricule"
                          value={user.matricule}
                          title2="Education level"
                          value2={user.schoolLevel}
                        />
                        <CardRow
                          title="Creation date"
                          value={
                            <Moment format="DD/MM/YYYY">
                              {user.creationDate}
                            </Moment>
                          }
                          title2="Club"
                          value2={user.clubs[0].name}
                          showHR={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {playerTransfer.length > 0 ? (
                  <div className="row">
                    <div className="col">
                      <div className="card mb-3 shadow bg-white rounded my-3">
                        <div className="card-body">
                          <h1 className="text-center">Transferts</h1>
                          <DatatableCard tableData={tableData} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <EmptyDetailCard />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerDetail;
