import React from 'react';
import { Link } from 'react-router-dom';

const HomeHeader = () => {

    return (
        <nav class="navbar navbar-expand-lg mainNav fixed-top" id="mainNav" style={{ boxShadow: 'none' }}>
            <div class="container">
                <Link to="/signin" className="navbar-brand"><span class="text-white"><img src='/assets/images/fecavolley.png' height='40' alt="logo" /></span><span class="text-white ml-2">FECAVOLLEY</span></Link>
                <button class="navbar-toggler btn-rounded navbar-toggler-right collapsed blue darken-3" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">

                    <i class="fas fa-bars"></i>
                </button>
                <div class="navbar-collapse collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a href='http://www.fecavolley.net/' target='_blank' rel="noopener noreferrer" className="nav-link js-scroll-trigger"> <i class="fa fa-globe mr-1"></i>fecavolley.net</a>
                        </li>
                        {/* <li class="nav-item">
                            <Link to="/admin/dashboard" className="nav-link js-scroll-trigger">Se connecter</Link>
                        </li> */}

                        {/* <?php if ($this->session->userdata('logged_in')) : ?>
                <li class="nav-item">
                  <a class="btn btn-dark btn-sm rounded" style="margin-top: 12px; box-shadow: none;" roll="btn" href="<?php echo base_url(); ?>admin/dashboard">Dashboard
                  </a>
                </li>
              <?php endif; ?> */}
                    </ul>
                </div>
            </div>
        </nav>

    )
}


export default HomeHeader