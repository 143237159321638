import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeNotify } from "../screens/admin/actions";

const Notify = ({ showTextOnly = false }) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  return !showTextOnly ? (
    <div
      className={`bg-${adminState.notifyType} py-3 px-3`}
      style={{
        textAlign: "center",
        display: adminState.notifyDisplay,
        marginBottom: 10,
      }}
    >
      <span className="text-white" style={{ float: "left" }}>
        <i className={`fa fa-${adminState.notifyIcon} mr-3`}></i>
      </span>
      <span className="text-white">{adminState.notifyMessage}</span>
      <span
        className="text-white"
        style={{ float: "right" }}
        onClick={() => dispatch(closeNotify())}
      >
        <i className="fa fa-times ml-3"></i>
      </span>
    </div>
  ) : (
    !adminState.notifyDisplay ? <div className="d-flex w-100 justify-content-center mb-3">
      <small className="text-danger">
        {adminState.notifyMessage}
      </small>
    </div>:null
  );
};

export default Notify;
