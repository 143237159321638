import React from 'react';

const EmptyTable = ({rootStyle,titleStyle,imageStyle}) => {

    return (

        <div style={{ textAlign: 'center', ...rootStyle }}>
            <img src='/assets/images/paper.png' width='100' height='100' alt="Empty" style={{...imageStyle}} />
            <h4 style={{ marginTop: 15, ...titleStyle }}>This list is empty</h4>
        </div>

    );
}


export default EmptyTable;