import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ELiberoStartModal = ({
  handleClose,
  show,
  teamA,
  teamB,
  teams,
  onLibero,
  captainSelection,
}) => {
  const [playerID, setPlayerID] = useState("");
  const [liberoID, setLiberoID] = useState("");

  const onSave = () => {
    const toSave = {
      // time,
      // team,
      player: playerID,
      playerSelection: {
        [playerID]: { playerIn: playerID, playerOut: liberoID },
      },
    };
    onLibero(toSave);
  };

  return (
    <Modal show={show} centered size={"lg"} onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>ADD LIBERO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
        <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Team:</p>
                <h1 style={{ fontSize: 16 }}>{teams[teamA[0].clubID]?.name}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0 mt-3">Libero:</p>
              <select
                onChange={({ target }) => setLiberoID(target.value)}
                className="form-control form-control-sm"
                value={liberoID}
              >
                <option></option>
                {teamA
                  ?.filter(
                    (val) =>
                      captainSelection[val.player.id]?.role?.charAt(0) === "L"
                  )
                  .map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <p className="mb-0 mt-3">Player:</p>
              <select
                onChange={({ target }) => setPlayerID(target.value)}
                className="form-control form-control-sm"
                value={playerID}
              >
                <option></option>
                {teamA
                  .filter((val) => [5,6,1].includes(val.position))
                  ?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Team:</p>
                <h1 style={{ fontSize: 16 }}>{teams[teamB[0].clubID]?.name}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0 mt-3">Libero:</p>
              <select
                onChange={({ target }) => setLiberoID(target.value)}
                className="form-control form-control-sm"
                value={liberoID}
              >
                <option></option>
                {teamB
                  ?.filter(
                    (val) =>
                      captainSelection[val.player.id]?.role?.charAt(0) === "L"
                  )
                  .map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <p className="mb-0 mt-3">Player:</p>
              <select
                onChange={({ target }) => setPlayerID(target.value)}
                className="form-control form-control-sm"
                value={playerID}
              >
                <option></option>
                {teamB
                  .filter((val) => [1, 6, 5].includes(val.position))
                  ?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            style={{ backgroundColor: "#02c0ce", color: "white" }}
          >
            Add Libero
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ELiberoStartModal;
