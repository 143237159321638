import { call, put } from 'redux-saga/effects';
import * as API from '../apis';
import * as actions from '../actions';
import { checkPassword } from '../../auth/apis';
import { ADMIN_LOCALS } from '../../../utilities/locals';
import { authResult } from '../../auth/actions';

export function* saveUserWorker(payload) {

  try {

    const checkDuplication = yield call(API.checkDuplicateUser, payload.user);

    if (checkDuplication.data.length > 0) {

      yield put(actions.notifyError(`User already exist !`))
      yield put(authResult())

    } else {

      const response = yield call(API.saveUser, payload.user);

      if (String(response).substring(String(response).length - 3) === '401') {
        yield put(actions.openSessionExpiredModal())
        yield put(authResult())
      } else {
        if (response.status === 201) {
          payload.callback()
          yield put(actions.notifySuccess('Save Successful !'))
          yield put(authResult())
        } else {
          yield put(actions.notifyError());
          yield put(authResult())
        }
      }


    }

  } catch (error) {
    console.log(error)
    yield put(authResult())
  }
}

export function* getUsersWorker(payload) {

  try {

    const ligue = localStorage.getItem('fvApp')

    const roleResponse = yield call(API.getRole, payload.code);

    let response;

    if (String(roleResponse).substring(String(roleResponse).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (ligue === ADMIN_LOCALS) {
        response = yield call(API.getUsers, roleResponse.data.id, payload.page, payload.perPage);

      } else {
        let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
        response = yield call(API.getUsersByLigue, roleResponse.data.id, loggedInResponse.data.league);
      }

      if (String(response).substring(String(response).length - 3) === '401') {
        yield put(actions.openSessionExpiredModal())
      } else {

        if (response.status !== 200) {
          yield put(actions.notifyError());
        } else {

          switch (payload.code) {
            case 'ARBIT': yield put(actions.getRefereesSuccess(response.data));
              return;
            case 'COACH': yield put(actions.getCoachsSuccess(response.data));
              return;
            case 'JOUER': yield put(actions.getPlayersSuccess(response.data));
              return;
            case 'FEDER': yield put(actions.getFederationSuccess(response.data));
              return;
            case 'LEAGU': yield put(actions.getLeagueSuccess(response.data));
              return;
            case 'CLUB': yield put(actions.getClubsAdminSuccess(response.data));
              return;

            default:
              break;
          }

        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* updateUserWorker(payload) {

  try {

    let response

    if (payload.source === 'inscrit') {
      response = yield call(API.enableUser, payload.user);
    } else {
      response = yield call(API.updateUser, payload.user);
    }

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(actions.notifySuccess('Update Successful !'))

        switch (payload.source) {
          case 'inscrit': yield put(actions.getInscrits())
            break;

          default:
            break;
        }
        yield put(authResult())
        payload.callback('/admin/' + payload.source)
      } else {
        yield put(actions.notifyError())
        yield put(authResult())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteUserWorker(payload) {

  try {

    let user = {
      pwd: payload.pwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteUser, payload.userId);

        if (String(response).substring(String(response).length - 3) === '401') {
          yield put(actions.openSessionExpiredModal())
        } else {

          if (response.status === 200) {
            yield put(actions.notifySuccess('Delete Successful !'))
            // yield put(actions.getUsers(payload.roleId));

            const ligue = localStorage.getItem('fvApp')

            const roleResponse = yield call(API.getRole, payload.code);

            let response;

            if (ligue === '15sdf#4%$#sd#@_)54') {
              response = yield call(API.getUsers, roleResponse.data.id);
            } else {
              response = yield call(API.getUsersByLigue, roleResponse.data.id, ligue);
            }

            if (String(response).substring(String(response).length - 3) === '401') {
              yield put(actions.openSessionExpiredModal())
            } else {

              if (response.status !== 200) {
                // store.dispatch()
                yield put(actions.notifyError());
              } else {
                if (payload.isInscrit) {
                  yield put(actions.getInscrits())
                } else {
                  switch (payload.code) {
                    case 'ARBIT': yield put(actions.getRefereesSuccess(response.data));
                      return;
                    case 'COACH': yield put(actions.getCoachsSuccess(response.data));
                      return;
                    case 'JOUER': yield put(actions.getPlayersSuccess(response.data));
                      return;
                    case 'FEDER': yield put(actions.getFederationSuccess(response.data));
                      return;
                    case 'LEAGU': yield put(actions.getLeagueSuccess(response.data));
                      return;

                    default:
                      break;
                  }
                }

              }
            }

            // const ligue = localStorage.getItem('fvApp')

            // let inscritResponse;

            // if (ligue === '15sdf#4%$#sd#@_)54') {
            //   inscritResponse = yield call(API.getInscrit);
            // } else {
            //   inscritResponse = yield call(API.getInscritByLigue, ligue);
            // }

            // inscritResponse.status == 200 ? yield put(actions.getInscritSuccess(inscritResponse.data)) : yield put(actions.notifyError());
          } else {
            yield put(actions.notifyError());
          }
        }

      } else {
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    console.log(error)
  }
}

export function* printUsersWorker(payload) {

  try {

    // const ligue = localStorage.getItem('fvApp')

    const roleResponse = yield call(API.getRole, payload.code);

    let response;

    if (String(roleResponse).substring(String(roleResponse).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      // if (ligue === ADMIN_LOCALS) {
      //   response = yield call(API.getUsers, roleResponse.data.id);
      // } else {
      //   let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
      //   response = yield call(API.getUsersByLigue, roleResponse.data.id, loggedInResponse.data.league);
      // }

      response = yield call(API.printUsers, roleResponse.data.id);

      if (String(response).substring(String(response).length - 3) === '401') {
        yield put(actions.openSessionExpiredModal())
      } else {

        if (response.status !== 200) {
          yield put(actions.notifyError());
        } else {
          console.log(typeof response.data)


          // switch (payload.code) {
          //   case 'ARBIT': yield put(actions.getRefereesSuccess(response.data));
          //     return;
          //   case 'COACH': yield put(actions.getCoachsSuccess(response.data));
          //     return;
          //   case 'JOUER': yield put(actions.getPlayersSuccess(response.data));
          //     return;
          //   case 'FEDER': yield put(actions.getFederationSuccess(response.data));
          //     return;
          //   case 'LEAGU': yield put(actions.getLeagueSuccess(response.data));
          //     return;
          //   case 'CLUB': yield put(actions.getClubsAdminSuccess(response.data));
          //     return;

          //   default:
          //     break;
          // }

        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* getRoleWorker(payload) {

  try {

    const response = yield call(API.getRole, payload.code);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {
      if (response.status === 200) {
        yield put(actions.getRoleSuccess(response.data.id))
        yield put(actions.getSubRoles(response.data.id))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getRolesWorker(payload) {

  try {

    const response = yield call(API.getRoles);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        if (payload.source === 'admin') {
          yield put(actions.getRolesSuccess(response.data))
        } else {
          let data = []

          response.data.forEach(e => {

            if (e.code === 'COACH' || e.code === 'ARBIT') {
              data.push(e)
            }
          });

          yield put(actions.getRolesSuccess(data))
        }

      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* saveRoleWorker(payload) {

  try {

    const response = yield call(API.saveRole, payload.role);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        yield put(actions.getRoles('admin'))
        // payload.callback('role')
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* deleteRoleWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteRole, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getRoles());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updateRoleWorker(payload) {

  try {

    const response = yield call(API.updateRole, payload.role);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        // payload.callback('role')
        yield put(actions.getRoles('admin'));
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getGradeWorker(payload) {

  try {

    const response = yield call(API.getGrade, payload.userId);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      response.status === 200 ? yield put(actions.getGradeSuccess(response.data)) : yield put(actions.notifyError());

    }

  } catch (error) {
    console.log(error)
  }
}

export function* saveClubWorker(payload) {

  try {

    const checkName = yield call(API.getClubByName, payload.club);
    const checkCode = yield call(API.getClubByCode, payload.club.code);

    if (checkName.data.length > 0 || checkCode.data.length) {

      yield put(actions.notifyError(`Ce Club existe déjà !`))
      yield put(authResult())
    } else {

      const response = yield call(API.saveClub, payload.club);

      if (String(response).substring(String(response).length - 3) === '401') {
        yield put(actions.openSessionExpiredModal())
        yield put(authResult())
      } else {

        if (response.status === 201) {
          yield put(authResult())
          payload.callback()
          yield put(actions.notifySuccess('Save Successful !'))
        } else {
          yield put(actions.notifyError());
          yield put(authResult())
        }
      }
    }


  } catch (error) {
    console.log(error)
    yield put(actions.notifyError());
    yield put(authResult())
  }
}

export function* updateClubWorker(payload) {

  try {

    const response = yield call(API.updateClub, payload.club);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        payload.callback()
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
        yield put(authResult())
      }
    }

  } catch (error) {
    console.log(error)
    yield put(actions.notifyError());
    yield put(authResult())
  }
}

export function* deleteClubWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteClub, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getClubs());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getClubWorker() {

  try {

    const ligue = localStorage.getItem('fvApp')

    let response;

    if (ligue === ADMIN_LOCALS) {
      response = yield call(API.getClub);
    } else {
      let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
      response = yield call(API.getClubByLigue, loggedInResponse.data.league);
    }

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal('/admin/club'))
    } else {
      response.status === 200 ? yield put(actions.getClubsSuccess(response.data)) : yield put(actions.notifyError());
    }


  } catch (error) {
    console.log(error)
  }
}

export function* getClubByLigueWorker(payload) {

  try {

    // const ligue = localStorage.getItem('fvApp')

    let response = yield call(API.getClubByLigue, payload.ligue);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal('/admin/club'))
    } else {
      response.status === 200 ? yield put(actions.getClubsSuccess(response.data)) : yield put(actions.notifyError());
    }


  } catch (error) {
    console.log(error)
  }
}

export function* getClubAuxiliaireWorker(payload) {

  try {

    const ligue = localStorage.getItem('fvApp')

    let response;

    if (ligue === ADMIN_LOCALS) {
      response = yield call(API.getClubAuxiliaire, payload.typeClub);
    } else {
      let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
      response = yield call(API.getClubByLigue, loggedInResponse.data.league);
    }

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {
      response.status === 200 ? yield put(actions.getClubsSuccess(response.data)) : yield put(actions.notifyError());
    }


  } catch (error) {
    console.log(error)
  }
}

export function* getDashboardElementsWorker() {

  try {

    const ligue = localStorage.getItem('fvApp')

    let elementsCount = {}

    if (ligue === ADMIN_LOCALS) {
      const clubResponse = yield call(API.getClubCount);
      const inscritResponse = yield call(API.getInscritCount);

      const playerRoleResponse = yield call(API.getRole, 'JOUER');
      const coachRoleResponse = yield call(API.getRole, 'COACH');
      const refereeRoleResponse = yield call(API.getRole, 'ARBIT');
      const federationRoleResponse = yield call(API.getRole, 'FEDER');
      const ligueRoleResponse = yield call(API.getRole, 'LEAGU');

      const playerResponse = yield call(API.getUsersCount, playerRoleResponse.data.id);
      const coachResponse = yield call(API.getUsersCount, coachRoleResponse.data.id);
      const refereeResponse = yield call(API.getUsersCount, refereeRoleResponse.data.id);
      const federationResponse = yield call(API.getUsersCount, federationRoleResponse.data.id);
      const ligueResponse = yield call(API.getUsersCount, ligueRoleResponse.data.id);

      clubResponse.status === 200 ? elementsCount = { ...elementsCount, clubsLength: clubResponse.data } : yield put(actions.notifyError());
      playerResponse.status === 200 ? elementsCount = { ...elementsCount, playersLength: playerResponse.data } : yield put(actions.notifyError());
      coachResponse.status === 200 ? elementsCount = { ...elementsCount, coachsLength: coachResponse.data } : yield put(actions.notifyError());
      refereeResponse.status === 200 ? elementsCount = { ...elementsCount, refereesLength: refereeResponse.data } : yield put(actions.notifyError());
      federationResponse.status === 200 ? elementsCount = { ...elementsCount, federationsLength: federationResponse.data } : yield put(actions.notifyError());
      ligueResponse.status === 200 ? elementsCount = { ...elementsCount, leaguesLength: ligueResponse.data } : yield put(actions.notifyError());
      inscritResponse.status === 200 ? elementsCount = { ...elementsCount, inscritsLength: inscritResponse.data } : yield put(actions.notifyError());



    } else {

      /* I have to match this method
      // with ADMIN_LOCALS
      // That is create a count method 
       for LIGUE ROLE */

      let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
      const clubResponse = yield call(API.getClubByLigue, loggedInResponse.data.league);

      const playerRoleResponse = yield call(API.getRole, 'JOUER');
      const coachRoleResponse = yield call(API.getRole, 'COACH');
      const refereeRoleResponse = yield call(API.getRole, 'ARBIT');

      const playerResponse = yield call(API.getUsersByLigue, playerRoleResponse.data.id, loggedInResponse.data.league);
      const coachResponse = yield call(API.getUsersByLigue, coachRoleResponse.data.id, loggedInResponse.data.league);
      const refereeResponse = yield call(API.getUsersByLigue, refereeRoleResponse.data.id, loggedInResponse.data.league);
      const inscritResponse = yield call(API.getInscritByLigue, loggedInResponse.data.league);

      clubResponse.status === 200 ? elementsCount = { ...elementsCount, clubsLength: clubResponse.data.length } : yield put(actions.notifyError());
      playerResponse.status === 200 ? elementsCount = { ...elementsCount, playersLength: playerResponse.data.length } : yield put(actions.notifyError());
      coachResponse.status === 200 ? elementsCount = { ...elementsCount, coachsLength: coachResponse.data.length } : yield put(actions.notifyError());
      refereeResponse.status === 200 ? elementsCount = { ...elementsCount, refereesLength: refereeResponse.data.length } : yield put(actions.notifyError());
      inscritResponse.status === 200 ? elementsCount = { ...elementsCount, inscritsLength: inscritResponse.data.length } : yield put(actions.notifyError());

    }

    yield put(actions.getDashboardElementsSuccess(elementsCount));

  } catch (error) {
    console.log(error)
  }
}

export function* getInscritWorker() {

  try {

    const ligue = localStorage.getItem('fvApp')

    let response;

    if (ligue === ADMIN_LOCALS) {
      response = yield call(API.getInscrit);
    } else {
      let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
      response = yield call(API.getInscritByLigue, loggedInResponse.data.league);
    }

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal('/admin/inscrit'))
    } else {

      response.status === 200 ? yield put(actions.getInscritSuccess(response.data)) : yield put(actions.notifyError());

    }

  } catch (error) {
    console.log(error)
  }
}

export function* getLoggedInUserWorker(payload) {

  try {

    const response = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'));

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {
        yield put(actions.getLoggedInUserSuccess(response.data))

      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getRegionsWorker() {

  try {

    const response = yield call(API.getRegions);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {
        yield put(actions.getRegionSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getDepartementsWorker(payload) {

  try {

    const response = yield call(API.getDepartements, payload.regionId);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {
        yield put(actions.getDepartementSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getArrondissementWorker(payload) {

  try {

    const response = yield call(API.getArrondissements, payload.departementId);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {
        yield put(actions.getArrondissementSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getLigueUsersWorker(payload) {

  try {

    const response = yield call(API.getLigueUsers, payload.ligueType);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {
        yield put(actions.getLigueUserSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getSubRolesWorker(payload) {

  try {

    const response = yield call(API.getSubRoles, payload.role);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getSubRolesSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* saveMatchWorker(payload) {

  try {

    const response = yield call(API.saveMatch, payload.match);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        payload.callback()
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getMatchsWorker() {

  try {

    const response = yield call(API.getMatchs);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {
        yield put(actions.getMatchSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getMatchFormDataWorker() {

  try {

    const refereeRoleResponse = yield call(API.getRole, "ARBIT");
    const playerRoleResponse = yield call(API.getRole, "JOUER");
    const clubRoleResponse = yield call(API.getRole, "CLUB");

    if (String(refereeRoleResponse).substring(String(refereeRoleResponse).length - 3) === '401' ||
    String(playerRoleResponse).substring(String(playerRoleResponse).length - 3) === '401' ||
    String(clubRoleResponse).substring(String(clubRoleResponse).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      let loggedInResponse = yield call(API.getLoggedInUser, localStorage.getItem('fvAccessToken'))
     const refereeResponse = yield call(API.getUsers, refereeRoleResponse.data.id, loggedInResponse.data.league);
     const playerResponse = yield call(API.getUsers, playerRoleResponse.data.id, loggedInResponse.data.league);
     const clubResponse = yield call(API.getUsers, clubRoleResponse.data.id, loggedInResponse.data.league);

      yield put(actions.getRefereesSuccess(refereeResponse.data));
      yield put(actions.getPlayersSuccess(playerResponse.data));
      yield put(actions.getClubsAdminSuccess(clubResponse.data))
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getMatchsByTournoisWorker(payload) {

  try {

    const response = yield call(API.getMatchsByTournois, payload.tournoisId);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getMatchSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteMatchWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteMatch, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getMatchs());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updateMatchWorker(payload) {

  try {

    const response = yield call(API.updateMatch, payload.match);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        payload.callback()
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* saveTournoisWorker(payload) {

  try {

    const response = yield call(API.saveTournois, payload.tournois);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        payload.callback()
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getTournoisWorker() {

  try {
    const response = yield call(API.getTournois);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getTournoisSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getTournoisAuxiliaireWorker(payload) {

  try {
    const response = yield call(API.getTournoisAuxiliaire, payload.typeTournois);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getTournoisSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteTournoisWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteTournois, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getTournois());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updateTournoisWorker(payload) {

  try {

    const response = yield call(API.updateTournois, payload.tournois);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        payload.callback('tournois')
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* saveMatriculeCodeWorker(payload) {

  try {

    const response = yield call(API.saveMatriculeCode, payload.matriculeCode);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        yield put(actions.getMatriculeCodes())
        // payload.callback('matriculeCode')
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getMatriculeCodeWorker() {

  try {
    const response = yield call(API.getMatriculeCode);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getMatriculeCodesSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteMatriculeCodeWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteMatriculeCode, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getMatriculeCodes());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updateMatriculeCodeWorker(payload) {

  try {

    const response = yield call(API.updateMatriculeCode, payload.matriculeCode);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        // payload.callback('matriculeCode')
        yield put(actions.getMatriculeCodes());
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* savePermissionWorker(payload) {

  try {

    const response = yield call(API.savePermission, payload.permission);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        yield put(actions.getPermissions())
        // payload.callback('permission')
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getPermissionWorker() {

  try {
    const response = yield call(API.getPermission);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getPermissionsSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deletePermissionWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deletePermission, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getPermissions());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updatePermissionWorker(payload) {

  try {

    const response = yield call(API.updatePermission, payload.permission);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        // payload.callback('permission')
        yield put(actions.getPermissions());
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* saveRolePermissionWorker(payload) {

  try {

    let { roleCode } = payload.rolePermission;

    delete payload.rolePermission['roleCode'];

    const response = yield call(API.saveRolePermission, payload.rolePermission);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        yield put(actions.getRolePermissions(roleCode))
        // payload.callback('rolePermission')
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getRolePermissionWorker(payload) {

  try {
    const response = yield call(API.getRolePermission, payload.roleCode);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getRolePermissionsSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteRolePermissionWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deleteRolePermission, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getRolePermissions());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updateRolePermissionWorker(payload) {

  try {

    const response = yield call(API.updateRolePermission, payload.rolePermission);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        // payload.callback('rolePermission')
        yield put(actions.getRolePermissions());
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* savePlayerTransferWorker(payload) {

  try {

    let { player } = payload.playerTransfer;

    //delete payload.playerTransfer['playerId'];

    const response = yield call(API.savePlayerTransfer, payload.playerTransfer);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 201) {
        yield put(authResult())
        yield put(actions.getPlayerTransfers(player))
        // payload.callback('playerTransfer')
        yield put(actions.notifySuccess('Save Successful !'))
      } else {
        yield put(authResult())
        yield put(actions.notifyError());
      }
    }


  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}

export function* getPlayerTransferWorker(payload) {

  try {
    const response = yield call(API.getPlayerTransfer, payload.playerId);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
    } else {

      if (response.status === 200) {

        yield put(actions.getPlayerTransfersSuccess(response.data))
      } else {
        yield put(actions.notifyError())
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deletePlayerTransferWorker(payload) {

  try {

    let user = {
      pwd: payload.deleteItemPwd,
      accessToken: localStorage.getItem('fvAccessToken')
    }

    const checkPasswordResponse = yield call(checkPassword, user);

    if (String(checkPasswordResponse).substring(String(checkPasswordResponse).length - 3) === '401') {
      // yield put(actions.openSessionExpiredModal())
      yield put(actions.notifyError("Email or password incorrect. "));
    } else {

      if (checkPasswordResponse.status === 200) {

        const response = yield call(API.deletePlayerTransfer, payload.deleteItemId);

        if (response.status === 200) {
          yield put(actions.notifySuccess('Delete Successful !'))
          yield put(actions.getPlayerTransfers());
        } else {
          yield put(actions.notifyError());
        }

      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    console.log(error)
  }
}

export function* updatePlayerTransferWorker(payload) {

  try {

    const response = yield call(API.updatePlayerTransfer, payload.playerTransfer);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(actions.openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response.status === 200) {
        yield put(authResult())
        // payload.callback('playerTransfer')
        yield put(actions.getPlayerTransfers());
        yield put(actions.notifySuccess('Update Successful !'))
      } else {
        yield put(actions.notifyError());
      }
    }

  } catch (error) {
    yield put(authResult())
    console.log(error)
  }
}