import React from 'react';

const FormButton = ({ title = 'Title', iconLeft, iconRight, type = "button", ...rest }) => {

    return (
        <button className="btn" style={{ backgroundColor: '#004843', color: '#fff' }} type={type} {...rest}>
            {iconLeft && <i className={`fa fa-${iconLeft} mr-2`}></i>} {title}
            {iconRight && <i className={`fa fa-${iconRight} ml-2`}></i>}</button>
    );
}


export default FormButton;
