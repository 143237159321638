import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import * as jwt from "jsonwebtoken";
import ModalFooter from "./ModalFooter";
import { closeSessionExpiredModal } from "../screens/admin/actions";
import { signin } from "../screens/auth/actions";

const SessionExpiredModal = (props) => {
  const navigate = useNavigate();

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  const [pwd, setPwd] = useState("");

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "password_text":
        setPwd(e.target.value);
        break;

      default:
        break;
    }
  };

  // switch (adminState.sessionExpiredModal) {
  //     case true:
  //         {

  //             if (document.getElementById('sessionExpiredModal') != null) {
  //                 document.getElementById('sessionExpiredModal').click()
  //             }
  //         }
  //         break;

  //     default:
  //         break;
  // }

  const signout = () => {
    localStorage.removeItem("fvAuth");
    localStorage.removeItem("fvApp");
    localStorage.removeItem("fvAccessToken");

    // switch (adminState.reload) {
    //     case true:
    //         window.location.reload(false)
    //         break;

    //     default:
    //         break;
    // }

    dispatch(closeSessionExpiredModal());

    navigate("/signin");
  };

  const connect = () => {
    let user = {
      email: jwt.decode(localStorage.getItem("fvAccessToken")).email,
      pwd,
    };

    setPwd("");
    dispatch(closeSessionExpiredModal());
    dispatch(signin(user, navigate, adminState.sessionExpiredSource));
  };

  return (
    <Modal centered show={adminState.sessionExpiredModal}>
      <Modal.Body style={{ textAlign: "center" }}>
        <h1>SESSION EXPIRÉE</h1>
        <p>
          veuillez saisir le mot de passe de l'utilisateur:{" "}
          <b style={{ fontWeight: "bold" }}>
            {jwt.decode(localStorage.getItem("fvAccessToken")).email}
          </b>
        </p>

        <input
          placeholder="Mot de passe"
          type="password"
          id="password_text"
          onChange={handleInputChange}
          className="form-control"
          value={pwd}
        />

        <div style={{ marginTop: 10 }}>
          <button
            style={{ backgroundColor: "#00387B", color: "#fff" }}
            disabled={pwd === "" ? true : false}
            onClick={() => connect()}
            class="btn"
          >
            Connexion
          </button>
          <button class="btn" onClick={() => signout()}>
            Se Déconnecter
          </button>
          {/* <button class="btn" style={{ backgroundColor: '#00387B', color: '#fff' }} onClick={() => signout()} >Connexion</button> */}
        </div>
      </Modal.Body>

      <ModalFooter />
    </Modal>
    // <div>
    //     <button id="sessionExpiredModal" data-toggle="modal" data-target="#seModal" hidden></button>

    //     <div class="modal fade" id="seModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    //         <div class="modal-dialog modal-dialog-centered" role="document">
    //             <div class="modal-content">

    //                 <SessionExpiredForm dispatch={dispatch} />
    //                 <ModalFooter />
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
};

export default SessionExpiredModal;
