import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { CardRow, EmptyDetailCard } from '../../../components';
import { closeNotify, getLoggedInUser } from '../actions';
import { stringReduceAddDots } from '../../../utilities';


const Profile = () => {

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoggedInUser());
    return () => dispatch(closeNotify())
  }, [dispatch]);


  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span><i className="fa fa-user fa-lg mr-3"></i></span>MY PROFILE</h6>

            </div>
          </div>

          {adminState.loggedInUser.length !== 0 ?
            <div class="row gutters-sm">
              <div class="col-md-4 mb-3">
                <div class="card shadow mb-25 bg-white rounded my-3" >
                  <div class="card-body">
                    <div class="d-flex flex-column align-items-center text-center">
                      <img src={adminState.loggedInUser.picture || '/assets/images/profile.png'} alt="Admin" class="rounded-circle" width="150" height="150" />
                      <div class="mt-3">
                        <h6>{`${adminState.loggedInUser.name} ${adminState.loggedInUser.surname}`}</h6>

                        <p class="font-size-sm mb-0" style={{fontSize: 12}}>{adminState.loggedInUser.matricule === null ? '' : adminState.loggedInUser.matricule}</p>
                        <p class="font-size-sm" style={{fontSize: 12}}>{adminState.loggedInUser._role === null ? '' : adminState.loggedInUser._role.name}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card shadow mb-25 bg-white rounded my-3" >
                  <div class="card-body">
                    <CardRow title='League' value={adminState.loggedInUser.league} title2='Region' value2={adminState.loggedInUser._region === null ? '' : adminState.loggedInUser._region.name} />
                    <CardRow title="Departement" value={adminState.loggedInUser._departement === null ? '' : adminState.loggedInUser._departement.name} title2={stringReduceAddDots('District',10)} value2={adminState.loggedInUser._arrondissement === null ? '' : adminState.loggedInUser._arrondissement.name} showHR={false} />
                  </div>
                </div>

              </div>
              <div class="col-md-8">
                <div class="card mb-3 shadow bg-white rounded my-3" >
                  <div class="card-body">

                    <CardRow title='Name' value={adminState.loggedInUser.name} title2='Surname' value2={adminState.loggedInUser.surname} />
                    <CardRow title='Date of birth' value={adminState.loggedInUser.dob} title2='Place of birth' value2={adminState.loggedInUser.pob} />
                    <CardRow title='Marital status' value={adminState.loggedInUser.familyStatus} title2='Gender' value2={adminState.loggedInUser.gender} />
                    <CardRow title='Email' value={adminState.loggedInUser.email} showHR={false} title2="Phone number" value2={adminState.loggedInUser.phone} />

                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="card mb-3 shadow bg-white rounded my-3" >
                      <div class="card-body">
                        <CardRow title="Profession" value={adminState.loggedInUser.profession} title2='Role' value2={adminState.loggedInUser._role.name} />
                        <CardRow title="Matricule" value={adminState.loggedInUser.matricule} title2="Education level" value2={adminState.loggedInUser.schoolLevel} />
                        <CardRow title="Creation date" value={<Moment format='DD/MM/YYYY'>{adminState.loggedInUser.creationDate}</Moment>} showHR={false} />
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

            : <EmptyDetailCard />}

        </div>
      </div>
    </div>
  );
}

export default Profile