import React from 'react';

const CardContainer = (props) => {
    return (
        <div class="row">
            <div class="col-md-12">
                <div class="card" >
                    <div class="card-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardContainer;