import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  InputTextSmall,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants, locals } from "../../../utilities";
import { DATE_VALIDATOR_LOCALS } from "../../../utilities/locals";
import {
  getRole,
  updateUser,
  saveUser,
  getDepartements,
  getArrondissements,
  getRegions,
  resetArrondissements,
  getLoggedInUser,
  getMatriculeCodes,
  setSignature,
} from "../actions";
import { getUser } from "../apis";

const RefereeFormAdmin = (props) => {
  const location = useLocation();
  const userId = location.state;

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSignature(""));
    dispatch(getRole("ARBIT"));
    dispatch(getRegions());
    dispatch(getLoggedInUser());
    dispatch(getMatriculeCodes());
    loadLigue();

    if (userId) {
      getUser(userId).then((response) => {
        refereeInitializer(response.data);
      });
    }
  }, []);

  const loadLigue = () => {
    const ligue = localStorage.getItem("fvApp");

    if (ligue !== locals.ADMIN_LOCALS) {
      setLeague(adminState.loggedInUser.league);
      setDisabled(true);
    }
  };

  const refereeInitializer = (referee) => {
    setId(referee.id);
    setName(referee.name);
    setSurname(referee.surname);
    setDob(referee.dob);
    setPob(referee.pob);
    setMatricule(referee.matricule);
    setGender(referee.gender);
    setFamilyStatus(referee.familyStatus);
    setProfession(referee.profession);
    setSchoolLevel(referee.schoolLevel);
    setLeague(referee.league);
    setPhone(referee.phone);
    setEmail(referee.email);
    setPicture(referee.picture);
    if (referee.signature !== null) {
      dispatch(setSignature(referee.signature));
    }
    setConseilAdministration(referee.conseilAdministration);
    setCommission(referee.commission);
    // setRegion(referee._region.id);
    // dispatch(getDepartements(referee._region.id));
    // setDepartement(referee._departement.id);
    // dispatch(getArrondissements(referee._departement.id));
    // setArrondissement(referee._arrondissement.id);

    if (referee._region !== null) {
      setRegion(referee._region.id);
      dispatch(getDepartements(referee._region.id));
    } else {
      setRegion(null);
    }

    if (referee._departement !== null) {
      setDepartement(referee._departement.id);
      dispatch(getArrondissements(referee._departement.id));
    } else {
      setDepartement(null);
    }

    if (referee._arrondissement !== null) {
      setArrondissement(referee._arrondissement.id);
    } else {
      setArrondissement(null);
    }

    // console.log(adminState.grade)
    referee.grade.forEach((arbitre) => {
      switch (arbitre.name) {
        case "Departmental Referee":
          return arbitreDepartementalInitializer(arbitre);

        case "Regional Referee":
          return arbitreRegionalInitializer(arbitre);

        case "National Referee":
          return arbitreNationalInitializer(arbitre);

        case "International Referee":
          return arbitreInternationalInitializer(arbitre);

        default:
          break;
      }
    });
  };

  const arbitreDepartementalInitializer = (ad) => {
    setAdDateObtention(ad.date);
    setAdLieu(ad.place);
    setAdFormateur(ad.instructor);
    setAdObservation(ad.observation);
  };

  const arbitreRegionalInitializer = (ar) => {
    setArDateObtention(ar.date);
    setArLieu(ar.place);
    setArFormateur(ar.instructor);
    setArObservation(ar.observation);
  };

  const arbitreNationalInitializer = (an) => {
    setAnDateObtention(an.date);
    setAnLieu(an.place);
    setAnFormateur(an.instructor);
    setAnObservation(an.observation);
  };

  const arbitreInternationalInitializer = (ai) => {
    setAiDateObtention(ai.date);
    setAiLieu(ai.place);
    setAiFormateur(ai.instructor);
    setAiObservation(ai.observation);
  };

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dob, setDob] = useState("");
  const [pob, setPob] = useState("");
  const [matricule, setMatricule] = useState("");
  const [gender, setGender] = useState("");
  const [familyStatus, setFamilyStatus] = useState("");
  const [profession, setProfession] = useState("");
  const [schoolLevel, setSchoolLevel] = useState("");
  const [league, setLeague] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [picture, setPicture] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [validate, setValidate] = useState(false);
  const [validateMessage, setValidateMessage] = useState("");
  const [_region, setRegion] = useState("");
  const [_departement, setDepartement] = useState("");
  const [_arrondissement, setArrondissement] = useState("");
  const [matriculeCode, setMatriculeCode] = useState("");
  const [conseilAdministration, setConseilAdministration] = useState(false);
  const [commission, setCommission] = useState(false);

  /*
  AD: Departmental Referee
  AR: Regional Referee
  AN: National Referee
  AI: International Referee
  */

  const [adDateObtention, setAdDateObtention] = useState("");
  const [adLieu, setAdLieu] = useState("");
  const [adFormateur, setAdFormateur] = useState("");
  const [adObservation, setAdObservation] = useState("");
  const [arDateObtention, setArDateObtention] = useState("");
  const [arLieu, setArLieu] = useState("");
  const [arFormateur, setArFormateur] = useState("");
  const [arObservation, setArObservation] = useState("");
  const [anDateObtention, setAnDateObtention] = useState("");
  const [anLieu, setAnLieu] = useState("");
  const [anFormateur, setAnFormateur] = useState("");
  const [anObservation, setAnObservation] = useState("");
  const [aiDateObtention, setAiDateObtention] = useState("");
  const [aiLieu, setAiLieu] = useState("");
  const [aiFormateur, setAiFormateur] = useState("");
  const [aiObservation, setAiObservation] = useState("");

  const callback = () => {
    navigate("/admin/referee");
  };

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "surname":
        setSurname(e.target.value);
        break;
      case "dob":
        {
          let date = new Date(e.target.value);
          let newDate = new Date();
          let validAge = newDate.getFullYear() - date.getFullYear();

          if (validAge < 10 || validAge > 99) {
            setValidate(true);
            setDob("");
            setValidateMessage(DATE_VALIDATOR_LOCALS);
          } else {
            setDob(e.target.value);
            setValidate(false);
          }
        }
        break;
      case "matricule":
        setMatricule(e.target.value);
        break;
      case "gender":
        setGender(e.target.value);
        break;
      case "pob":
        setPob(e.target.value);
        break;
      case "matriculeCode":
        setMatriculeCode(e.target.value);
        break;
      case "familyStatus":
        setFamilyStatus(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "profession":
        setProfession(e.target.value);
        break;
      case "league":
        setLeague(e.target.value);
        break;
      case "schoolLevel":
        setSchoolLevel(e.target.value);
        break;
      case "adDateObtention":
        setAdDateObtention(e.target.value);
        break;
      case "adLieu":
        setAdLieu(e.target.value);
        break;
      case "adFormateur":
        setAdFormateur(e.target.value);
        break;
      case "adObservation":
        setAdObservation(e.target.value);
        break;
      case "arDateObtention":
        setArDateObtention(e.target.value);
        break;
      case "arLieu":
        setArLieu(e.target.value);
        break;
      case "arFormateur":
        setArFormateur(e.target.value);
        break;
      case "arObservation":
        setArObservation(e.target.value);
        break;
      case "anDateObtention":
        setAnDateObtention(e.target.value);
        break;
      case "anLieu":
        setAnLieu(e.target.value);
        break;
      case "anFormateur":
        setAnFormateur(e.target.value);
        break;
      case "anObservation":
        setAnObservation(e.target.value);
        break;
      case "aiDateObtention":
        setAiDateObtention(e.target.value);
        break;
      case "aiLieu":
        setAiLieu(e.target.value);
        break;
      case "aiFormateur":
        setAiFormateur(e.target.value);
        break;
      case "aiObservation":
        setAiObservation(e.target.value);
        break;
      case "_region":
        dispatch(getDepartements(e.target.value));
        setRegion(e.target.value);
        dispatch(resetArrondissements());
        break;
      case "_departement":
        dispatch(getArrondissements(e.target.value));
        setDepartement(e.target.value);
        break;
      case "_arrondissement":
        setArrondissement(e.target.value);
        break;
      case "conseilAdministration":
        setConseilAdministration(e.target.checked);
        break;
      case "commission":
        setCommission(e.target.checked);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let user = {
      id,
      name,
      surname,
      dob,
      gender,
      pob,
      familyStatus,
      phone,
      email,
      profession,
      schoolLevel,
      league,
      _role: adminState.code,
      state: true,
      matricule,
      matriculeCode,
      _region,
      _departement,
      _arrondissement,
      conseilAdministration,
      commission,
      signature: adminState.signature,
    };

    if (picture.base64 === undefined) {
      user = {
        ...user,
        picture: picture,
      };
    } else {
      user = {
        ...user,
        picture: picture.base64,
      };
    }

    let arbitreDepartemental = {
      name: "Departmental Referee",
      date: adDateObtention,
      place: adLieu,
      instructor: adFormateur,
      observation: adObservation,
    };

    let arbitreRegional = {
      name: "Regional Referee",
      date: arDateObtention,
      place: arLieu,
      instructor: arFormateur,
      observation: arObservation,
    };

    let arbitreNational = {
      name: "National Referee",
      date: anDateObtention,
      place: anLieu,
      instructor: anFormateur,
      observation: anObservation,
    };

    let arbitreInternational = {
      name: "International Referee",
      date: aiDateObtention,
      place: aiLieu,
      instructor: aiFormateur,
      observation: aiObservation,
    };

    if (
      adDateObtention !== "" &&
      adLieu !== "" &&
      adFormateur !== "" &&
      adObservation !== ""
    ) {
      user = {
        ...user,
        arbitreDepartemental,
      };
    }

    if (
      arDateObtention !== "" &&
      arLieu !== "" &&
      arFormateur !== "" &&
      arObservation !== ""
    ) {
      user = {
        ...user,
        arbitreRegional,
      };
    }

    if (
      anDateObtention !== "" &&
      anLieu !== "" &&
      anFormateur !== "" &&
      anObservation !== ""
    ) {
      user = {
        ...user,
        arbitreNational,
      };
    }

    if (
      aiDateObtention !== "" &&
      aiLieu !== "" &&
      aiFormateur !== "" &&
      aiObservation !== ""
    ) {
      user = {
        ...user,
        arbitreInternational,
      };
    }

    if (picture.length === 0) {
      alert("Please add a picture");
    } else if (picture.length === 0) {
      alert("Please add a picture");
    } else if (!name.trim() || !surname.trim()) {
      alert("Please enter a valid name");
    } else if (matriculeCode === "" && id === "") {
      alert("Please choose a MATRICULE CODE");
    } else {
      id === ""
        ? dispatch(saveUser(user, callback))
        : dispatch(updateUser(user, callback));
    }
  };

  const renderMatriculeCodes = () => {
    if (id === "") {
      return (
        <div class="col-lg-4 mt-3">
          <div class="form-group">
            <label for="matriculeCode">Code Matricule</label>{" "}
            <span class="text-danger ml-1">*</span>
            <select
              class="browser-default custom-select"
              name="matriculeCode"
              id="matriculeCode"
              onChange={handleInputChange}
              value={matriculeCode}
            >
              <option value="" disabled selected>
                Choose an option
              </option>
              {adminState.matriculeCodes.map((e) => {
                return <option value={e.code}>{e.code}</option>;
              })}
            </select>
          </div>
        </div>
      );
    } else return <div></div>;
  };

  return (
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-12">
              <h6 class="h2 d-inline-block text-uppercase mb-0">
                <span class="">
                  <i class="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!userId ? "ADD" : "EDIT"} A REFEREE
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="font-weight-bold mb-4">General information</h4>
                    <div class="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={name}
                        required
                      />
                      <InputTextLarge
                        title="Surname"
                        name="surname"
                        id="surname"
                        onChange={handleInputChange}
                        value={surname}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        validate={validate}
                        validateMessage={validateMessage}
                        title="Date of birth"
                        type="date"
                        name="dob"
                        id="dob"
                        onChange={handleInputChange}
                        value={dob}
                        required
                      />

                      <InputTextLarge
                        title="Place of birth"
                        name="pob"
                        id="pob"
                        onChange={handleInputChange}
                        value={pob}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Gender"
                        options={DropdownConstants.GENDER}
                        name="gender"
                        id="gender"
                        onChange={handleInputChange}
                        value={gender}
                        required
                      />

                      <DropdownLarge
                        title="Marital status"
                        options={DropdownConstants.FAMILY_STATUS}
                        name="familyStatus"
                        id="familyStatus"
                        onChange={handleInputChange}
                        value={familyStatus}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <InputText
                        title="Profession"
                        name="profession"
                        id="profession"
                        onChange={handleInputChange}
                        value={profession}
                        required
                      />

                      <Dropdown
                        title="Education level"
                        options={DropdownConstants.SCHOOL_LEVEL}
                        name="schoolLevel"
                        id="schoolLevel"
                        onChange={handleInputChange}
                        value={schoolLevel}
                        required
                      />

                      <InputText
                        title="Phone"
                        type="number"
                        name="phone"
                        id="phone"
                        onChange={handleInputChange}
                        value={phone}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputText
                        title="Email"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleInputChange}
                        value={email}
                        required
                      />

                      <Dropdown
                        title="League"
                        options={DropdownConstants.LEAGUE}
                        name="league"
                        id="league"
                        onChange={handleInputChange}
                        disabled={disabled}
                        value={league}
                        required
                      />

                      {adminState.matriculeCodes.length !== 0
                        ? renderMatriculeCodes()
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {picture !== null ? (
                        <div class="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span class="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture.length !== 0 && (
                                <div class="col">
                                  {picture.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div class="col-md-10" style={{ marginTop: -19 }}>
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}

                      <DropdownLarge
                        title="Region"
                        options={adminState.regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={_region}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Departement"
                        options={adminState.departements}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={_departement}
                        required
                      />
                      <DropdownLarge
                        title="District"
                        options={adminState.arrondissements}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={_arrondissement}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                      <div class="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="conseilAdministration"
                          onChange={handleInputChange}
                          checked={conseilAdministration}
                        />
                        <label>Member of the Board</label>
                      </div>

                      <div class="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="commission"
                          onChange={handleInputChange}
                          checked={commission}
                        />
                        <label>Member of the Commission</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="font-weight-bold mb-4 mt-5">
                      Professional information
                    </h4>
                    <h5 class="h5">Grade</h5>
                    <h6 class="h6">Departmental Referee</h6>
                    <div class="row">
                      <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="adDateObtention"
                        id="adDateObtention"
                        onChange={handleInputChange}
                        value={adDateObtention}
                      />

                      <InputTextSmall
                        title="Location"
                        name="adLieu"
                        id="adLieu"
                        onChange={handleInputChange}
                        value={adLieu}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="adFormateur"
                        id="adFormateur"
                        onChange={handleInputChange}
                        value={adFormateur}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="adObservation"
                        id="adObservation"
                        onChange={handleInputChange}
                        value={adObservation}
                      />
                    </div>
                    <h6 class="h6">Regional Referee</h6>
                    <div class="row">
                      <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="arDateObtention"
                        id="arDateObtention"
                        onChange={handleInputChange}
                        value={arDateObtention}
                      />

                      <InputTextSmall
                        title="Location"
                        name="arLieu"
                        id="arLieu"
                        onChange={handleInputChange}
                        value={arLieu}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="arFormateur"
                        id="arFormateur"
                        onChange={handleInputChange}
                        value={arFormateur}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="arObservation"
                        id="arObservation"
                        onChange={handleInputChange}
                        value={arObservation}
                      />
                    </div>
                    <h6 class="h6">National Referee</h6>
                    <div class="row">
                      <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="anDateObtention"
                        id="anDateObtention"
                        onChange={handleInputChange}
                        value={anDateObtention}
                      />

                      <InputTextSmall
                        title="Location"
                        name="anLieu"
                        id="anLieu"
                        onChange={handleInputChange}
                        value={anLieu}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="anFormateur"
                        id="anFormateur"
                        onChange={handleInputChange}
                        value={anFormateur}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="anObservation"
                        id="anObservation"
                        onChange={handleInputChange}
                        value={anObservation}
                      />
                    </div>
                    <h6 class="h6">International Referee</h6>
                    <div class="row">
                      <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="aiDateObtention"
                        id="aiDateObtention"
                        onChange={handleInputChange}
                        value={aiDateObtention}
                      />

                      <InputTextSmall
                        title="Location"
                        name="aiLieu"
                        id="aiLieu"
                        onChange={handleInputChange}
                        value={aiLieu}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="aiFormateur"
                        id="aiFormateur"
                        onChange={handleInputChange}
                        value={aiFormateur}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="aiObservation"
                        id="aiObservation"
                        onChange={handleInputChange}
                        value={aiObservation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!adminState.loading ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RefereeFormAdmin;
