import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../../components';

const NotFound = ({ showFooter = true, showBackButton = true }) => {
    return (

        <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <h1 style={{ fontSize: 150 }}>404</h1>
            <h1>La Page que vous demandez n'existe pas!</h1>
            {showBackButton ? <Link to="/" className="btn">
                Acceuil </Link> : null}
                {showFooter ? <Footer /> : null }
        </div>

    )
}

export default NotFound;