import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { EmptyTable } from "../../../components";

const ETimeoutModal = ({ handleClose, show, team, onSetTimeout, time, timeoutCount, data }) => {
  const [timeoutType, setTimeoutType] = useState("regular");
  const [timer, setTimer] = useState(30);
  const [isPaused, setIsPaused] = useState(true);

  const onPause = () => {
    setIsPaused((prev) => !prev);
  };

  useEffect(
    function () {
      if (!isPaused) {
        setTimeout(function () {
          if (timer > 0) {
            setTimer(timer - 1);
          } else {
            onSetTimeout({ type: timeoutType, startTime: time });
          }
        }, 1000);
      }
    },
    [timer, isPaused, handleClose, onSetTimeout, time, timeoutType]
  );

  return (
    <Modal
      show={show}
      centered
      // backdrop={!isPaused && "static"}
      size="lg"
      onHide={isPaused && handleClose}
    >
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>TIMEOUT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       

        {/* offste */}

        <div className="row">
          <div className="col-md-6">
          <div className="d-block justify-content-center text-center w-100">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={`/assets/images/time-${timer < 10 ? 'bomb2' : 'bomb'}.png`}
              alt="scored"
              width={100}
              height={100}
            />
            <h1
              style={{ fontSize: 100, color: timer < 10 && "#E56969" }}
              className="mx-2"
            >
              :
            </h1>
            <h1 style={{ fontSize: 100, color: timer < 10 && "#E56969" }}>
              {timer}
            </h1>
            <h1 className="mt-5" style={{ color: timer < 10 && "#E56969" }}>
              S
            </h1>
          </div>
          <h1>{timeoutCount} / 2</h1>

          {/* <div className="">
            <p className="text-left mb-0">Timeout Type:</p>
            <select
              value={timeoutType}
              disabled={!isPaused}
              onChange={({ target }) => setTimeoutType(target.value)}
              className="form-control form-control-sm"
            >
              <option value={"regular"}>Regular</option>
              <option value={"technical"}>Technical</option>
            </select>
          </div> */}
          {/* <hr /> */}
        </div>
        <div className="d-flex justify-content-between border-top mt-3 pt-2">
          <div>
            <p className="mb-0">Team:</p>
            <h1>{team.name}</h1>
          </div>
          <div>
            <p className="mb-0">Time:</p>
            <h1>{time}</h1>
          </div>
        </div>
          </div>
          <div className="col-md-6 border-left">
            <h4 className="text-center">
              <b>Timeout history</b>
            </h4>
            {data.length ? data.filter(dat=>dat.team===team.id).map(history=><div className="border px-2" style={{backgroundColor: "#c2c2c220"}}>
              <div className="d-flex justify-content-between">
                <div>
                  <small className="mb-0">Set:</small>
                  <h6 style={{ fontWeight: 600, fontSize: 13}}>{history.set}</h6>
                </div>
                <div>
                  <small className="mb-0">Start Time:</small>
                  <h6 style={{ fontWeight: 600, fontSize: 13}}>{history.startTime}</h6>
                </div>
                <div>
                  <small className="mb-0">End Time:</small>
                  <h6 style={{ fontWeight: 600, fontSize: 13}}>{history.endTime}</h6>
                </div>
                <div>
                  <small className="mb-0">Score:</small>
                  <h6 style={{ fontWeight: 600, fontSize: 13}}>{history.score}</h6>
                </div>
              </div>
            </div>) : <EmptyTable titleStyle={{fontSize: 14}} imageStyle={{height: 70, width: 70}} rootStyle={{marginTop: 60}} />}
            
          </div>
        </div>

        <div className="d-flex mt-5">
        
          <button
            disabled={!isPaused || timeoutCount >=2}
            className="btn btn-sm w-50"
            onClick={onPause}
            style={{ backgroundColor: "#00a1ff", color: "white" }}
          >
            Start Timeout
          </button>

          <button
            className="btn btn-sm w-50"
            disabled={!isPaused}
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ETimeoutModal;
