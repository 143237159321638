import React from 'react';
import { Loader } from '.';


const SaveSpinner = () => {

    return (

        <div className="row">
        <div className="col-md-12 text-center">
            <button type="button" class="btn btn-primary btn-sm ">
                <Loader size='sm' showText={false} />
            </button>
        </div>
    </div>

    );
}


export default SaveSpinner