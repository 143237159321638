import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ESubstitutionTableRow from "./ESubstitutionTableRow";

const ESubstitutionModal = ({
  handleClose,
  show,
  team,
  players,
  onBenchPlayers,
  time,
  onSubstitute,
  captainSelection,
  subCount,
}) => {
  const [playerSelection, setPlayerSelection] = useState({});

  const onPlayerSelect = (data) => {
    if (Object.keys(playerSelection || {})?.includes(data.playerIn)) {
      const result = Object.values(playerSelection || {}).filter(
        (res) => res.playerIn !== data.playerIn
      );
      const rest = result.reduce((a, b) => ({ ...a, [b.playerIn]: b }), {});
      setPlayerSelection(rest);
    } else {
      setPlayerSelection((prev) => ({ ...prev, [data.playerIn]: data }));
    }
  };

  return (
    <Modal show={show} centered size="lg" onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>SUBSTITUTION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: "65vh", overflow: "auto" }}>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <p className="mb-0">Team:</p>
              <h1>{team.name}</h1>
            </div>
            <div>
              <p className="mb-0">Substitutions remaining:</p>
              <h1 className="text-center">{6 - Number(subCount)}</h1>
            </div>
            <div>
              <p className="mb-0">Time:</p>
              <h1>{time}</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Player on court</th>
                    <th>Substitute</th>
                    <th>Change</th>
                  </tr>
                </thead>
                <tbody>
                  {players.map((item, index) => (
                    <ESubstitutionTableRow
                      key={index}
                      item={item}
                      index={index + 1}
                      players={players}
                      onBenchPlayers={onBenchPlayers}
                      onDeselect={onPlayerSelect}
                      playerSelection={playerSelection}
                      onPlayerSelect={onPlayerSelect}
                      captainSelection={captainSelection}
                      // clubID={selectedMatch._participantTwo?.id}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-center">
          {6 - Number(subCount) > 0 ? (
            <button
              className="btn btn-sm w-50"
              onClick={() => onSubstitute({ playerSelection, team, time })}
              style={{ backgroundColor: "#369688", color: "white" }}
            >
              Substitute
            </button>
          ) : (
            <button className="btn btn-sm w-50" disabled>
              Substitutions Exhausted
            </button>
          )}
          <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ESubstitutionModal;
