import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DatatableCard, Dropdown, Loader } from "../../../components";
import {
  closeNotify,
  getLigueUsers,
  showPasswordModal,
  updateUser,
} from "../actions";
import { DropdownConstants } from "../../../utilities";
import { hasPermission } from "../../../utilities/auth";

const Ligue = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(getUsers('LEAGU'))
    return () => dispatch(closeNotify());
  }, [dispatch]);

  const [pwd, setPwd] = useState("");
  const [user, setUser] = useState("");
  const [ligueType, setLigueType] = useState("");

  const onDelete = (user) => {
    dispatch(showPasswordModal(user, "user"));
  };

  const callback = () => {
    navigate("ligue");
  };

  const onPasswordGenerator = (data) => {
    setUser(data);
    setPwd(data.pwd);
    document.getElementById("passwordGenerator").click();
  };

  const generatePassword = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setPwd(retVal);
  };

  const saveGeneratedPassword = () => {
    if (user !== "" && pwd !== "") {
      let userUpdate = {
        ...user,
        pwd,
      };
      dispatch(updateUser(userUpdate, callback));
      document.getElementById("closePwdGenerator").click();
    }
  };

  const closePwdGenerator = () => {
    setPwd("");
    document.getElementById("closePwdGenerator").click();
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Surname",
      selector: "surname",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Matricule",
      selector: "matricule",
      sortable: true,
    },
    {
      name: "Profession",
      selector: "profession",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) =>
        adminState.loggedInUser.length !== 0
          ? hasPermission(adminState.loggedInUser, ["VIEW_LEAGUE_MEMBER"]) && (
              <Link
                to={{ pathname: `/admin/ligue-detail/` }}
                className="btn btn-sm"
                state={row.id}
              >
                <i className="fa fa-list"></i>
              </Link>
            )
          : null,
    },
    {
      cell: (row, index, column, selector) => (
        <button
          style={{ margin: 0, backgroundColor: "#139c9b", color: "#fff" }}
          onClick={() => onPasswordGenerator(row)}
          className="btn btn-sm"
        >
          <i className="fa fa-lock"></i>
        </button>
      ),
    },
    {
      cell: (row, index, column, selector) =>
        adminState.loggedInUser.length !== 0
          ? hasPermission(adminState.loggedInUser, ["EDIT_LEAGUE_MEMBER"]) && (
              <Link
                to={{ pathname: `/admin/ligue-form` }}
                className="btn btn-sm btn-primary"
                state={row.id}
              >
                <i className="fa fa-edit"></i>
              </Link>
            )
          : null,
    },
    {
      cell: (row, index, column, selector) =>
        adminState.loggedInUser.length !== 0
          ? hasPermission(adminState.loggedInUser, [
              "DEACTIVATE_LEAGUE_MEMBER",
            ]) && (
              <button
                onClick={() => onDelete(row)}
                className="btn btn-sm btn-danger"
              >
                <i className="fa fa-trash"></i>
              </button>
            )
          : null,
    },
  ];

  const tableData = {
    columns,
    data: adminState.ligueUsers,
  };

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "ligueType":
        setLigueType(e.target.value);
        dispatch(getLigueUsers(e.target.value));
        break;

      default:
        break;
    }
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                LISTE DES MEMBRES DE LA LIGUE
              </h6>
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, [
                    "ADD_LEAGUE_MEMBER",
                  ]) && (
                    <h6
                      style={{ float: "right" }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/ligue-form"
                        style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg "></i>{" "}
                      </Link>
                    </h6>
                  )
                : null}
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, [
                    "PRINT_LEAGUE_MEMBER",
                  ]) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: "#343a3f", color: "#fff" }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-eye mr-1"></i> Preview print
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-print mr-1"></i> Print
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div
            className="row"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <Dropdown
              title="Filtre"
              options={DropdownConstants.LIGUE_TYPE}
              name="ligueType"
              id="ligueType"
              onChange={handleInputChange}
              value={ligueType}
            />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">LIGUE RÉGIONALE </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">LIGUE DÉPARTEMENTALE </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">LIGUE D’ARRONDISSEMENT</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"><LigueRegional /></div>
                                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"><LigueDepartemental /></div>
                                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"><LigueArrondissement /></div>
                                    </div> */}

                {adminState.loading ? (
                  <Loader />
                ) : (
                  <DatatableCard tableData={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="passwordGenerator"
        data-toggle="modal"
        data-target="#passwordGeneratorModal"
        className="btn btn-sm"
        style={{ display: "none" }}
      ></button>
      <div
        className="modal fade"
        id="passwordGeneratorModal"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#11213A", color: "#fff", padding: 12 }}
            >
              <h5 className="modal-title" id="exampleModalLabel">
                PASSWORD
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => closePwdGenerator()}
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ color: "#fff" }}>
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Generate le mot de passe de{" "}
                <b
                  style={{ fontWeight: "bold" }}
                >{`${user.name} ${user.surname}`}</b>
              </p>
              <div className="row">
                <div className="col-lg-8">
                  <input
                    placeholder="Mot de passe"
                    disabled
                    className="form-control"
                    type="text"
                    value={pwd}
                  />
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    onClick={() => generatePassword()}
                    style={{ backgroundColor: "#139c9b", color: "#fff" }}
                    className="btn btn-sm"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                style={{ display: "none" }}
                id="closePwdGenerator"
                className="btn btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => closePwdGenerator()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => saveGeneratedPassword()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ligue;
