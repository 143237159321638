import React from "react";

const EStopMatchSet = ({ score, teamA, teamB }) => {

  const secondTeam = teamB.scoreSet.filter(({set})=>set===score.set)[0]

  return (
    <div className="border-top pt-2 px-2" style={{ background: "#c2c2c220" }}>
      <small>
        <b>SET: {score.set}</b>
      </small>
      <div className="d-flex justify-content-between mt-1">
        <div>
          <small className="mb-0">{teamA.teamName} (Score):</small>
          <br />
          <small>
            <b>{score.score}</b>
          </small>
        </div>
        <div>
          <small className="mb-0">{teamB.teamName} (Score):</small>
          <br />
          <small>
            <b>{secondTeam.score}</b>
          </small>
        </div>
      </div>
    </div>
  );
};

export default EStopMatchSet;
