import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  BackButton,
  CardContainer,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants, locals, yupValidation } from "../../../utilities";
import {
  getArrondissements,
  getDepartements,
  getRegions,
  getRole,
  saveUser,
  updateUser,
  getClubsAuxiliaire,
  resetArrondissements,
  getLoggedInUser,
  getMatriculeCodes,
  setSignature,
} from "../actions";
import { getUser } from "../apis";
import { useLocation, useNavigate } from "react-router-dom";

const PlayerFormAdmin = (props) => {
  const location = useLocation();
  const userId = location.state;

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadLigue = () => {
    const ligue = localStorage.getItem("fvApp");

    if (ligue !== locals.ADMIN_LOCALS) {
      setLeague(adminState.loggedInUser.league);
      setDisabled(true);
    }
  };

  const callback = () => {
    navigate("/admin/player");
  };

  const initializeUser = (user) => {
    setPicture(user.picture);
    if (user.signature !== null) {
      dispatch(setSignature(user.signature));
    }

    setValues(user);

    dispatch(getDepartements(user._region.id));
    dispatch(getArrondissements(user._departement.id));
    dispatch(getClubsAuxiliaire(user.auxiliaire));

    setFieldValue("_region", user._region.id || "");
    setFieldValue("_arrondissement", user._arrondissement.id || "");
    setFieldValue("_departement", user._departement.id);
    setFieldValue("_club", user.clubs[0].id || "");
  };

  const [picture, setPicture] = useState([]);
  const [league, setLeague] = useState("");
  const [disabled, setDisabled] = useState(false);

  const {
    values,
    handleSubmit,
    getFieldProps,
    setValues,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      state: true,
      surname: "",
      dob: "",
      pob: "",
      gender: "",
      auxiliaire: false,
      matricule: "",
      _club: "",
      _region: "",
      _departement: "",
      _arrondissement: "",
      familyStatus: "",
      profession: "",
      schoolLevel: "",
      league: "",
      phone: "",
      email: "",
      conseilAdministration: false,
      commission: false,
      id: "",
      dossard: "",
    },
    validationSchema: Yup.object().shape(yupValidation.userYup),
    onSubmit(values) {
      let user = values;
      user = {
        ...user,
        signature: adminState.signature,
        _role: adminState.code,
        matriculeCode: values.matricule,
      };

      if (picture.base64 === undefined) {
        user = {
          ...user,
          picture: picture,
        };
      } else {
        user = {
          ...user,
          picture: picture.base64,
        };
      }

      if (picture.length === 0) {
        alert("Please add a picture");
      } else {
        if (values.id === "") {
          dispatch(saveUser(user, callback));
        } else {
          dispatch(updateUser(user, callback));
        }
      }
    },
  });

  const renderMatriculeCodes = () => {
    if (values.id === "") {
      return (
        <div class="col-lg-6">
          <div class="form-group">
            <label for="matricule">Code Matricule</label>{" "}
            <span class="text-danger ml-1">*</span>
            <select
              className={
                touched.matricule && errors.matricule
                  ? "browser-default custom-select is-invalid"
                  : "browser-default custom-select"
              }
              name="matricule"
              id="matricule"
              {...getFieldProps("matricule")}
            >
              <option value="" disabled selected>
                Choose an option
              </option>
              {adminState.matriculeCodes.map((e, index) => {
                return (
                  <option key={index} value={e.code}>
                    {e.code}
                  </option>
                );
              })}
            </select>
            {touched.matricule && errors.matricule && (
              <small className="text-danger ml-1">{errors.matricule}</small>
            )}
          </div>
        </div>
      );
    } else return <div></div>;
  };

  useEffect(() => {
    dispatch(setSignature(""));
    dispatch(getRole("JOUER"));
    dispatch(getClubsAuxiliaire(false));
    dispatch(getRegions());
    dispatch(getMatriculeCodes());
    dispatch(getLoggedInUser());
    loadLigue();

    if (userId) {
      getUser(userId).then((response) => {
        initializeUser(response.data);
      });
    }
  }, []);

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!userId ? "ADD" : "EDIT"} A PLAYER
              </h6>
              <BackButton />
              <h6 className="float-right">
                {!adminState.loading ? <SaveButton /> : <SaveSpinner />}
              </h6>
            </div>
          </div>

          <>
            <CardContainer>
              <h4 className="font-weight-bold mb-4">General information</h4>
              <div className="row">
                <InputTextLarge
                  title="Name"
                  name="name"
                  id="name"
                  error={touched.name && errors.name}
                  {...getFieldProps("name")}
                  required
                />
                <InputTextLarge
                  title="Surname"
                  name="surname"
                  id="surname"
                  error={touched.surname && errors.surname}
                  {...getFieldProps("surname")}
                  required
                />
              </div>

              <div className="row">
                <InputTextLarge
                  title="Date of birth"
                  type="date"
                  name="dob"
                  id="dob"
                  error={touched.dob && errors.dob}
                  {...getFieldProps("dob")}
                  required
                />
                <InputTextLarge
                  title="Place of birth"
                  name="pob"
                  id="pob"
                  error={touched.pob && errors.pob}
                  {...getFieldProps("pob")}
                  required
                />
              </div>

              <div className="row">
                <DropdownLarge
                  title="Gender"
                  options={DropdownConstants.GENDER}
                  name="gender"
                  id="gender"
                  error={touched.gender && errors.gender}
                  {...getFieldProps("gender")}
                  required
                />
                <InputTextLarge
                  title="Dossard"
                  name="dossard"
                  id="dossard"
                  error={touched.dossard && errors.dossard}
                  {...getFieldProps("dossard")}
                  required
                />
              </div>

              <div className="row">
                {adminState.matriculeCodes.length !== 0
                  ? renderMatriculeCodes()
                  : null}
                {adminState.clubs.length !== 0 ? (
                  <DropdownLarge
                    title="Club"
                    options={adminState.clubs}
                    dynamic={true}
                    name="club"
                    id="club"
                    error={touched._club && errors._club}
                    {...getFieldProps("_club")}
                    required
                  />
                ) : null}
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>{"Normal or Auxiliary Player ?"}</label>{" "}
                    <span className="text-danger ml-1">*</span>
                    <br />
                    <Checkbox
                      title="Normal"
                      id="normal"
                      onChange={(e) => {
                        setFieldValue("auxiliaire", false);
                        dispatch(getClubsAuxiliaire(false));
                      }}
                      checked={!values.auxiliaire}
                    />
                    <Checkbox
                      title="Auxiliary"
                      id="auxiliaire"
                      onChange={(e) => {
                        setFieldValue("auxiliaire", true);
                        dispatch(getClubsAuxiliaire(true));
                      }}
                      checked={values.auxiliaire}
                    />
                  </div>
                </div>
              </div>
            </CardContainer>

            <CardContainer>
              <div className="row">
                {picture !== null ? (
                  <div className="col-lg-6">
                    <div>
                      <label for={"Photo"}>{"Photo"}</label>{" "}
                      <span className="text-danger ml-1">*</span>
                      <br />
                      <div className="row">
                        {picture.length !== 0 && (
                          <div className="col">
                            {picture.base64 === undefined ? (
                              <img
                                src={picture}
                                height={50}
                                width={50}
                                alt="pic"
                              />
                            ) : (
                              <img
                                src={picture.base64}
                                height={50}
                                width={50}
                                alt="pic"
                              />
                            )}
                          </div>
                        )}
                        <div className="col-md-10" style={{ marginTop: -19 }}>
                          <InputPicture
                            placeholder="Choose another picture"
                            onDone={setPicture.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <InputPicture onDone={setPicture.bind(this)} />
                  </div>
                )}

                <DropdownLarge
                  title="Region"
                  options={adminState.regions}
                  dynamic={true}
                  name="_region"
                  id="_region"
                  error={touched._region && errors._region}
                  {...getFieldProps("_region")}
                  onChange={(e) => {
                    dispatch(getDepartements(e.target.value));
                    dispatch(resetArrondissements());
                    setFieldValue("_region", e.target.value);
                  }}
                  required
                />
              </div>

              <div className="row">
                <DropdownLarge
                  title="Departement"
                  options={adminState.departements}
                  dynamic={true}
                  name="_departement"
                  id="_departement"
                  error={touched._departement && errors._departement}
                  {...getFieldProps("_departement")}
                  onChange={(e) => {
                    dispatch(getArrondissements(e.target.value));
                    setFieldValue("_departement", e.target.value);
                  }}
                  required
                />
                <DropdownLarge
                  title="District"
                  options={adminState.arrondissements}
                  dynamic={true}
                  name="_arrondissement"
                  id="_arrondissement"
                  error={touched._arrondissement && errors._arrondissement}
                  {...getFieldProps("_arrondissement")}
                  onChange={(e) => {
                    setFieldValue("_arrondissement", e.target.value);
                  }}
                  required
                />
              </div>
            </CardContainer>

            <CardContainer>
              <div className="row">
                <Dropdown
                  title="Marital status"
                  options={DropdownConstants.FAMILY_STATUS}
                  name="familyStatus"
                  id="familyStatus"
                  error={touched.familyStatus && errors.familyStatus}
                  {...getFieldProps("familyStatus")}
                  required
                />
                <InputText
                  title="Profession"
                  name="profession"
                  id="profession"
                  error={touched.profession && errors.profession}
                  {...getFieldProps("profession")}
                />
                <Dropdown
                  title="Education level"
                  options={DropdownConstants.SCHOOL_LEVEL}
                  name="schoolLevel"
                  id="schoolLevel"
                  error={touched.schoolLevel && errors.schoolLevel}
                  {...getFieldProps("schoolLevel")}
                  required
                />
              </div>

              <div className="row">
                <Dropdown
                  title="League"
                  options={DropdownConstants.LEAGUE}
                  name="league"
                  id="league"
                  error={touched.league && errors.league}
                  {...getFieldProps("league")}
                  disabled={disabled}
                  required
                />
                <InputText
                  title="Phone"
                  type="number"
                  name="phone"
                  id="phone"
                  error={touched.phone && errors.phone}
                  {...getFieldProps("phone")}
                />
                <InputText
                  title="Email"
                  type="email"
                  name="email"
                  id="email"
                  error={touched.email && errors.email}
                  {...getFieldProps("email")}
                />
              </div>
            </CardContainer>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <CardContainer>
              <div className="row">
                {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                <div className="col d-flex align-items-center">
                  <Checkbox
                    title=""
                    id="conseilAdministration"
                    error={
                      touched.conseilAdministration &&
                      errors.conseilAdministration
                    }
                    {...getFieldProps("conseilAdministration")}
                  />
                  <label>Member of the Board</label>
                </div>

                <div className="col d-flex align-items-center">
                  <Checkbox
                    title=""
                    id="commission"
                    error={touched.commission && errors.commission}
                    {...getFieldProps("commission")}
                  />
                  <label>Member of the Commission</label>
                </div>
              </div>
            </CardContainer>

            <div className="float-right">
              {!adminState.loading ? <SaveButton /> : <SaveSpinner />}
            </div>
          </>
        </form>
      </div>
    </div>
  );
};

export default PlayerFormAdmin;
