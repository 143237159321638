import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants } from "../../../utilities";
import { DATE_VALIDATOR_LOCALS } from "../../../utilities/locals";
import {
  getRole,
  updateUser,
  saveUser,
  getArrondissements,
  getRegions,
  getDepartements,
  getSubRoles,
  resetArrondissements,
  getMatriculeCodes,
  setSignature,
} from "../actions";
import { getUser } from "../apis";

const LigueForm = (props) => {
  const location = useLocation();
  const userId = location.state;

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSignature(""));
    dispatch(getRole("LEAGU"));
    dispatch(getRegions());
    dispatch(getMatriculeCodes());

    if (userId) {
      getUser(userId).then((response) => {
        leagueInitializer(response.data);
      });
    }
  }, []);

  const leagueInitializer = (league) => {
    setId(league.id);
    setName(league.name);
    setSurname(league.surname);
    setDob(league.dob);
    setPob(league.pob);
    setMatricule(league.matricule);
    setGender(league.gender);
    setFamilyStatus(league.familyStatus);
    setProfession(league.profession);
    setSchoolLevel(league.schoolLevel);
    setLeague(league.league);
    setPhone(league.phone);
    setEmail(league.email);
    setPicture(league.picture);
    if (league.signature !== null) {
      dispatch(setSignature(league.signature));
    }
    setConseilAdministration(league.conseilAdministration);
    setCommission(league.commission);

    if (league._region !== null) {
      setRegion(league._region.id);
      dispatch(getDepartements(league._region.id));
    } else {
      setRegion(null);
    }

    if (league._departement !== null) {
      setDepartement(league._departement.id);
      dispatch(getArrondissements(league._departement.id));
    } else {
      setDepartement(null);
    }

    if (league._arrondissement !== null) {
      setArrondissement(league._arrondissement.id);
    } else {
      setArrondissement(null);
    }

    setLigueType(league.ligueType);

    switch (league.ligueType) {
      case "LIGUE RÉGIONALE":
        dispatch(getSubRoles(7));
        break;
      case "LIGUE DÉPARTEMENTAL":
        dispatch(getSubRoles(8));
        break;
      case "LIGUE D'ARRONDISSEMENT":
        dispatch(getSubRoles(9));
        break;
      default:
        dispatch(getSubRoles(7));
        break;
    }

    setSubRole(league._subRole.id);
  };

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dob, setDob] = useState("");
  const [matricule, setMatricule] = useState("");
  const [gender, setGender] = useState("");
  const [pob, setPob] = useState("");
  const [familyStatus, setFamilyStatus] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [picture, setPicture] = useState([]);
  const [profession, setProfession] = useState("");
  const [league, setLeague] = useState("");
  const [schoolLevel, setSchoolLevel] = useState("");
  const [_club, setClub] = useState("");
  const [validate, setValidate] = useState(false);
  const [validateMessage, setValidateMessage] = useState("");
  const [_region, setRegion] = useState("");
  const [_departement, setDepartement] = useState(null);
  const [_arrondissement, setArrondissement] = useState(null);
  const [ligueType, setLigueType] = useState("");
  const [showDepartement, setShowDepartement] = useState(false);
  const [showArrondissement, setShowArrondissement] = useState(false);
  const [_subRole, setSubRole] = useState("");
  const [matriculeCode, setMatriculeCode] = useState("");
  const [conseilAdministration, setConseilAdministration] = useState(false);
  const [commission, setCommission] = useState(false);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "surname":
        setSurname(e.target.value);
        break;
      case "dob":
        {
          let date = new Date(e.target.value);
          let newDate = new Date();
          let validAge = newDate.getFullYear() - date.getFullYear();

          if (validAge < 10 || validAge > 99) {
            setValidate(true);
            setDob("");
            setValidateMessage(DATE_VALIDATOR_LOCALS);
          } else {
            setDob(e.target.value);
            setValidate(false);
          }
        }
        break;
      case "matricule":
        setMatricule(e.target.value);
        break;
      case "matriculeCode":
        setMatriculeCode(e.target.value);
        break;
      case "gender":
        setGender(e.target.value);
        break;
      case "pob":
        setPob(e.target.value);
        break;
      case "familyStatus":
        setFamilyStatus(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "profession":
        setProfession(e.target.value);
        break;
      case "league":
        setLeague(e.target.value);
        break;
      case "schoolLevel":
        setSchoolLevel(e.target.value);
        break;
      case "role":
        setSubRole(e.target.value);
        break;
      case "club":
        setClub(e.target.value);
        break;
      case "_region":
        dispatch(getDepartements(e.target.value));
        setRegion(e.target.value);
        dispatch(resetArrondissements());
        break;
      case "_departement":
        dispatch(getArrondissements(e.target.value));
        setDepartement(e.target.value);
        break;
      case "_arrondissement":
        setArrondissement(e.target.value);
        break;
      case "conseilAdministration":
        setConseilAdministration(e.target.checked);
        break;
      case "commission":
        setCommission(e.target.checked);
        break;
      case "ligueType":
        switch (e.target.value) {
          case "LIGUE RÉGIONALE":
            setShowDepartement(false);
            setShowArrondissement(false);
            dispatch(getSubRoles(7));
            break;
          case "LIGUE DÉPARTEMENTAL":
            setShowDepartement(true);
            setShowArrondissement(false);
            dispatch(getSubRoles(8));
            break;
          case "LIGUE D'ARRONDISSEMENT":
            setShowDepartement(true);
            setShowArrondissement(true);
            dispatch(getSubRoles(9));
            break;

          default:
            setShowDepartement(false);
            setShowArrondissement(false);
            break;
        }
        setLigueType(e.target.value);
        break;

      default:
        break;
    }
  };

  const callback = () => {
    navigate("/admin/ligue");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let user = {
      id,
      name,
      state: true,
      surname,
      dob,
      ligueType,
      gender,
      pob,
      familyStatus,
      phone,
      email,
      profession,
      schoolLevel,
      matricule,
      league,
      _role: adminState.code,
      _region,
      _departement,
      _arrondissement,
      _subRole,
      matriculeCode,
      conseilAdministration,
      commission,
      signature: adminState.signature,
    };

    if (picture.base64 === undefined) {
      user = {
        ...user,
        picture: picture,
      };
    } else {
      user = {
        ...user,
        picture: picture.base64,
      };
    }

    if (picture.length === 0) {
      alert("Please add a picture");
    } else if (!name.trim() || !surname.trim()) {
      alert("Please enter a valid name");
    } else if (matriculeCode === "" && id === "") {
      alert("Please choose a MATRICULE CODE");
    } else {
      id === ""
        ? dispatch(saveUser(user, callback))
        : dispatch(updateUser(user, callback));
    }
  };

  const renderMatriculeCodes = () => {
    if (id === "") {
      return (
        <div class="col-lg-6">
          <div class="form-group">
            <label for="matriculeCode">Code Matricule</label>{" "}
            <span class="text-danger ml-1">*</span>
            <select
              class="browser-default custom-select"
              name="matriculeCode"
              id="matriculeCode"
              onChange={handleInputChange}
              value={matriculeCode}
            >
              <option value="" disabled selected>
                Choose an option
              </option>
              {adminState.matriculeCodes.map((e) => {
                return <option value={e.code}>{e.code}</option>;
              })}
            </select>
          </div>
        </div>
      );
    } else return <div></div>;
  };

  return (
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-12">
              <h6 class="h2 d-inline-block text-uppercase mb-0">
                <span class="">
                  <i class="fa fa-plus fa-lg mr-3"></i>
                </span>
                {location.state === undefined ? "ADD" : "EDIT"} A LIGUE MEMBER
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="font-weight-bold mb-4">General information</h4>
                    <div class="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={name}
                        required
                      />
                      <InputTextLarge
                        title="Surname"
                        name="surname"
                        id="surname"
                        onChange={handleInputChange}
                        value={surname}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        validate={validate}
                        validateMessage={validateMessage}
                        title="Date of birth"
                        type="date"
                        name="dob"
                        id="dob"
                        onChange={handleInputChange}
                        value={dob}
                        required
                      />

                      <InputTextLarge
                        title="Place of birth"
                        name="pob"
                        id="pob"
                        onChange={handleInputChange}
                        value={pob}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Gender"
                        options={DropdownConstants.GENDER}
                        name="gender"
                        id="gender"
                        onChange={handleInputChange}
                        value={gender}
                        required
                      />

                      <DropdownLarge
                        title="League Type"
                        options={DropdownConstants.LIGUE_TYPE}
                        name="ligueType"
                        id="ligueType"
                        onChange={handleInputChange}
                        value={ligueType}
                        required
                      />

                      <DropdownLarge
                        title="Role"
                        options={adminState.subRoles}
                        dynamic={true}
                        name="role"
                        id="role"
                        onChange={handleInputChange}
                        value={_subRole}
                        required
                      />

                      {adminState.matriculeCodes.length !== 0
                        ? renderMatriculeCodes()
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {picture !== null ? (
                        <div class="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span class="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture.length !== 0 && (
                                <div class="col">
                                  {picture.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div class="col-md-10" style={{ marginTop: -19 }}>
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}

                      <DropdownLarge
                        title="Region"
                        options={adminState.regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={_region}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Departement"
                        options={adminState.departements}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={_departement}
                        required
                      />
                      <DropdownLarge
                        title="District"
                        options={adminState.arrondissements}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={_arrondissement}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <Dropdown
                        title="Marital status"
                        options={DropdownConstants.FAMILY_STATUS}
                        name="familyStatus"
                        id="familyStatus"
                        onChange={handleInputChange}
                        value={familyStatus}
                        required
                      />

                      <InputText
                        title="Profession"
                        name="profession"
                        id="profession"
                        onChange={handleInputChange}
                        value={profession}
                        required
                      />

                      <Dropdown
                        title="Education level"
                        options={DropdownConstants.SCHOOL_LEVEL}
                        name="schoolLevel"
                        id="schoolLevel"
                        onChange={handleInputChange}
                        value={schoolLevel}
                        required
                      />
                    </div>

                    <div class="row">
                      <Dropdown
                        title="League"
                        options={DropdownConstants.LEAGUE}
                        name="league"
                        id="league"
                        onChange={handleInputChange}
                        value={league}
                        required
                      />

                      <InputText
                        title="Phone"
                        type="number"
                        name="phone"
                        id="phone"
                        onChange={handleInputChange}
                        value={phone}
                        required
                      />

                      <InputText
                        title="Email"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleInputChange}
                        value={email}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                      <div class="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="conseilAdministration"
                          onChange={handleInputChange}
                          checked={conseilAdministration}
                        />
                        <label>Member of the Board</label>
                      </div>

                      <div class="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="commission"
                          onChange={handleInputChange}
                          checked={commission}
                        />
                        <label>Member of the Commission</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!adminState.loading ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LigueForm;
