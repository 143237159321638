import React from "react";
import EOnBenchItem from "./EOnBenchItem";

const EOnBench = ({ side = "left", data, captainSelection }) => {
  return (
    <div>
      <div
        className={`block-title my-1 ${side === "right" ? "text-right" : ""}`}
      >
        <span className={`${side === "right" ? "mr-4" : "ml-4"}`}>
          On bench
        </span>
      </div>
      <div className={`on-benche-${side}`}>
        <ul className={`list-group list-group-flush`}>
          {data
            .sort((a, b) => a.position - b.position)
            .map((val, index) => (
              <EOnBenchItem
                title={val.player.name}
                value={val.kit}
                side={side}
                key={index}
                captain={captainSelection[val.player.id]?.role}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default EOnBench;
