import React, { useState } from "react";

const ESubstitutionTableRow = ({
  onPlayerSelect,
  clubID,
  players,
  item,
  playerSelection,
  onDeselect,
  onBenchPlayers,
  captainSelection,
  index
}) => {

  const [selectedPlayer, setSelectedPlayer] = useState("");

  const onValueChange = (value) => {
    // const player = players.filter(player=>player.id===value).shift()
onPlayerSelect({playerIn: value,playerOut:item.player.id, position: item.position})
    // onPlayerSelect({
    //   player: { id: player.id, name: `${player.name} ${player.surname}` },
    //   position,
    //   clubID
    // });
    setSelectedPlayer(value)
  };

  const onDeselectPlayer=()=>{
    onDeselect({playerIn:selectedPlayer});
    setSelectedPlayer("");
  }

  return (
    <tr>
      <td>
        {item.position}{" "}
        {captainSelection[item.player.id]?.role}
      </td>
      <td>
        {`(${item.kit}) ${item.player.name}`}
      </td>
      <td>
        <select
          onChange={(event) => onValueChange(event.target.value)}
          className="form-control form-control-sm"
          value={selectedPlayer}
        >
          <option></option>
          {onBenchPlayers?.map(({player}) => (
            <option key={player.id} value={player.id} disabled={Object.keys(playerSelection || "{}").includes(player.id)}>
              {`${captainSelection[player.id]?.role ? `(${captainSelection[player.id]?.role.charAt(0)})` : ''} ${player.name}`}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button className="btn btn-primary btn-sm" disabled={!selectedPlayer} onClick={onDeselectPlayer}><i className="fa fa-redo"></i></button>
      </td>
    </tr>
  );
};

export default ESubstitutionTableRow;
