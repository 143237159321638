import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { BackButton, DatatableCard, Loader } from "../../../../components";
import { getUsers, closeNotify, showPasswordModal } from "../../actions";

const ClubAdmin = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const location = useLocation();

  const club = location.state;

  useEffect(() => {
    dispatch(getUsers("CLUB"));
    return () => dispatch(closeNotify());
  }, [dispatch]);

  const onDelete = (user) => {
    dispatch(showPasswordModal(user, "user"));
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Surname",
      selector: "surname",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Matricule",
      selector: "matricule",
      sortable: true,
    },
    {
      name: "Profession",
      selector: "profession",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <div class="dropdown">
          <button
            class="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-h"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <Link
              to={{
                pathname: `/admin/club-admin-form`,
              }}
              state={row.id}
              className="dropdown-item"
            >
              <span>
                <i className="fa fa-edit mr-1"></i> Edit
              </span>
            </Link>

            <div class="dropdown-divider"></div>
            <Link
              to={{
                pathname: "/admin/club-admin-detail",
              }}
              state={row.id}
              className="dropdown-item"
            >
              <span>
                {" "}
                <i class="fa fa-list mr-1"></i> Details
              </span>
            </Link>
            <div class="dropdown-divider"></div>

            <button
              onClick={() => onDelete(row)}
              className="dropdown-item text-danger"
            >
              <span>
                <i className="fa fa-trash mr-1"></i> Delete
              </span>
            </button>
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: adminState.clubAdmins.filter(({ clubs }) => clubs[0].id === club?.id),
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                STAFF DU CLUB {club ? club.name.toUpperCase() : null}
              </h6>

              <h6
                style={{ float: "right" }}
                className="h2 d-inline-block text-uppercase mb-0"
              >
                <Link
                  to="/admin/club-admin-form"
                  style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                  className="btn btn-sm"
                >
                  <i className="fa fa-plus fa-lg"></i>{" "}
                </Link>
              </h6>
              <div class="dropdown float-right">
                <button
                  class="btn btn-sm"
                  style={{ backgroundColor: "#343a3f", color: "#fff" }}
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-print"></i>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button class="dropdown-item">
                    <span>
                      {" "}
                      <i class="fa fa-eye mr-1"></i> Print preview
                    </span>
                  </button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item">
                    <span>
                      {" "}
                      <i class="fa fa-print mr-1"></i> Print
                    </span>
                  </button>
                </div>
              </div>
              <BackButton />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {adminState.loading ? (
                  <Loader />
                ) : (
                  <DatatableCard tableData={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubAdmin;
