import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute, RestrictedRoute } from "../components";
import Admin from "./admin";
import { getLoggedInUser } from "./admin/actions";
import { NotFound, Signin, Signup } from "./auth/view";
import EScoreSheet from "./eScoreSheet/view/EScoreSheet";
import {ClubAdminForm, ClubForm, CoachFormAdmin, FederationForm, LigueForm, MatchForm, PlayerFormAdmin, RefereeFormAdmin, TournoisForm} from "../screens/admin/forms";
import {Dashboard,Referee,RefereeDetail,Coach,CoachDetail,Player,PlayerDetail, Ligue, LigueDetail, ClubList, ClubAdmin, ClubAdminDetail, Match, MatchDetail, Tournois, Inscrit, InscritDetail, Profile, Notification, Settings, FederationDetail, Federation, RolePermission, Permission, MatriculeCode, Role, Securite} from "../screens/admin/view";

const Screens = () => {

    const adminState = useSelector((state) => state.adminState);
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getLoggedInUser());
    }, [dispatch]);

    const restrictedRoutes = () => {
        if (adminState.loggedInUser.length !== 0) {
          return (
            <>
              {/* <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/securite/e-score-sheet"
                component={EScoreSheet}
              /> */}
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/securite"
                element={<Securite />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/securite/role"
                element={<Role />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/securite/matricule-code"
                element={<MatriculeCode />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/securite/permission"
                element={<Permission />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/securite/role-permission/:roleCode"
                element={<RolePermission />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/federation"
                element={<Federation />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/federation-form/:userId"
                element={<FederationForm />}
              />
              <RestrictedRoute
                user={adminState.loggedInUser}
                roles={["ADMIN"]}
                path="/admin/federation-detail/:userId"
                element={<FederationDetail />}
              />
            </>
          );
        }
      };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/e-score-sheet" element={<EScoreSheet />} />
        <Route
          path={"/admin"}
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        >
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/referee" element={<Referee />} />
        <Route
          path="/admin/referee-detail"
          element={<RefereeDetail />}
        />
        <Route
          path="/admin/referee-form"
          element={<RefereeFormAdmin />}
        />
        <Route path="/admin/coach" element={<Coach />} />
        <Route path="/admin/coach-detail" element={<CoachDetail />} />
        <Route path="/admin/coach-form" element={<CoachFormAdmin />} />
        <Route path="/admin/player" element={<Player />} />
        <Route
          path="/admin/player-form"
          element={<PlayerFormAdmin />}
        />
        <Route
          path="/admin/player-detail"
          element={<PlayerDetail />}
        />
        <Route path="/admin/ligue" element={<Ligue />} />
        <Route path="/admin/ligue-form" element={<LigueForm />} />
        <Route path="/admin/ligue-detail" element={<LigueDetail />} />
        <Route path="/admin/club" element={<ClubList />} />
        <Route path="/admin/club-admin" element={<ClubAdmin />} />
        <Route
          path="/admin/club-admin-detail"
          element={<ClubAdminDetail />}
        />
        <Route
          path="/admin/club-admin-form"
          element={<ClubAdminForm />}
        />
        <Route path="/admin/club-form" element={<ClubForm />} />
        <Route path="/admin/match" element={<Match />} />
        <Route path="/admin/match-form" element={<MatchForm />} />
        <Route path="/admin/match-detail" element={<MatchDetail />} />
        <Route path="/admin/tournois" element={<Tournois />} />
        <Route
          path="/admin/tournois-form"
          element={<TournoisForm />}
        />
        <Route path="/admin/inscrit" element={<Inscrit />} />
        <Route
          path="/admin/inscrit-detail"
          element={<InscritDetail />}
        />
        <Route path="/admin/profile" element={<Profile />} />
        <Route path="/admin/notification" element={<Notification />} />
        <Route path="/admin/settings" element={<Settings />} />
        {/* {restrictedRoutes()} */}

        {/* To be protected */}
        <Route path="/admin/securite" element={<Securite />} />
        <Route path="/admin/securite/role" element={<Role />} />
        <Route path="/admin/securite/matricule-code" element={<MatriculeCode />} />
        <Route path="/admin/securite/permission" element={<Permission />} />
        <Route path="/admin/securite/role-permission/:roleCode" element={<RolePermission />} />
        <Route path="/admin/federation" element={<Federation />} />
        <Route path="/admin/federation-form/:userId" element={<FederationForm />} />
        <Route path="/admin/federation-detail/:userId" element={<FederationDetail />} />
         {/* To be protected */}
            </Route>
        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Screens;
