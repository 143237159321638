import React from 'react';

const SearchBar = () => {

    const styles = {
        ctn: {
            marginBottom: 10, backgroundColor: '#fff',
            width: 280, borderColor: '#19a0c2', borderWidth: 2,
            borderStyle: 'solid', borderRadius: 5
        },
        input: {
            borderStyle: 'none', padding: 4,
            borderRadius: 5, outlineWidth: 0
        },
        button: {
            margin: 0, backgroundColor: '#19a0c2',
            color: '#fff', float: 'right', borderRadius: 0
        }
    }

    return (

        <div style={styles.ctn}>
            <input placeholder="Recherche" style={styles.input} />
            <button style={styles.button} className="btn btn-sm"><i class="fa fa-search"></i></button>
        </div>

    );
}


export default SearchBar;