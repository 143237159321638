import React from "react";

const DropdownUser = ({ title, options, selected, ...other_props }) => {
  return (
    <div class="col-lg-6">
      <div class="form-group">
        <label for={title}>{title}</label>
        {other_props.required ? <span class="text-danger ml-1">*</span> : null}
        <select class="browser-default custom-select" {...other_props}>
          <option value="" disabled selected>
            Choose an option
          </option>

          {options.map((e, index) => {
            return (
              <option
                key={index}
                value={e.id}
                disabled={selected?.includes(e.id)}
              >
                {e.name} {e.surname}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default DropdownUser;
