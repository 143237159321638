import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import { deleteClub, deleteMatch, deleteTournois, deleteUser, hidePasswordModal } from '../screens/admin/actions';
import ModalFooter from './ModalFooter';


const PasswordModal = (props) => {

    const adminState = useSelector((state) => state.adminState);
    const dispatch = useDispatch();

    const [pwd, setPwd] = useState('');

    const close = () => {
        dispatch(hidePasswordModal())
        setPwd('')
    }

    const deleteSelected = () => {

        switch (adminState.deleteSource) {
            case 'club':
                dispatch(deleteClub(adminState.deleteItemId.id, pwd))
                break;
            case 'user':
                dispatch(deleteUser(adminState.deleteItemId.id, pwd, adminState.deleteItemId._role.code, false))
                break;
            case 'inscrit':
                dispatch(deleteUser(adminState.deleteItemId.id, pwd, adminState.deleteItemId._role.code, true))
                break;
            case 'tournois':
                dispatch(deleteTournois(adminState.deleteItemId.id, pwd))
                break;
            case 'match':
                dispatch(deleteMatch(adminState.deleteItemId.id, pwd))
                break;

            default:
                break;
        }

        setPwd('')
        dispatch(hidePasswordModal())
    }

    const handleInputChange = e => {
        switch (e.target.id) {
            case 'p_text':
                setPwd(e.target.value)
                break;

            default:
                break;
        }

    }


    return (
        <Modal centered show={adminState.passwordModal} onHide={() => dispatch(hidePasswordModal())}>

            <Modal.Body style={{ textAlign: 'center' }}>

                <p>Pour des raisons de sécurité veuillez saisir votre mot de passe pour confirmer la suppression de: <b style={{ color: 'firebrick', fontWeight: 'bold' }}>{adminState.deleteItemId.name}</b></p>

                <input placeholder="Mot de passe" type='password' id="p_text" onChange={handleInputChange} className='form-control' value={pwd} />

                <div style={{ marginTop: 10 }}>
                    <button style={{ backgroundColor: 'firebrick', color: '#fff' }} disabled={pwd === '' ? true : false} onClick={() => deleteSelected()} class="btn">Confirmer la suppression</button>
                    <button class="btn" onClick={() => close()} >Close</button>
                    {/* <button class="btn" style={{ backgroundColor: '#00387B', color: '#fff' }} onClick={() => signout()} >Connexion</button> */}
                </div>

            </Modal.Body>

            <ModalFooter />

        </Modal>
        // <div>
        //     <button id="passwordModal" data-toggle="modal" data-target="#pwModal" hidden></button>

        //     <div class="modal fade" id="pwModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        //         <div class="modal-dialog modal-dialog-centered" role="document">
        //             <div class="modal-content">

        //                 <PasswordForm dispatch={dispatch} deleteItem={adminState.deleteItemId} deleteSource={adminState.deleteSource} />
        //                 <ModalFooter />
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );

}

export default PasswordModal