import React from 'react';

const InputText = ({ title = 'Title', type = 'text', error, ...other_props }) => {

    return (

        <div class="col-lg-4 mt-3">
            <div class="form-group">
                <label for={title}>{title}</label>{other_props.required ? <span className="text-danger ml-1">*</span> : null}
                <input className={error ? 'form-control is-invalid' : 'form-control'} type={type} {...other_props} />
                {error && <small className="text-danger ml-1">{error}</small>}
            </div>
        </div>

    );
}


export default InputText;