import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  InputTextLarge,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { saveTournois, updateTournois } from "../actions";

const TournoisForm = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      tournoisInitializer(location.state);
    }
  }, [location.state]);

  const tournoisInitializer = (tournois) => {
    setId(tournois.id);
    setName(tournois.name);
    setSalle(tournois.salle);
    setStart(tournois.start);
    setEnd(tournois.end);
    setOrganizer(tournois.organizer);
    if (tournois.auxiliaire) {
      setNormal(false);
      setAuxiliaire(true);
    } else {
      setNormal(true);
      setAuxiliaire(false);
    }
  };

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [salle, setSalle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [normal, setNormal] = useState(true);
  const [auxiliaire, setAuxiliaire] = useState(false);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "salle":
        setSalle(e.target.value);
        break;
      case "start":
        setStart(e.target.value);
        break;
      case "end":
        setEnd(e.target.value);
        break;
      case "organizer":
        setOrganizer(e.target.value);
        break;
      case "normal":
        setNormal(true);
        setAuxiliaire(false);
        break;
      case "auxiliaire":
        setNormal(false);
        setAuxiliaire(true);
        break;

      default:
        break;
    }
  };

  const callback = () => {
    navigate("tournois");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let tournois = {
      id,
      name,
      salle,
      start,
      end,
      organizer,
    };

    if (normal) {
      tournois = {
        ...tournois,
        auxiliaire: false,
      };
    } else {
      tournois = {
        ...tournois,
        auxiliaire: true,
      };
    }

    id === ""
      ? dispatch(saveTournois(tournois, callback))
      : dispatch(updateTournois(tournois, callback));
  };

  return (
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-12">
              <h6 class="h2 d-inline-block text-uppercase mb-0">
                <span class="">
                  <i class="fa fa-plus fa-lg mr-3"></i>
                </span>
                {location.state === undefined ? "ADD" : "EDIT"} A TOURNAMENT
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div class="row">
              <div class="col-md-12">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={name}
                        required
                      />
                      <InputTextLarge
                        title="Organizer"
                        name="organizer"
                        id="organizer"
                        onChange={handleInputChange}
                        value={organizer}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Start date"
                        type="date"
                        name="start"
                        id="start"
                        onChange={handleInputChange}
                        value={start}
                      />

                      <InputTextLarge
                        title="End date"
                        type="date"
                        name="end"
                        id="end"
                        onChange={handleInputChange}
                        value={end}
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Salle"
                        name="salle"
                        id="salle"
                        onChange={handleInputChange}
                        value={salle}
                        required
                      />

                      <div className="col-lg-6">
                        <div class="form-group">
                          <label for="test">
                            {"Normal or Auxiliary Tournament ?"}
                          </label>{" "}
                          <span class="text-danger ml-1">*</span>
                          <br />
                          <Checkbox
                            title="Normal"
                            id="normal"
                            onChange={handleInputChange}
                            checked={normal}
                          />
                          <Checkbox
                            title="Auxiliary"
                            id="auxiliaire"
                            onChange={handleInputChange}
                            checked={auxiliaire}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!adminState.loading ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TournoisForm;
