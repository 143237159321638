import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DatatableCard, Loader } from "../../../components";
import ModalFooter from "../../../components/ModalFooter";
import { hasPermission } from "../../../utilities/auth";
import {
  getInscrits,
  updateUser,
  closeNotify,
  showPasswordModal,
  getMatriculeCodes,
} from "../actions";

const Inscrit = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInscrits());
    dispatch(getMatriculeCodes());
    return () => dispatch(closeNotify());
  }, [dispatch]);

  const [matriculeCode, setMatriculeCode] = useState("");
  const [user, setUser] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);

  const onGrant = (user) => {
    user["state"] = true;
    setUser(user);
    setModalVisibility(true);
  };

  const callback = () => {
    navigate("inscrit");
  };

  const validateRegistration = () => {
    user["matriculeCode"] = matriculeCode;
    setModalVisibility(false);
    dispatch(updateUser(user, callback));
  };

  const onDelete = (user) => {
    dispatch(showPasswordModal(user));
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Surname",
      selector: "surname",
      sortable: true,
    },
    {
      name: "Place of birth",
      selector: "pob",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Profession",
      selector: "profession",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <Link
          to={{
            pathname: "/admin/inscrit-detail/",
          }}
          state={row.id}
          className="btn btn-sm"
        >
          <i className="fa fa-list"></i>
        </Link>
      ),
    },
    {
      name: "",
      selector: "name",
      cell: (row, index, column, selector) =>
        adminState.loggedInUser.length !== 0
          ? hasPermission(adminState.loggedInUser, [
              "VALIDATE_REGISTRATION",
            ]) && (
              <button
                style={{ margin: 0 }}
                onClick={() => onGrant(row)}
                className="btn btn-sm btn-primary"
              >
                <i className="fa fa-check"></i>
              </button>
            )
          : null,
    },
    {
      name: "",
      selector: "name",
      cell: (row, index, column, selector) =>
        adminState.loggedInUser.length !== 0
          ? hasPermission(adminState.loggedInUser, ["DELETE_REGISTRATION"]) && (
              <button
                style={{ margin: 0 }}
                onClick={() => onDelete(row)}
                className="btn btn-sm btn-danger"
              >
                <i className="fa fa-times"></i>
              </button>
            )
          : null,
    },
  ];

  const tableData = {
    columns,
    data: adminState.inscrits,
  };

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "matriculeCode":
        setMatriculeCode(e.target.value);
        break;

      default:
        break;
    }
  };

  const renderMatriculeCodes = () => {
    return (
      <div class="col-lg-12">
        <div class="form-group">
          <label for="matriculeCode">Code Matricule</label>{" "}
          <span class="text-danger ml-1">*</span>
          <select
            class="browser-default custom-select"
            name="matriculeCode"
            id="matriculeCode"
            onChange={handleInputChange}
            value={matriculeCode}
          >
            <option value="" disabled selected>
              Choose an option
            </option>
            {adminState.matriculeCodes.map((e) => {
              return <option value={e.code}>{e.code}</option>;
            })}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                LIST OF ENROLLED
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {adminState.loading ? (
                  <Loader />
                ) : (
                  <DatatableCard tableData={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        show={modalVisibility}
        onHide={() => setModalVisibility(false)}
      >
        <Modal.Body>
          <p>Please choose a code to validate the registration</p>
          <hr style={{ margin: 5, marginBottom: 10 }} />

          <div class="row">
            {adminState.matriculeCodes.length !== 0
              ? renderMatriculeCodes()
              : null}
          </div>

          <div style={{ marginTop: 10, textAlign: "center" }}>
            <button
              disabled={matriculeCode === "" ? true : false}
              onClick={() => validateRegistration()}
              class="btn btn-primary"
            >
              Validate registration
            </button>
            <button class="btn" onClick={() => setModalVisibility(false)}>
              Close
            </button>
          </div>
        </Modal.Body>

        <ModalFooter />
      </Modal>
    </div>
  );
};

export default Inscrit;
