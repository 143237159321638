import React from 'react';

const DetailCardItem = ({ title = 'Title', date = 'Date', place = 'Place', instructor = 'Instructor', observation = 'Observation' }) => {

    return (

        <div>

            <div style={{ backgroundColor: '#11213A', padding: 8, color: '#fff', textAlign: 'start', alignItems: 'center' }}>
                <h6>{title}</h6>
            </div>

            <div class="row" style={{ marginTop: 20, marginBottom: 20 }}  >
                <div class="col-md-3">
                    <h6 style={{fontSize: 13}}>Date obtained:</h6>
                    <b>{date}</b>
                </div>
                <div class="col-md-3">
                    <h6 style={{fontSize: 13}}>Location: </h6>
                    <b>{place}</b>
                </div>
                <div class="col-md-3">
                    <h6 style={{fontSize: 13}}>Instructor: </h6>
                    <b>{instructor}</b>
                </div>
                <div class="col-md-3">
                    <h6 style={{fontSize: 13}}>Observation: </h6>
                    <b>{observation}</b>
                </div>
            </div>

        </div>

    );
}


export default DetailCardItem;