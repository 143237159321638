import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  DatatableCard,
  Dropdown,
  Loader,
} from "../../../../components";
import {
  closeNotify,
  getClubs,
  getClubsAuxiliaire,
  showPasswordModal,
  getClubsByLigue,
  getUsers,
} from "../../actions";
import { DropdownConstants } from "../../../../utilities";
import { hasPermission } from "../../../../utilities/auth";

const ClubList = (props) => {
  const userRole = localStorage.getItem("fvApp");

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClubs());
    dispatch(getUsers("CLUB"));
    return () => dispatch(closeNotify());
  }, [dispatch]);

  const goToAdministration = (club) => {
    navigate("/admin/club-admin", { state: club });
  };

  const onEdit = (club) => {
    navigate("/admin/club-form", { state: club });
  };

  const onDelete = (clubId) => {
    dispatch(showPasswordModal(clubId, "club"));
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Picture",
      cell: (row) => (
        <img
          src={row.picture || "/assets/images/no-image.png"}
          alt="logo"
          className="rounded"
          width="50"
          height="50"
        />
      ),
    },
    // {
    //   name: "League",
    //   selector: "league",
    //   sortable: true,
    // },
    {
      name: "Code",
      selector: "code",
      sortable: true,
    },
    {
      name: "Type",
      selector: "typeClub",
      sortable: true,
    },
    {
      name: "Region",
      selector: "_region.name",
      sortable: true,
    },
    {
      name: "Staff",
      cell: (row, index, column, selector) => (
        <span>
          {adminState.clubAdmins.filter(({ clubs }) => clubs[0].id === row.id)
            .length === 0
            ? "No Staff"
            : adminState.clubAdmins.filter(
                ({ clubs }) => clubs[0].id === row.id
              ).length}
        </span>
      ),
    },
    {
      cell: (row, index, column, selector) => (
        <div class="dropdown">
          <button
            class="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-h"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              onClick={() => goToAdministration(row)}
              className="dropdown-item"
            >
              <span>
                <i className="fa fa-list mr-1"></i> Staff
              </span>
            </button>

            <div class="dropdown-divider"></div>
            {adminState.loggedInUser.length !== 0
              ? hasPermission(adminState.loggedInUser, ["EDIT_TEAM"]) && (
                  <button onClick={() => onEdit(row)} className="dropdown-item">
                    <span>
                      <i className="fa fa-edit mr-1"></i> Edit
                    </span>
                  </button>
                )
              : null}
            <div class="dropdown-divider"></div>
            {adminState.loggedInUser.length !== 0
              ? hasPermission(adminState.loggedInUser, ["DEACTIVATE_TEAM"]) && (
                  <button
                    onClick={() => onDelete(row)}
                    className="dropdown-item text-danger"
                  >
                    <span>
                      <i className="fa fa-trash mr-1"></i> Delete
                    </span>
                  </button>
                )
              : null}
          </div>
        </div>
      ),
    },
  ];

  const handleFilterChange = (e) => {
    if (e.target.id === "auxiliaireFiltre") {
      switch (e.target.value) {
        case "Afficher Tout":
          dispatch(getClubs());
          break;
        case "Afficher Normal":
          dispatch(getClubsAuxiliaire(false));
          break;
        case "Afficher Auxiliaire":
          dispatch(getClubsAuxiliaire(true));
          break;

        default:
          dispatch(getClubs());
          break;
      }
    } else if (e.target.id === "ligueFiltre") {
      dispatch(getClubsByLigue(e.target.value));
    }
  };

  const tableData = {
    columns,
    data: adminState.clubs,
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                CLUBS LIST
              </h6>
              {/* <h6
                style={{ float: "right" }}
                className="h2 d-inline-block text-uppercase mb-0"
              >
                <Link
                  to="/admin/club-admin"
                  style={{ backgroundColor: "#11213a", color: "#fff" }}
                  className="btn btn-sm"
                >
                  {" "}
                  ADMINISTRATION
                </Link>
              </h6> */}
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, ["ADD_TEAM"]) && (
                    <h6
                      style={{ float: "right" }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/club-form"
                        style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{" "}
                      </Link>
                    </h6>
                  )
                : null}
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, ["PRINT_TEAM"]) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: "#343a3f", color: "#fff" }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-eye mr-1"></i> Preview print
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-print mr-1"></i> Print
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div
            className="row"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <Dropdown
              title="Filter"
              options={DropdownConstants.AUXILIAIRE_FILTER}
              id="auxiliaireFiltre"
              onChange={handleFilterChange}
            />
            {userRole === "15sdf#4%$#sd#@_)54" ? (
              <Dropdown
                title="Filter by league"
                options={DropdownConstants.LEAGUE}
                id="ligueFiltre"
                onChange={handleFilterChange}
              />
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {adminState.loading ? (
                  <Loader />
                ) : (
                  <DatatableCard tableData={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubList;
