import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import EManualChangeBox from "./EManualChangeBox";

const EManualChangeModal = ({ handleClose, show }) => {
  const [manualChanges, setManualChanges] = useState({
    captainChange: false,
    liberoPopup: false,
    checkAccidents: false,
    timeouts: false,
    numPlayers: 0,
    scoreTimer: false,
    shareData: false,
  });

  const onSave = () => {
    localStorage.setItem("fcvbManualChanges", JSON.stringify(manualChanges));
    handleClose();
  };

  useEffect(() => {
    const mc = localStorage.getItem('fcvbManualChanges')
    if(mc){
      setManualChanges(JSON.parse(mc))
    }
  }, []);

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>MANUAL CHANGES</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>GENERAL</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox
                preTitle={"Captain"}
                title="change of captain on court"
                checked={manualChanges.captainChange}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, captainChange: val })
                }
              />
              <EManualChangeBox
                preTitle={"Libero"}
                title="pop up for libero replacement after rotation"
                checked={manualChanges.liberoPopup}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, liberoPopup: val })
                }
              />
              <EManualChangeBox
                preTitle={"Check Accidents"}
                title="report cases of accidents or injuries"
                checked={manualChanges.checkAccidents}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, checkAccidents: val })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>TOURNAMENT</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox
                preTitle={"Timeouts"}
                title="play with technical timeouts"
                checked={manualChanges.timeouts}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, timeouts: val })
                }
              />
              <EManualChangeBox
                title="Number of players authorize"
                showText
                checked={manualChanges.numPlayers}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, numPlayers: val })
                }
              />
              <EManualChangeBox
                preTitle={"Score timer"}
                title="enable 15s timer between 2 points"
                checked={manualChanges.scoreTimer}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, scoreTimer: val })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>SHORTCUTS</Accordion.Header>
            <Accordion.Body>
              List of shortcuts to perform different actions
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>SHARE DATA</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox
                title="Share information with other federations (FIVB included)"
                checked={manualChanges.shareData}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, shareData: val })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="4">
            <Accordion.Header>VCS</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox title="Enable Video Challenge System" />
              <EManualChangeBox title="Configure IP address for information exchange" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>TABLET</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox title="Enable PoE tablets" />
              <EManualChangeBox title="Configure IP address, Port and password" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>VIS</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox title="Configure access to Video Information System(login,IP,password,connection)" />
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            style={{ backgroundColor: "#00a1ff", color: "white" }}
          >
            Save
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EManualChangeModal;
