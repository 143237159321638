import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DatatableCard,
  Dropdown,
  Loader,
  PlayerTransferModal,
} from "../../../components";
import { hasPermission } from "../../../utilities/auth";
import {
  getUsers,
  closeNotify,
  showPasswordModal,
  printUsers,
  getClubs,
} from "../actions";

const Player = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  const [players, setPlayers] = useState();
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [clubId, setClubId] = useState("");
  const [user, setUser] = useState("");
  // const [perPage, setPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(8);
  // const [totalRows, setTotalRows] = useState(0);

  const transferModal = (id, user) => {
    setShowTransferModal(true);
    setClubId(id);
    setUser(user);
  };

  useEffect(() => {
    dispatch(getUsers("JOUER"));
    // dispatch(getUsers('JOUER',currentPage,perPage));
    dispatch(getClubs());
    return () => dispatch(closeNotify());
  }, [dispatch]);

  // const handlePageChange = page => {
  //   dispatch(getUsers('JOUER', page, perPage));
  //   setCurrentPage(page);
  // };

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   dispatch(getUsers('JOUER', page, newPerPage));
  //   setPerPage(newPerPage);
  // };

  const onDelete = (user) => {
    dispatch(showPasswordModal(user, "user"));
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>
    },
    {
      name: "Name",
      cell: (row, index, column, selector) => (<span>{row.name} {row.surname}</span>)
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Matricule",
      selector: "matricule",
      sortable: true,
    },
    {
      name: "Club",
      selector: "clubs[0].name",
      sortable: true,
    },
    {
      name: "Dossard",
      selector: "dossard",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <div className="dropdown">
          <button
            className="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-h"></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            {adminState.loggedInUser.length !== 0
              ? hasPermission(adminState.loggedInUser, ["EDIT_PLAYER"]) && (
                  <Link
                    to={{
                      pathname: `/admin/player-form/`,
                    }}
                    state={row.id}
                    className="dropdown-item"
                  >
                    <span>
                      <i className="fa fa-edit mr-1"></i> Edit
                    </span>
                  </Link>
                )
              : null}
            <div className="dropdown-divider"></div>
            <Link
              to={{
                pathname: "/admin/player-detail",
              }}
              state={row.id}
              className="dropdown-item"
            >
              <span>
                {" "}
                <i className="fa fa-list mr-1"></i> Details
              </span>
            </Link>
            <div className="dropdown-divider"></div>
            <button
              className="dropdown-item"
              onClick={() => transferModal(row.clubs[0].id, row)}
            >
              <span>
                {" "}
                <i className="fa fa-exchange-alt mr-1"></i> Transfer
              </span>
            </button>
            <div className="dropdown-divider"></div>
            {adminState.loggedInUser.length !== 0
              ? hasPermission(adminState.loggedInUser, [
                  "DEACTIVATE_PLAYER",
                ]) && (
                  <button
                    onClick={() => onDelete(row)}
                    className="dropdown-item text-danger"
                  >
                    <span>
                      <i className="fa fa-trash mr-1"></i> Delete
                    </span>
                  </button>
                )
              : null}
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: players || adminState.players,
  };

  const handleFilterChange = (e) => {
    if (e.target.value !== "") {
      let filtered = [];

      adminState.players.forEach((player) => {
        player.clubs.forEach((club) => {
          if (club.id === e.target.value) {
            filtered.push(player);
          }
        });
      });

      setPlayers(filtered);
    } else {
      //dispatch(getUsers('JOUER'))
      setPlayers(adminState.players);
    }

    // dispatch(getClubs(e.target.value));
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                PLAYERS LIST
              </h6>
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, ["ADD_PLAYER"]) && (
                    <h6
                      style={{ float: "right" }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/player-form"
                        style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg "></i>{" "}
                      </Link>
                    </h6>
                  )
                : null}
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, ["PRINT_PLAYER"]) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: "#343a3f", color: "#fff" }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-eye mr-1"></i> Preview print
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button
                          class="dropdown-item"
                          onClick={() => dispatch(printUsers("JOUER"))}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-print mr-1"></i> Print
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div
            className="row"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            {adminState.clubs.length !== 0 ? (
              <Dropdown
                title="Filter by club"
                options={adminState.clubs}
                dynamic={true}
                onChange={handleFilterChange}
                showAll
              />
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {adminState.loading ? (
                  <Loader />
                ) : (
                  <DatatableCard tableData={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlayerTransferModal
        show={showTransferModal}
        clubId={clubId}
        onClose={() => setShowTransferModal(false)}
        user={user}
      />
    </div>
  );
};

export default Player;
