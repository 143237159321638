import React from 'react';

const DropdownLarge = ({ title, options = [1, 2, 3], dynamic = false,
    error, ...other_props }) => {

    return (

        <div class="col-lg-6">
            <div class="form-group">
                <label for={title}>{title}</label>{other_props.required ? <span class="text-danger ml-1">*</span> : null}
                <select className={error ? 'browser-default custom-select is-invalid' : 'browser-default custom-select'} {...other_props} >
                    <option value="" disabled selected>Choose an option</option>
                    {dynamic ? options.map((e,index) => { return <option key={index} value={e.id}>{e.name}</option> }) :
                        options.map((e,index) => { return <option key={index} value={e}>{e}</option> })}

                </select>
                {error && <small className="text-danger ml-1">{error}</small>}
            </div>
        </div>

    );
}


export default DropdownLarge;