import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";
import {
  BackButton,
  CardRow,
  EmptyDetailCard,
} from "../../../components";
import { getUser } from "../apis";

const ClubAdminDetail = (props) => {
  const location = useLocation();
  const [user, setUser] = useState({});
 
  useEffect(() => {
    getUser(location.state).then((response) => {
      setUser(response.data);
    });
  }, [location.state]);

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span>
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                CLUB MEMBER DETAIL: {user.id ? user?.clubs[0]?.name : null}
              </h6>
           <BackButton />
            </div>
          </div>

          {user.id ? (
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card shadow mb-25 bg-white rounded my-3">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img
                        src={user.picture || "/assets/images/profile.png"}
                        alt="Admin"
                        className="rounded"
                        width="150"
                        height="150"
                      />
                      <div className="mt-3">
                        <h4>{`${user.name} ${user.surname}`}</h4>

                        <p className="font-size-sm mb-0">
                          {user.matricule === null ? "" : user.matricule}
                        </p>
                        <p className="font-size-sm">
                          {user._subRole === null ? "" : user._subRole.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow mb-25 bg-white rounded my-3">
                  <div className="card-body">
                    <CardRow
                      title="League"
                      value={user.league}
                      title2="Region"
                      value2={user._region === null ? "" : user._region.name}
                    />
                    <CardRow
                      title="Departement"
                      value={
                        user._departement === null ? "" : user._departement.name
                      }
                      title2="District"
                      value2={
                        user._arrondissement === null
                          ? ""
                          : user._arrondissement.name
                      }
                      showHR={false}
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-8">
                <div className="card mb-3 shadow bg-white rounded my-3">
                  <div className="card-body">
                    <CardRow
                      title="Name"
                      value={user.name}
                      title2="Surname"
                      value2={user.surname}
                    />
                    <CardRow
                      title="Date of birth"
                      value={user.dob}
                      title2="Place of birth"
                      value2={user.pob}
                    />
                    <CardRow
                      title="Marital status"
                      value={user.familyStatus}
                      title2="Gender"
                      value2={user.gender}
                    />
                    <CardRow
                      title="Email"
                      value={user.email}
                      showHR={false}
                      title2="Phone number"
                      value2={user.phone}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="card mb-3 shadow bg-white rounded my-3">
                      <div className="card-body">
                        <CardRow
                          title="Profession"
                          value={user.profession}
                          title2="Role"
                          value2={
                            user._subRole === null ? "" : user._subRole.name
                          }
                        />
                        <CardRow
                          title="Matricule"
                          value={user.matricule === null ? "" : user.matricule}
                          title2="Clubs"
                          value2={user.clubs.map((e) => (
                            <b>{e.name}</b>
                          ))}
                        />
                        <CardRow
                          title="Education level"
                          value={user.schoolLevel}
                          title2="Creation date"
                          value2={
                            <Moment format="DD/MM/YYYY">
                              {user.creationDate}
                            </Moment>
                          }
                          showHR={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EmptyDetailCard />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubAdminDetail;
