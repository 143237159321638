import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './';
import adminReducer from '../screens/admin/reducers';


const rootReducer = combineReducers({
    adminState: adminReducer,
});

const store = () => {

    const sagaMiddleWare = createSagaMiddleware();

    return {
        ...createStore(rootReducer, applyMiddleware(sagaMiddleWare)),
        runSaga: sagaMiddleWare.run(rootSaga)
    }

}

export default store;