import React from 'react';
import Modal from "react-bootstrap/Modal";

const ModalFooter = () => {

    return (

        <Modal.Footer style={{ justifyContent: 'space-between' }}>
           
                    <a href="https://cybexai.com" target="_blank" rel="noopener noreferrer"><img src='/assets/images/cybex_logo.png' height='30' alt="cybexai" /></a>
               
                    <a href="http://www.fecavolley.net" target="_blank" rel="noopener noreferrer"><img src='/assets/images/fecavolley.png' height='30' alt="fecavolley" /><span class="text-dark ml-2">FECAVOLLEY</span></a>
                
            </Modal.Footer>

        // <div class="card-footer" >
        //     <div className="row" style={{ justifyContent: 'space-between' }}>
        //         <div >
        //             <a href="https://cybexai.com" class="font-weight-bold" target="_blank"><img src='/assets/images/cybex_logo.png' height='30' /></a>
        //         </div>

        //         <div >
        //             <a href="http://www.fecavolley.net" class="font-weight-bold" target="_blank"><img src='/assets/images/fecavolley.png' height='30' /><span class="text-dark ml-2">FECAVOLLEY</span></a>
        //         </div>

        //     </div>
        // </div>

    );

}


export default ModalFooter