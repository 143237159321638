import React from "react";

const EManualChangeBox = ({
  preTitle,
  title,
  showText = false,
  checked,
  onChange,
}) => {
  return (
    <div className="form-check form-check-inline mb-2">
      <input
        className="form-check-input"
        type="checkbox"
        id={title}
        value="option1"
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
      />
      <label className="form-check-label" for={title}>
        <small>
          {preTitle ? <b>{preTitle}: </b> : null}
          {title}
        </small>
      </label>
      {showText && (
        <input
          className="form-check-input w-25 ml-3"
          type="number"
          value={checked}
          onChange={(event) => onChange(event.target.value)}
        />
      )}
    </div>
  );
};

export default EManualChangeBox;
