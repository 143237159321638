import * as Yup from "yup";
import moment from "moment";

export const matchYupuserYup = {
  name: Yup.string().required("Invalid Name"),
  surname: Yup.string().required("Invalid Surname"),
  dob: Yup.string()
    .test(function (value) {
      if (
        moment().diff(moment(value), "years") < 3 ||
        moment().diff(moment(value), "years") > 99
      ) {
        return this.createError({
          mesage: "You must be between 3 and 99 years old.",
        });
      } else {
        return true;
      }
    })
    .required("You must be between 3 and 99 years old."),
  pob: Yup.string().required("Invalid Place of Birth"),
  gender: Yup.string().required("Invalid Gender"),
  auxiliaire: Yup.boolean().required("Invalid User Type"),
  matricule: Yup.string().required("Invalid Matricule Code"),
  _club: Yup.string().required("Invalid Club"),
  _region: Yup.string().required("Invalid Region"),
  _departement: Yup.string().required("Invalid Departement"),
  _arrondissement: Yup.string().required("Invalid District"),
  familyStatus: Yup.string().required("Invalid Family Status"),
  // profession: Yup.string()
  //     .required("Invalid Profession"),
  schoolLevel: Yup.string().required("Invalid School Level"),
  league: Yup.string().required("Invalid League"),
  // phone: Yup.string()
  //     .required("Invalid Phone Number"),
  dossard: Yup.string().required("Invalid Dossard Number"),
  // email: Yup.string().email()
  //     .required("Invalid Email"),
};

export const signinYup = {
  pwd: Yup.string().required("Password Required"),
  email: Yup.string().email().required("Email Required"),
};

export const matchYup = {
  _tournois: Yup.string().required("Tournament Required"),
  _participantOne: Yup.string().required("Team 1 Required"),
  _participantTwo: Yup.string().required("Team 2 Required"),
  dateMatch: Yup.string().required("Date of Match Required"),
  timeMatch: Yup.string().required("Time of Match Required"),
  hall: Yup.string().required("Hall Required"),
  numSpectators: Yup.number().required("Number of spectators Required"),
  division: Yup.string().required("Division Required"),
  category: Yup.string().required("Category Required"),
  _refereePremier: Yup.string().required("Referee (First) Required"),
  _refereeSecond: Yup.string().required("Referee (Second) Required"),
  _refereeScorer: Yup.string().required("Referee (Scorer) Required"),
  _jugeOne: Yup.string().required("Judge 1 Required"),
  _jugeTwo: Yup.string().required("Judge 2 Required")
};
