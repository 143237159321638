import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ModalFooter from "./ModalFooter";
import { getClubs, savePlayerTransfer } from "../screens/admin/actions";
import DropdownLarge from "./DropdownLarge";

const PlayerTransferModal = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClubs());
  }, []);

  const [to, setTo] = useState("");

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "to":
        setTo(e.target.value);
        break;

      default:
        break;
    }
  };

  const transfer = () => {
    let playerTransfer = {
      to,
      from: props.clubId,
      player: props.user.id,
    };
    dispatch(savePlayerTransfer(playerTransfer));
    props.onClose();
  };

  return (
    <Modal centered show={props.show}>
      <Modal.Body>
        <h1 className="mb-5 text-center">
          Transfert du joueur: {`${props.user.name} ${props.user.surname}`}
        </h1>

        <div className="row mb-3">
          <DropdownLarge
            title="De"
            options={adminState.clubs}
            dynamic={true}
            value={props.clubId}
            disabled
          />
          <DropdownLarge
            title="A"
            options={adminState.clubs}
            dynamic={true}
            name="to"
            id="to"
            onChange={handleInputChange}
            value={to}
          />
        </div>

        <div className="text-center">
          <button className="btn" onClick={props.onClose}>
            Close
          </button>
          <button
            style={{ backgroundColor: "#00387B", color: "#fff" }}
            className="btn"
            onClick={() => transfer()}
          >
            Transférer
          </button>
        </div>
      </Modal.Body>

      <ModalFooter />
    </Modal>
  );
};

export default PlayerTransferModal;
