import React from 'react';

const EmptyDetailCard = () => {

    return (

        <div style={{ textAlign: 'center', padding: 200, justifyContent: 'center' }}>

            <div className='card' >

                <div className='card-body'>

                    <img src='/assets/images/tap.png' style={{ width: 100, height: 100, marginBottom: 20 }} alt="Select" />

                    <h1>An item must be selected</h1>
                </div>

            </div>

        </div>
    );
}


export default EmptyDetailCard;