import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DatatableCard, Loader } from "../../../components";
import { hasPermission } from "../../../utilities/auth";
import { getUsers, closeNotify, showPasswordModal } from "../actions";

const Referee = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers("ARBIT"));
    return () => dispatch(closeNotify());
  }, []);

  const onDelete = (user) => {
    dispatch(showPasswordModal(user, "user"));
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Surname",
      selector: "surname",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Matricule",
      selector: "matricule",
      sortable: true,
    },
    {
      name: "Profession",
      selector: "profession",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <div class="dropdown">
          <button
            class="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-h"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            {adminState.loggedInUser.length !== 0
              ? hasPermission(adminState.loggedInUser, ["EDIT_REFEREE"]) && (
                  <Link
                    to={{ pathname: `/admin/referee-form/` }}
                    className="dropdown-item"
                    state={row.id}
                  >
                    <span>
                      <i className="fa fa-edit mr-1"></i> Edit
                    </span>
                  </Link>
                )
              : null}
            <div class="dropdown-divider"></div>
            <Link
              to={{
                pathname: "/admin/referee-detail/",
              }}
              state={row.id}
              className="dropdown-item"
            >
              <span>
                <i class="fa fa-list mr-1"></i> Details
              </span>
            </Link>
            <div class="dropdown-divider"></div>
            {adminState.loggedInUser.length !== 0
              ? hasPermission(adminState.loggedInUser, [
                  "DEACTIVATE_REFEREE",
                ]) && (
                  <button
                    onClick={() => onDelete(row)}
                    className="dropdown-item text-danger"
                  >
                    <span>
                      <i className="fa fa-trash mr-1"></i> Delete
                    </span>
                  </button>
                )
              : null}
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: adminState.referees,
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                REFEREE LIST
              </h6>
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, ["ADD_REFEREE"]) && (
                    <h6
                      style={{ float: "right" }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/referee-form"
                        style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{" "}
                      </Link>
                    </h6>
                  )
                : null}
              {adminState.loggedInUser.length !== 0
                ? hasPermission(adminState.loggedInUser, ["PRINT_REFEREE"]) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: "#343a3f", color: "#fff" }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-eye mr-1"></i> Preview print
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item">
                          <span>
                            {" "}
                            <i class="fa fa-print mr-1"></i> Print
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {adminState.loading ? (
                  <Loader />
                ) : (
                  <DatatableCard tableData={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referee;
