import { fork, all } from 'redux-saga/effects';
import { adminWatchers } from '../screens/admin/sagas';
import { authWatchers } from '../screens/auth/sagas';

export default function* rootSaga() {

    yield all([
        ...Object.values(adminWatchers),
        ...Object.values(authWatchers),
    ].map(fork));

}