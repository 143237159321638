import React from "react";

const Injury = ({ side, onClick }) => (
  <div class={`action time-out injury-${side}`}>
    <div class="label">Injury</div>
  </div>
);

const Timeout = ({ side, onClick }) => (
  <div class={`action time-out time-out-${side}`}>
    <div class="label">Time-out</div>
    <div class="figures">11</div>
  </div>
);

const Libero = ({ side, onClick }) => (
  <div class={`action substitution libero-${side}`}>
    <div class="label">Libero</div>
  </div>
);

const Substitution = ({ side, onClick }) => (
  <div class={`action substitution substitution-${side}`}>
    <div class="label">Substitution</div>
    <div class="figures">11</div>
  </div>
);

const EBottomAction = ({
  side = "left",
  timeout = 10,
  substitution,
  substitutionClick,
  timeoutClick,
  liberoClick,
  injuryClick,
}) => {
  return side === "left" ? (
    <div class="bottom-actions left-actions">
      <div class="action time-out injury-left" onClick={injuryClick}>
        <div class="label">Injury</div>
      </div>
      <div class="action time-out time-out-left" onClick={timeoutClick}>
        <div class="label">Time-out</div>
        <div class="figures">{timeout}</div>
      </div>
      <div class="action substitution libero-left" onClick={liberoClick}>
        <div class="label">Libero</div>
      </div>
      <div class="action substitution substitution-left" onClick={substitutionClick}>
        <div class="label">Substitution</div>
        <div class="figures">{substitution}</div>
      </div>
    </div>
  ) : (
    <div class="bottom-actions right-actions">
      <div class="action time-out time-out-right" onClick={timeoutClick}>
        <div class="figures">{timeout}</div>
        <div class="label">Time-out</div>
      </div>
      <div class="action time-out injury-right" onClick={injuryClick}>
        <div class="label">Injury</div>
      </div>
      <div class="action substitution substitution-right" onClick={substitutionClick}>
        <div class="figures">{substitution}</div>
        <div class="label">Substitution</div>
      </div>
      <div class="action substitution libero-right" onClick={liberoClick}>
        <div class="label">Libero</div>
      </div>
    </div>
  );
  //   <div className={`bottom-actions ${side}-actions`}>
  //   <div className={`action time-out time-out-${side} ${side==="right"?"flex-row-reverse":""}`}>
  //     <div className="label" onClick={timeoutClick}>Time-out ({timeout})</div>
  //     <div style={{backgroundColor: "#c2c2c2", height: "100%", width: 3}} />
  //     <div className="label alt" onClick={liberoClick}>Libero</div>
  //   </div>
  //   <div className={`action substitution substitution-${side} ${side==="right"?"flex-row-reverse":""}`}>
  //     <div className="label" onClick={substitutionClick}>Substitution ({substitution})</div>
  //     <div style={{backgroundColor: "#c2c2c2", height: "100%", width: 3}} />
  //     <div className="label alt" onClick={injuryClick}>Injury</div>
  //   </div>
  // </div>
};

export default EBottomAction;
