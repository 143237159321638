import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { tableStyle } from '../utilities';
import EmptyTable from './EmptyTable';

const DatatableCard = ({ tableData, ...other_props }) => {

    return (
        <DataTableExtensions print={false} export={false} {...tableData}>
            <DataTable
                pagination
                noHeader
                highlightOnHover
                striped
                pointerOnHover                
                customStyles={tableStyle}
                noDataComponent={<EmptyTable />}
                style={{ borderRadius: 0, minHeight: 300 }}
                {...other_props}
            />
        </DataTableExtensions>
    );
}


export default DatatableCard;