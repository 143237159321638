import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DatatableCard, Dropdown, Loader } from '../../../components';
import { closeNotify, getTournois, getTournoisAuxiliaire } from '../actions';
import { DropdownConstants } from '../../../utilities';
import { hasPermission } from '../../../utilities/auth';

const Tournois = (props) => {

  const adminState = useSelector((state) => state.adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getTournois());
    return () => dispatch(closeNotify())
  }, [dispatch]);

  const onEdit = (tournois) => {
    navigate("/admin/tournois-form", { state: tournois });
  }
 
  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Salle',
      selector: 'salle',
      sortable: true,
    },
    {
      name: 'Start date',
      selector: 'start',
      sortable: true,
    },
    {
      name: 'End date',
      selector: 'end',
      sortable: true,
    },
    {
      name: 'Organizer',
      selector: 'organizer',
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["EDIT_TOURNAMENT"]) && <button
        onClick={() => onEdit(row)}
        className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button> : null
    },
  ];

  const handleFilterChange = e => {
    switch (e.target.value) {
      case 'Afficher Tout':
        dispatch(getTournois());
        break;
      case 'Afficher Normal':
        dispatch(getTournoisAuxiliaire(false));
        break;
      case 'Afficher Auxiliaire':
        dispatch(getTournoisAuxiliaire(true));
        break;

      default:
        dispatch(getTournois());
        break;
    }

  }

  const tableData = {
    columns,
    data: adminState.tournois
  };

  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-list fa-lg mr-3"></i></span>LIST OF TOURNAMENTS</h6>
              {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["ADD_TOURNAMENT"]) &&<h6 style={{ float: 'right' }} className="h2 d-inline-block text-uppercase mb-0"><Link to='/admin/tournois-form' style={{ backgroundColor: '#19a0c2', color: '#fff' }} className="btn btn-sm"><i className="fa fa-plus fa-lg"></i> </Link></h6> : null}
              {adminState.loggedInUser.length !== 0 ? hasPermission(adminState.loggedInUser, ["PRINT_TOURNAMENT"]) && <div class="dropdown float-right">
                <button class="btn btn-sm" style={{ backgroundColor: '#343a3f', color: '#fff' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-print"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <button class="dropdown-item">
                    <span> <i class="fa fa-eye mr-1"></i> Print preview</span>
                  </button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item">
                    <span> <i class="fa fa-print mr-1"></i> Print</span>
                  </button>
                </div>
              </div> : null}
            </div>
          </div>

          <div className="row" data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">
            <Dropdown title="Filtre" options={DropdownConstants.AUXILIAIRE_FILTER} onChange={handleFilterChange} />
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card card-stats" >

                  {adminState.loading ?

                    <Loader /> : <DatatableCard tableData={tableData} />}
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tournois