import React from "react";
import { Modal } from "react-bootstrap";

const EExitModal = ({ handleClose, show, onExit }) => {

  return (
    <Modal show={show} centered size="sm" onHide={handleClose}>
    
      <Modal.Body>
        <div className="d-block justify-content-center text-center w-100">
        <img src="/assets/images/exit.png" alt="scored" width={120} height={120} />
        <h3 className="mt-3">Are you sure you want to exit?</h3>
        <small>All the current data will be lost</small>
        </div>

        <div className="d-flex mt-3">
        <button
          className="btn btn-sm btn-danger w-50"
          onClick={onExit}
        >
          Exit
        </button>
        <button className="btn btn-sm w-50" onClick={handleClose}>
          Close
        </button>
       
      </div>
      </Modal.Body>
     
    </Modal>
  );
};

export default EExitModal;
