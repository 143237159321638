import React from "react";
import EOnCourtItem from "./EOnCourtItem";

const EOnCourt = ({ side="left", data, captainSelection }) => {
  return (
    <div>
      <div className={`block-title ${side==="right" ? "text-right":""}`}>
        <span className={`${side==="right" ? "mr-4":"ml-4"}`}>On court</span>
      </div>
      <div className={`on-court-${side}`}>
        <ul className="list-group list-group-flush">
          {data.sort((a,b)=>a.position-b.position).map((val,index)=><EOnCourtItem title={val.player.name} value={`${val.kit}`} side={side} key={index} captain={captainSelection[val.player.id]?.role} />)}
        </ul>
      </div>
    </div>
  );
};

export default EOnCourt;
