const tableStyle = {
    rows: {
        style: {
          minHeight: '55px',
        }
      },
      headCells: {
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
          backgroundColor: '#11213A',
          color: '#FFF',
          "&:hover": {
            color: "#fff"
          },
        },
      },
      cells: {
        style: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
};

export default tableStyle