import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = ({ align = "center", showText = true,...rest }) => {
  return (
    <div style={{ textAlign: align, padding: showText ? 30 : 0 }}>
      <Spinner animation="border" role="status" {...rest} />
      {showText && <p style={{ marginTop: 25 }}>Traitement en cours...</p>}
    </div>
  );
};

export default Loader;
