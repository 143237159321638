import { takeLatest } from 'redux-saga/effects';
import * as workers from './worker';
import * as constants from '../constants';

export function* watchSaveUser() {
    yield takeLatest(constants.SAVE_USER, workers.saveUserWorker);
}

export function* watchGetUsers() {
    yield takeLatest(constants.GET_USERS, workers.getUsersWorker);
}

export function* watchUpdateUser() {
    yield takeLatest(constants.UPDATE_USER, workers.updateUserWorker);
}

export function* watchDeleteUser() {
    yield takeLatest(constants.DELETE_USER, workers.deleteUserWorker);
}

export function* watchPrintUsers() {
    yield takeLatest(constants.PRINT_USERS, workers.printUsersWorker);
}

export function* watchGetRole() {
    yield takeLatest(constants.GET_ROLE, workers.getRoleWorker);
}

export function* watchGetRoles() {
    yield takeLatest(constants.GET_ROLES, workers.getRolesWorker);
}

export function* watchSaveRole() {
    yield takeLatest(constants.SAVE_ROLE, workers.saveRoleWorker);
}

export function* watchDeleteRole() {
    yield takeLatest(constants.DELETE_ROLE, workers.deleteRoleWorker);
}

export function* watchUpdateRole() {
    yield takeLatest(constants.UPDATE_ROLE, workers.updateRoleWorker);
}

export function* watchGetGrade() {
    yield takeLatest(constants.GET_GRADE, workers.getGradeWorker);
}

export function* watchSaveClub() {
    yield takeLatest(constants.SAVE_CLUB, workers.saveClubWorker);
}

export function* watchUpdateClub() {
    yield takeLatest(constants.UPDATE_CLUB, workers.updateClubWorker);
}

export function* watchDeleteClub() {
    yield takeLatest(constants.DELETE_CLUB, workers.deleteClubWorker);
}

export function* watchGetClub() {
    yield takeLatest(constants.GET_CLUB, workers.getClubWorker);
}

export function* watchGetClubByLigue() {
    yield takeLatest(constants.GET_CLUB_BY_LIGUE, workers.getClubByLigueWorker);
}

export function* watchGetClubAuxiliaire() {
    yield takeLatest(constants.GET_CLUB_AUXILIAIRE, workers.getClubAuxiliaireWorker);
}

export function* watchGetDashboardElements() {
    yield takeLatest(constants.GET_DASHBOARD_ELEMENTS, workers.getDashboardElementsWorker);
}

export function* watchGetInscrits() {
    yield takeLatest(constants.GET_INSCRITS, workers.getInscritWorker);
}

export function* watchGetLoggedInUser() {
    yield takeLatest(constants.GET_LOGGED_IN_USER, workers.getLoggedInUserWorker);
}

export function* watchGetRegions() {
    yield takeLatest(constants.GET_REGIONS, workers.getRegionsWorker);
}

export function* watchGetDepartements() {
    yield takeLatest(constants.GET_DEPARTEMENTS, workers.getDepartementsWorker);
}

export function* watchGetArrondissement() {
    yield takeLatest(constants.GET_ARRONDISSEMENTS, workers.getArrondissementWorker);
}

export function* watchGetLigueUsers() {
    yield takeLatest(constants.GET_LIGUE_USERS, workers.getLigueUsersWorker);
}

export function* watchGetSubRoles() {
    yield takeLatest(constants.GET_SUB_ROLES, workers.getSubRolesWorker);
}

export function* watchSaveMatch() {
    yield takeLatest(constants.SAVE_MATCH, workers.saveMatchWorker);
}

export function* watchGetMatchs() {
    yield takeLatest(constants.GET_MATCHS, workers.getMatchsWorker);
}

export function* watchGetMatchFormData() {
    yield takeLatest(constants.GET_MATCH_FORM_DATA, workers.getMatchFormDataWorker);
}

export function* watchGetMatchsByTournois() {
    yield takeLatest(constants.GET_MATCHS_BY_TOURNOIS, workers.getMatchsByTournoisWorker);
}

export function* watchDeleteMatch() {
    yield takeLatest(constants.DELETE_MATCH, workers.deleteMatchWorker);
}

export function* watchUpdateMatch() {
    yield takeLatest(constants.UPDATE_MATCH, workers.updateMatchWorker);
}

export function* watchSaveTournois() {
    yield takeLatest(constants.SAVE_TOURNOIS, workers.saveTournoisWorker);
}

export function* watchGetTournois() {
    yield takeLatest(constants.GET_TOURNOIS, workers.getTournoisWorker);
}

export function* watchGetTournoisAuxiliaire() {
    yield takeLatest(constants.GET_TOURNOIS_AUXILIAIRE, workers.getTournoisAuxiliaireWorker);
}

export function* watchDeleteTournois() {
    yield takeLatest(constants.DELETE_TOURNOIS, workers.deleteTournoisWorker);
}

export function* watchUpdateTournois() {
    yield takeLatest(constants.UPDATE_TOURNOIS, workers.updateTournoisWorker);
}

export function* watchSaveMatriculeCode() {
    yield takeLatest(constants.SAVE_MATRICULE_CODE, workers.saveMatriculeCodeWorker);
}

export function* watchGetMatriculeCode() {
    yield takeLatest(constants.GET_MATRICULE_CODES, workers.getMatriculeCodeWorker);
}

export function* watchDeleteMatriculeCode() {
    yield takeLatest(constants.DELETE_MATRICULE_CODE, workers.deleteMatriculeCodeWorker);
}

export function* watchUpdateMatriculeCode() {
    yield takeLatest(constants.UPDATE_MATRICULE_CODE, workers.updateMatriculeCodeWorker);
}

export function* watchSavePermission() {
    yield takeLatest(constants.SAVE_PERMISSION, workers.savePermissionWorker);
}

export function* watchGetPermission() {
    yield takeLatest(constants.GET_PERMISSIONS, workers.getPermissionWorker);
}

export function* watchDeletePermission() {
    yield takeLatest(constants.DELETE_PERMISSION, workers.deletePermissionWorker);
}

export function* watchUpdatePermission() {
    yield takeLatest(constants.UPDATE_PERMISSION, workers.updatePermissionWorker);
}

export function* watchSaveRolePermission() {
    yield takeLatest(constants.SAVE_ROLE_PERMISSION, workers.saveRolePermissionWorker);
}

export function* watchGetRolePermission() {
    yield takeLatest(constants.GET_ROLE_PERMISSIONS, workers.getRolePermissionWorker);
}

export function* watchDeleteRolePermission() {
    yield takeLatest(constants.DELETE_ROLE_PERMISSION, workers.deleteRolePermissionWorker);
}

export function* watchUpdateRolePermission() {
    yield takeLatest(constants.UPDATE_ROLE_PERMISSION, workers.updateRolePermissionWorker);
}

export function* watchSavePlayerTransfer() {
    yield takeLatest(constants.SAVE_PLAYER_TRANSFER, workers.savePlayerTransferWorker);
}

export function* watchGetPlayerTransfer() {
    yield takeLatest(constants.GET_PLAYER_TRANSFERS, workers.getPlayerTransferWorker);
}

export function* watchDeletePlayerTransfer() {
    yield takeLatest(constants.DELETE_PLAYER_TRANSFER, workers.deletePlayerTransferWorker);
}

export function* watchUpdatePlayerTransfer() {
    yield takeLatest(constants.UPDATE_PLAYER_TRANSFER, workers.updatePlayerTransferWorker);
}