import React from "react";
import { Modal } from "react-bootstrap";

const EStopSetModal = ({
  handleClose,
  show,
  set,
  confirmStopSet,
  onEndMatch,
  time,
  score,
}) => {
  const teamA = Object.values(score)[0];
  const teamB = Object.values(score)[1];

const canStopSet=()=>{
  if (set<5 && (teamA.points >= 25 || teamB.points >= 25)) {
    return true
  }
  return false
}

  return (
    <Modal show={show} centered size="sm" onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>END SET</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-block justify-content-center text-center w-100">
          <h1 style={{ fontSize: 60 }}>SET: {set}</h1>
        </div>
        <div className="d-flex justify-content-between border-top mt-3 pt-2">
          <div>
            <p className="mb-0">{teamA?.teamName} (Score):</p>
            <h1>{teamA.points}</h1>
          </div>
          <div>
            <p className="mb-0">{teamB?.teamName} (Score):</p>
            <h1>{teamB.points}</h1>
          </div>
        </div>
        <div className="d-flex justify-content-between border-top border-bottom mt-3 pt-2">
          <div>
            <p className="mb-0">Time:</p>
            <h1>{time || "0:45:32"}</h1>
          </div>
          <div>
            <p className="mb-0">Set Winner:</p>
            <h1>
              {teamA.points === teamB.points
                ? "DRAW"
                : teamA.points > teamB.points
                ? teamA?.teamName
                : teamB?.teamName}
            </h1>
          </div>
        </div>

        <div>
          {canStopSet() ? <> <button
            className="btn btn-sm w-100"
            disabled={set===5}
            onClick={()=>confirmStopSet(time)}
            style={{ backgroundColor: "#E56969", color: "white" }}
          >
            END SET
          </button>
          <button
            className="btn btn-sm w-100"
            onClick={onEndMatch}
            style={{ border: "1px solid #E56969", color: "#E56969" }}
          >
            END MATCH
          </button>
          </> : null}
          <button className="btn btn-sm w-100" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EStopSetModal;
