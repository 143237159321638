import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <h6
      style={{ float: "right" }}
      className="h2 d-inline-block text-uppercase mb-0"
    >
      <button onClick={() => navigate(-1)} className="btn btn-sm">
        <i className="fa fa-arrow-left mr-2"></i>Back
      </button>
    </h6>
  );
};

export default BackButton;
